import { useEffect, useState } from "react";
import { dateTimeHandler } from "../../lib/dateTimeHandler";

const handlerConfig = {
  enableDuration: true,
  enableLocalizedFormat: true,
  enableRelativeTime: true,
};

const PrettyPastDate = ({ pastDate, updated }) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    setNow(new Date());
  }, [updated]);

  return (
    dateTimeHandler(handlerConfig)
      .duration(
        dateTimeHandler()(pastDate).diff(dateTimeHandler()(now))
      ).humanize(true)
  );
};

export default PrettyPastDate;
