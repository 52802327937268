import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import config from "../config";

const commsportal = () => {
  const { commsPortalApiBaseUrl } = config;
  const client = axios.create({
    baseURL: commsPortalApiBaseUrl,
  });

  client.interceptors.response.use(
    (response) => {
      response.data = camelizeKeys(response.data);
      return response;
    },
    (error) => {
      const { status, data } = error.response;
      // Unable to use Eslint recommendation: reject(new Error({...}))
      /* eslint-disable prefer-promise-reject-errors */
      return Promise.reject({ responseHttpCode: status, data });
    }
  );

  client.interceptors.request.use(
    (request) => {
      const availableData = JSON.parse(localStorage.getItem("currentUser"));
      const { apiToken } = { apiToken: null, ...availableData };

      if (apiToken) {
        request.headers = {
          common: {
            Authorization: `Bearer ${apiToken}`
          },
        };
      }

      if (request.data !== undefined) {
        request.data = decamelizeKeys(request.data);
      }
      return request;
    },
  );

  return client;
};

export default commsportal;
