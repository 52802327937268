import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectIsSignedIn, setLastLocationPathname } from "../features/auth/authSlice";
import ProtectedRoute from "../components/ProtectedRoute";

const RequireAuth = ({ children, can, instance }) => {
  // const location = useLocation();
  const dispatch = useDispatch();
  const isSignedIn = useSelector(selectIsSignedIn);

  if (!isSignedIn) {
    // Using strategy documented by react-router-dom doesn't work
    // Strategy: <Navigate {...} state={{ from: location }} />
    dispatch(setLastLocationPathname({ locationPathname: window.location.pathname }));
    return <Navigate to="/signin?from-location=true" replace />;
  }

  return <ProtectedRoute can={can} instance={instance}>{children}</ProtectedRoute>;
};

export default RequireAuth;
