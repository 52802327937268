import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import CustomerForm from "./CustomerForm";
import {
  createCustomer,
  selectCustomer,
  selectCustomerId,
  selectStatus,
} from "./customerSlice";

const Header = () => <>Create Customer</>;

const CustomerCreate = () => {
  const status = useSelector(selectStatus);
  const customer = useSelector(selectCustomer);
  const selectedCustomerId = useSelector(selectCustomerId);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === "created") {
      navigate(`/customers/${customer.id}`);
    }
  }, [status]);

  const handleSubmit = (newCustomer) => {
    dispatch(createCustomer({ customer: newCustomer }));
  };

  return (
    <RequireAuth can="create customer">
      <HeaderLayout>
        <BaseView
          headerSlot={Header}
        >
          <CustomerForm onSubmit={handleSubmit} defaults={{ customerId: selectedCustomerId }} />
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default CustomerCreate;
