import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addMessage } from "../systemMessage/systemMessageSlice";

const initialState = {
  bonder: {
    telemetry: {
      isLoading: false,
    }
  },
  errors: {},
  isLoading: false,
  list: {
    bonders: {
      items: [],
      pagination: undefined,
    },
    status: undefined,
    isLoading: false,
  },
  map: {
    bonders: {
      items: [],
    },
    status: undefined,
    isLoading: false,
  },
  pollingUpdatedAt: null,
  speedtestResults: {},
  status: undefined,
};

export const fetchBondersForList = createAsyncThunk(
  "space/list/bonders",
  async ({ customerId, pageNumber, itemsPerPage, filters }, {
    extra: { api },
    rejectWithValue
  }) => {
    try {
      const params = { page: pageNumber, limit: itemsPerPage, ...filters };
      const { data } = await api.get(`/customers/${customerId}/bonds`, { params });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBondersForMap = createAsyncThunk(
  "space/map/bonders",
  async ({ customerId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/bonds/map`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchBonder = createAsyncThunk(
  "space/fetch/bonder",
  async ({ customerId, bonderId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/bonds/${bonderId}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateBonderLocation = createAsyncThunk(
  "bonder/location/update",
  async ({ customerId, bond }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.patch(
        `/customers/${customerId}/bonds/${bond.id}`,
        { bond: { location: bond.location } }
      );
      dispatch(addMessage({ message: "Bonder location updated", type: "success" }));
      return data;
    } catch (error) {
      dispatch(addMessage({ message: "Unable to update bonder location", type: "danger" }));
      return rejectWithValue(error);
    }
  }
);

export const updateBonderStatus = createAsyncThunk(
  "bonder/status/update",
  async ({ customerId, bond }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.patch(
        `/customers/${customerId}/bonds/${bond.id}`,
        { bond: { enabled: bond.enabled } }
      );
      const message = bond.enabled ? "Bond enabled" : "Bond disabled";
      dispatch(addMessage({ message, type: "success" }));
      return data;
    } catch (error) {
      dispatch(addMessage({ message: "Unable to update bonder status", type: "danger" }));
      return rejectWithValue(error);
    }
  }
);

export const fetchBonderTelemetry = createAsyncThunk(
  "bonder/fetch/telemetry",
  async ({ customerId, bonderId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/bonds/${bonderId}/chart_data`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSpeedTest = createAsyncThunk(
  "speedtest/create",
  async ({ customerId, bonderId }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.post(
        `/customers/${customerId}/bonds/${bonderId}/do_speedtest`,
      );
      dispatch(addMessage({ message: "Speed test requested", type: "success" }));
      return data;
    } catch (error) {
      dispatch(addMessage({ message: "Speed test failed", type: "danger" }));
      return rejectWithValue(error);
    }
  }
);

export const fetchSpeedTestResults = createAsyncThunk(
  "speedtest/fetch/results",
  async ({ customerId, bonderId, testIds }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.post(
        `/customers/${customerId}/bonds/${bonderId}/speedtests`,
        { ids: testIds.join(",") }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bonderSlice = createSlice({
  name: "bonder",
  initialState,
  reducers: {
    clearBonder: (state) => {
      state.bonder = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBondersForMap.pending, (state) => {
        state.map.status = "loading";
        state.map.isLoading = true;
      })
      .addCase(fetchBondersForMap.rejected, (state) => {
        state.map.status = "error";
        state.map.isLoading = false;
      })
      .addCase(fetchBondersForMap.fulfilled, (state, action) => {
        state.map.bonders = action.payload;
        state.map.status = "idle";
        state.map.isLoading = false;
      })
      .addCase(fetchBondersForList.pending, (state) => {
        state.list.status = "loading";
        state.list.isLoading = true;
      })
      .addCase(fetchBondersForList.rejected, (state) => {
        state.list.status = "error";
        state.list.isLoading = false;
      })
      .addCase(fetchBondersForList.fulfilled, (state, action) => {
        state.list.bonders = action.payload;
        state.list.status = "idle";
        state.list.isLoading = false;
      })
      .addCase(fetchBonder.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchBonder.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchBonder.fulfilled, (state, action) => {
        state.bonder = { ...state.bonder, ...action.payload };
        state.status = "idle";
        state.isLoading = false;
      })
      .addCase(updateBonderLocation.pending, (state) => {
        state.bonder.status = "loading";
        state.bonder.isLoading = true;
      })
      .addCase(updateBonderLocation.rejected, (state) => {
        state.bonder.status = "error";
        state.bonder.isLoading = false;
      })
      .addCase(updateBonderLocation.fulfilled, (state, action) => {
        state.bonder = { ...state.bonder, ...action.payload };
        state.bonder.status = "updated";
        state.bonder.isLoading = false;
      })
      .addCase(updateBonderStatus.pending, (state) => {
        state.bonder.status = "loading";
        state.bonder.isLoading = true;
      })
      .addCase(updateBonderStatus.rejected, (state) => {
        state.bonder.status = "error";
        state.bonder.isLoading = false;
      })
      .addCase(updateBonderStatus.fulfilled, (state, action) => {
        state.bonder = { ...state.bonder, ...action.payload };
        state.bonder.status = "updated";
        state.bonder.isLoading = false;
      })
      .addCase(fetchBonderTelemetry.pending, (state) => {
        state.bonder.status = "loading";
        state.bonder.telemetry.isLoading = true;
      })
      .addCase(fetchBonderTelemetry.rejected, (state) => {
        state.bonder.status = "error";
        state.bonder = { ...state.bonder, telemetry: { isLoading: false } };
      })
      .addCase(fetchBonderTelemetry.fulfilled, (state, action) => {
        const telemetry = action.payload;
        state.bonder = { ...state.bonder, telemetry };
        state.bonder.status = "idle";
        state.bonder.telemetry.isLoading = false;
      })
      .addCase(createSpeedTest.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(createSpeedTest.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(createSpeedTest.fulfilled, (state, action) => {
        state.status = "idle";
        state.bonder = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchSpeedTestResults.rejected, (state) => {
        state.status = "error";
      })
      .addCase(fetchSpeedTestResults.fulfilled, (state, action) => {
        state.pollingUpdatedAt = new Date().toISOString();
        state.isLoading = false;

        Object.values(action.payload)
          .forEach(({
            id,
            status,
          }) => {
            const updatedTest = { ...state.bonder.speedtests[id], status };
            state.bonder.speedtests = { ...state.bonder.speedtests, [id]: updatedTest };
          });

        const completedTests = Object.values(action.payload).filter(({ status }) => status === "completed");

        completedTests.forEach(({
          id,
          progressResults: { throughput, latency },
          masterCpuLoad,
          slaveCpuLoad
        }) => {
          state.speedtestResults.throughput = {
            ...state.speedtestResults.throughput,
            [id]: throughput,
          };

          state.speedtestResults.latency = {
            ...state.speedtestResults.latency,
            [id]: latency,
          };

          state.speedtestResults.masterCpuLoad = {
            ...state.speedtestResults.masterCpuLoad,
            [id]: masterCpuLoad,
          };

          state.speedtestResults.slaveCpuLoad = {
            ...state.speedtestResults.slaveCpuLoad,
            [id]: slaveCpuLoad,
          };
        });

        return state;
      });
  },
});

export const { clearBonder } = bonderSlice.actions;

export const selectBonder = (state) => state.bonder.bonder;
export const selectBonderSpeedTests = (state) => (state.bonder.bonder.speedtests) || {};
export const selectIsBonderLoading = (state) => state.bonder.isLoading;
export const selectIsListLoading = (state) => state.bonder.list.isLoading;
export const selectIsMapLoading = (state) => state.bonder.map.isLoading;
export const selectIsTelemetryLoading = (state) => state.bonder.bonder.telemetry.isLoading;
export const selectLatency = (state) => state.bonder.speedtestResults.latency;
export const selectListBonders = (state) => state.bonder.list.bonders.items;
export const selectMapBonders = (state) => state.bonder.map.bonders.items;
export const selectMasterCpuLoad = (state) => state.bonder.speedtestResults.masterCpuLoad;
export const selectPagination = (state) => state.bonder.list.bonders.pagination;
export const selectPollingUpdatedAt = (state) => state.bonder.pollingUpdatedAt;
export const selectSlaveCpuLoad = (state) => state.bonder.speedtestResults.slaveCpuLoad;
export const selectTelemetry = (state) => state.bonder.bonder.telemetry;
export const selectThroughput = (state) => state.bonder.speedtestResults.throughput;

export default bonderSlice.reducer;
