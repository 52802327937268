import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import {
  fetchIperfResult,
  selectIperfResults,
} from "./iperfSlice";
import LineChart from "../../components/LineChart/LineChart";
import Summary from "./Summary";

const IperfTests = () => {
  const dispatch = useDispatch();
  const results = useSelector(selectIperfResults);
  const { latestTest } = results;
  const { bonderId, customerId } = useParams();
  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    dispatch(fetchIperfResult({ customerId, bonderId }));
  }, []);

  useEffect(() => {
    if (latestTest) {
      const [intervalsDown, intervalsUp] = Object.values(latestTest);
      const up = intervalsUp
        .map(({ time, mbitsPerSecond }) => ({ name: "Upload", xAxisValue: time, yAxisValue: mbitsPerSecond / 1000 }));
      const down = intervalsDown
        .map(({ time, mbitsPerSecond }) => ({ name: "Download", xAxisValue: time, yAxisValue: mbitsPerSecond / 1000 }));

      const data = [].concat([up], [down]);
      if (data.length > 0) {
        setChartData(data);
      }
    }

    return undefined;
  }, [latestTest]);

  return (
    <div>
      {latestTest && (<Summary {...latestTest} />)}
      <Row>
        <Col>
          {chartData && (
            <LineChart
              data={chartData}
              targetHtml="iperf-latest"
              yAxisTitle="Mbps"
              yAxisNumberFormat="#k"
              colourList={["#0000fe", "#fe7000"]}
              showLineBullets
              showCursor
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default IperfTests;
