import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import humanizeString from "string-humanize";
import xbytes from "xbytes";
import PropertyValue from "../../components/PropertyValue/PropertyValue";
import Meta from "../../components/Meta/Meta";
import { BaseView, Button, Can, ConnectionAction } from "../../components";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import {
  clearConnection,
  deleteConnection,
  fetchActivationNote,
  fetchConnection,
  performConnectionAction,
  selectActivationNote,
  selectConnection,
  selectStatus,
  updateConnectionMeta,
} from "./connectionSlice";
import { ConnectionBreadCrumb, LinkedConnections } from "./index";
import LoadingPage from "../../pages/LoadingPage";
import { Pl, Pt, Pd } from "../../components/PropertyList/PropertyList";
import mapTerm from "../../lib/MapTerm";

const Header = ({ connection: { id } }) => `SIM ID: ${id}`;

const ConnectionShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { connectionId } = useParams();
  const activationNote = useSelector(selectActivationNote);
  const connection = useSelector(selectConnection);
  const status = useSelector(selectStatus);

  const getConnection = () => {
    dispatch(fetchConnection({ connectionId }));
  };

  const showActivationNote = () => {
    const fileURL = URL.createObjectURL(activationNote);
    window.open(fileURL);
  };

  useEffect(() => {
    getConnection();

    return () => dispatch(clearConnection());
  }, []);

  useEffect(() => {
    if (status === "deleted") navigate("/connections");
    if (status === "activation-note-success") showActivationNote();
  }, [status]);

  const handleDelete = () => {
    dispatch(deleteConnection({ connection }));
  };

  const handleConnectionAction = (params) => {
    dispatch(performConnectionAction(params));
  };

  const handleActivationNote = () => {
    dispatch(fetchActivationNote({ connectionId }));
  };

  const handleMetaEdit = (data) => {
    const { id, ...amendedMeta } = data;

    const metaKey = Object.keys(amendedMeta).pop();
    const metaValue = Object.values(amendedMeta).pop();

    dispatch(updateConnectionMeta({ connectionId: id, metaKey, metaValue }));
  };

  if (connection == null) return <LoadingPage />;

  return (
    <RequireAuth can="read connection">
      <HeaderLayout>
        <BaseView
          headerSlot={() => Header({ connection })}
          breadcrumbSlot={() => ConnectionBreadCrumb({ connection })}
        >
          <Container>
            <Row>
              <Col>
                <Pl>
                  <Pt>MSISDN</Pt>
                  <Pd><PropertyValue value={connection.msisdn} /></Pd>
                  <Pt>ICCID</Pt>
                  <Pd><PropertyValue value={connection.iccid} /></Pd>
                  <Pt>IMSI</Pt>
                  <Pd><PropertyValue value={connection.imsi} /></Pd>
                  <Pt>Device</Pt>
                  <Pd><PropertyValue value={connection.device} /></Pd>
                  <Pt>Allowance</Pt>
                  <Pd>{xbytes(!connection.allowance ? 0 : connection.allowance)}</Pd>
                  <Pt>status</Pt>
                  <Pd>
                    {humanizeString(connection.status)}
                    <Can update instance={connection}>
                      {connection.permittedActions && connection.permittedActions.map((action) => (
                        <ConnectionAction
                          key={`action-for-${action}`}
                          action={action}
                          connectionId={connection.id}
                          actionHandler={handleConnectionAction}
                        />
                      ))}
                    </Can>
                  </Pd>
                </Pl>
              </Col>
              <Col>
                <Pl>
                  <Pt>Customer</Pt>
                  <Pd><NavLink to={`/customers/${connection.customerId}`}>{connection.customerName}</NavLink></Pd>
                  <Pt>Operator</Pt>
                  <Pd>{mapTerm(connection.operator)}</Pd>
                  <Pt>Service</Pt>
                  <Pd>{mapTerm(connection.service)}</Pd>
                  <Pt>Plan</Pt>
                  <Pd><PropertyValue value={connection.planName} /></Pd>
                  <Pt>setup cost</Pt>
                  <Pd><PropertyValue value={connection.setupCost} /></Pd>
                  <Pt>monthly cost</Pt>
                  <Pd><PropertyValue value={connection.monthlyCost} /></Pd>
                  <Pt>PO Number</Pt>
                  <Pd><PropertyValue value={connection.poNumber} /></Pd>
                  {connection.meta && (
                    <Meta
                      entries={connection.meta}
                      entityId={connection.id}
                      onMetaEdit={handleMetaEdit}
                    />
                  )}
                  {connection.linked.length > 0 && (
                    <>
                      <Pt>Linked connections ({connection.linked.length})</Pt>
                      <Pd><LinkedConnections linkedConnections={connection.linked} /></Pd>
                    </>
                  )}
                </Pl>
              </Col>
            </Row>
          </Container>
          <ButtonGroup className="float-end">
            <Can read instance={connection}>
              <Button label="Activation note" onButtonClick={handleActivationNote} />
            </Can>
            <Can update instance={connection}>
              <Button as={NavLink} to={`/connections/${connectionId}/edit`} variant="primary" label="Edit" />
            </Can>
            <Can move instance={connection}>
              <Button as={NavLink} to={`/connections/${connectionId}/move`} variant="primary" label="Move" />
            </Can>
            <Can delete instance={connection}>
              <Button variant="primary" onButtonClick={handleDelete} label="Delete" />
            </Can>
          </ButtonGroup>
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default ConnectionShow;
