/* Ignoring eslint rules to preserve HTML structure and convenience */
/* eslint-disable max-len */
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import HeaderlessLayout from "../layouts/headerless";

const PublicCookiePolicy = () => (
  <HeaderlessLayout>
    <Container id="our-cookie-policy" fluid>
      <Row className="d-flex justify-content-center">
        <Col md="auto">
          <Card>
            <Card.Body>
              <Card.Title>Cookie policy</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">About our cookies</Card.Subtitle>
              <Card.Text>
                <p>This site uses cookies to store information on your computer.</p>
                <p>We do not and will not use this information to identify you and we will certainly never sell on any information to 3rd parties.</p>
                <p>Any information we gather is used purely to make your experience of our site easier and more useful.</p>
                <p>By using this site you agree to us collecting this anonymous information.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </HeaderlessLayout>
);

export default PublicCookiePolicy;
