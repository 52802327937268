import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import UserForm from "./UserForm";
import LoadingPage from "../../pages/LoadingPage";
import {
  fetchUser,
  clearUser,
  updateUser,
  selectIsLoading,
  selectStatus,
  selectUser,
} from "./userSlice";

const Header = () => <>Edit User</>;

const UserEdit = () => {
  const isLoading = useSelector(selectIsLoading);
  const status = useSelector(selectStatus);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();

  const getUser = () => {
    dispatch(fetchUser({ userId }));
  };

  useEffect(() => {
    getUser();

    return () => dispatch(clearUser());
  }, []);

  useEffect(() => {
    if (status === "updated") {
      navigate(`/users/${user.id}`);
    }
  }, [status]);

  const handleSubmit = (newUser) => {
    dispatch(updateUser({ user: newUser }));
  };

  if (isLoading) return <LoadingPage />;

  return (
    <RequireAuth can="update user" instance={user}>
      <HeaderLayout>
        <BaseView
          headerSlot={Header}
        >
          <UserForm onSubmit={handleSubmit} defaults={user} />
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default UserEdit;
