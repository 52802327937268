import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import mapTerm from "../../lib/MapTerm";

import Checkbox from "../../forms/inputs/Checkbox";

const OperatorServices = ({ sims, lines }) => (
  <>
    {sims.length && sims.map((operatorServices) => Object
      .entries(operatorServices).map(([name, services]) => (
        <Row key={`${name}-sim-services`}>
          <h5>{mapTerm(name)}</h5>
          {services.map((service) => (
            <Col key={`key-for-${name}-${service}`}>
              <Checkbox
                label={mapTerm(service)}
                value={`${name}_${service}`}
                name="operatorServices"
              />
            </Col>
          ))}
        </Row>
      )))}

    {lines.length && lines.map((operatorServices) => Object
      .entries(operatorServices).map(([name, services]) => (
        <Row key={`${name}-line-services`}>
          <h5>{mapTerm(name)}</h5>
          {services.map((service) => (
            <Col key={`key-for-${name}-${service}`}>
              <Checkbox
                label={mapTerm(service)}
                value={`${name}_${service}`}
                name="operatorServices"
              />
            </Col>
          ))}
        </Row>
      )))}
  </>
);

export default OperatorServices;
