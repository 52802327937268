import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  lines: [],
  sims: [],
  status: undefined,
};

export const fetchOperatorServices = createAsyncThunk(
  "operator-serices/fetch",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const response = await api.get("/connections/operator_services/");
      const { sims, lines } = response.data;
      return { sims, lines };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const operatorServiceSlice = createSlice({
  name: "operatorService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperatorServices.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.sims = [];
        state.lines = [];
      })
      .addCase(fetchOperatorServices.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchOperatorServices.fulfilled, (state, { payload: { sims, lines } }) => {
        state.status = "idle";
        state.isLoading = false;
        state.sims = sims;
        state.lines = lines;
      });
  },
});

export const selectIsLoadingOpServices = (state) => state.operatorService.isLoading;
export const selectSims = (state) => state.operatorService.sims;
export const selectLines = (state) => state.operatorService.lines;

export default operatorServiceSlice.reducer;
