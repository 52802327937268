import React from "react";
import { useDispatch } from "react-redux";
import { Button as ReactButton } from "react-bootstrap";
import { clearMessages } from "../../features/systemMessage/systemMessageSlice";

const Button = ({ variant, size, label, isLoading, ...props }) => {
  const { onButtonClick, ...buttonProps } = props;
  const dispatch = useDispatch();

  const clickHandler = (event) => {
    dispatch(clearMessages());

    if (onButtonClick) {
      onButtonClick(event);
    }
  };

  return (
    <ReactButton onClick={clickHandler} variant={variant} size={size} {...buttonProps}>
      {isLoading && (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          <span>&nbsp;{label}</span>
        </>
      )}
      {!isLoading && <span>{label}</span>}
    </ReactButton>
  );
};

export default Button;
