import React from "react";
import { Col, Row } from "react-bootstrap";
import { Pl, Pd, Pt } from "../../components/PropertyList/PropertyList";
import PrettyDate from "../../components/PrettyDate/PrettyDate";

const Summary = ({ sumDown, sumUp, targetServer, time }) => (
  <Row>
    <Col>
      <Pl>
        <Pt>Total download</Pt>
        <Pd>{sumDown} Mb</Pd>
        <Pt>Total upload</Pt>
        <Pd>{sumUp} Mb</Pd>
      </Pl>
    </Col>
    <Col>
      <Pl>
        <Pt>Target server</Pt>
        <Pd>{targetServer}</Pd>
        <Pt>Test date</Pt>
        <Pd><PrettyDate dateString={time} /></Pd>
      </Pl>
    </Col>
  </Row>
);

export default Summary;
