import { useSelector } from "react-redux";

import { selectCurrentUser } from "../../features/auth/authSlice";
import UseCan from "../../lib/UseCan";

const ProtectedRoute = ({ can, children, instance }) => {
  const user = useSelector(selectCurrentUser);
  const shouldCheckInstancePermissions = () => (typeof instance !== "undefined");
  const instanceNotReady = () => instance === null;

  let isAuthorised;
  if (shouldCheckInstancePermissions()) {
    const canDo = UseCan(user, instance?.instancePermissions);
    isAuthorised = instanceNotReady() || canDo(can);
  } else {
    const [verb, noun] = can.split(" ");
    const canDo = UseCan(user);
    isAuthorised = canDo(verb, noun);
  }

  if (isAuthorised) {
    return children;
  }

  return null;
};

export default ProtectedRoute;
