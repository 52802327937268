import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectLastPollingUpdate } from "./iperfSlice";
import PrettyPastDate from "../../components/PrettyPastDate/PrettyPastDate";

const IperfLaunchItem = ({ iperf }) => {
  const [show, setShow] = useState(true);
  const updatedAt = useSelector(selectLastPollingUpdate);

  if (!show) {
    return null;
  }

  if (iperf.status === "error") {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        This test encountered an error {iperf && (
          <PrettyPastDate pastDate={iperf.time} updated={updatedAt} />
      )}
      </Alert>
    );
  }

  return (
    <Alert variant="info" onClose={() => setShow(false)} dismissible>
      Test requested {iperf && (
        <PrettyPastDate pastDate={iperf.time} updated={updatedAt} />
    )}
    </Alert>
  );
};

export default IperfLaunchItem;
