import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  packages: {
    items: [],
  },
  package: null,
  errors: {},
};

export const fetchPackages = createAsyncThunk(
  "customer/fetch/packages",
  async ({ customerId, pageNumber, itemsPerPage }, { extra: { api }, rejectWithValue }) => {
    try {
      const params = { page: pageNumber, limit: itemsPerPage };
      const { data } = await api.get(`/customers/${customerId}/packages`, { params });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPackage = createAsyncThunk(
  "customer/fetch/package",
  async ({ packageId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/packages/${packageId}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    clearPackage: (state) => {
      state.packages = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchPackages.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.packages = action.payload;
        state.status = "idle";
        state.isLoading = false;
      })
      .addCase(fetchPackage.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchPackage.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchPackage.fulfilled, (state, action) => {
        state.package = action.payload;
        state.status = "idle";
        state.isLoading = false;
      });
  },
});

export const selectPackages = (state) => state.package.packages.items;
export const selectPagination = (state) => state.package.packages.pagination;
export const selectPackage = (state) => state.package.package;

export default packageSlice.reducer;
