import React from "react";
import { useSelector } from "react-redux";
import { selectIsDirect } from "../../features/auth/authSlice";
import FooterIconLink from "./FooterIconLink";

const Footer = () => {
  const year = new Date().getFullYear();
  const isDirect = useSelector(selectIsDirect);

  const linkData = [
    {
      iconName: "linkedin",
      linkUrl: "https://www.linkedin.com/company/210719",
    },
    {
      iconName: "twitter",
      linkUrl: "https://twitter.com/comms365",
    },
    {
      iconName: "facebook",
      linkUrl: "https://www.facebook.com/comms365",
    },
    {
      iconName: "instagram",
      linkUrl: "https://www.instagram.com/comms365/",
    },
    {
      iconName: "youtube",
      linkUrl: "https://www.youtube.com/user/Comms365",
    },
  ];

  return (
    <footer className="text-center text-lg-start text-white" style={{ backgroundColor: "#414042" }}>
      <div className="container">

        <section>
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-8 text-center text-md-start">
              <div className="p-3">
                <ul className="nav footer-nav-links">
                  {isDirect && (
                    <li className="nav-item">
                      <div id="app-footer-title"> &copy; Copyright Comms365 Ltd 2015 - {year}</div>
                    </li>
                  )}
                  {!isDirect && (
                    <li className="nav-item">
                      <div id="app-footer-title"> &copy; commsportal (v3) {year}</div>
                    </li>
                  )}
                  {isDirect && (
                    <li className="nav-item">
                      <a className="nav-link" href="/privacy-policy">Privacy Policy</a>
                    </li>
                  )}
                  {isDirect && (
                    <li className="nav-item">
                      <a className="nav-link" href="/cookie-policy">Cookie Policy</a>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
              {linkData.map((props) => <FooterIconLink key={props.iconName} {...props} />)}
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
