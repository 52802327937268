import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import humanizeString from "string-humanize";

import Button from "../../components/Button/Button";
import Can from "../../components/Can";
import NavLink from "../../components/NavLink/NavLink";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import LoadingPage from "../../pages/LoadingPage";
import { ShowBooleanAsIcon } from "../../components";
import { Pl, Pt, Pd } from "../../components/PropertyList/PropertyList";
import {
  clearUser,
  deleteUser,
  fetchUser,
  requestMojoUser,
  welcomeUser,
  selectIsLoading,
  selectStatus,
  selectUser,
  unlockUser,
} from "./userSlice";

const Header = ({ user }) => `User: ${user.firstName} ${user.lastName}`;
const ListItemWithToggle = ({ label, isOn }) => <li className="list-group-item">{label}{" "}<ShowBooleanAsIcon isTruthy={isOn} /></li>;

const UserShow = () => {
  const isLoading = useSelector(selectIsLoading);
  const status = useSelector(selectStatus);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();

  const getUser = () => {
    dispatch(fetchUser({ userId }));
  };

  useEffect(() => {
    getUser();

    return () => dispatch(clearUser());
  }, [userId]);

  useEffect(() => {
    if (status === "deleted") {
      navigate("/users");
    }
  }, [status]);

  const handleDelete = () => {
    dispatch(deleteUser({ user }));
  };

  const handleUnlock = () => {
    dispatch(unlockUser({ user }));
  };

  const handleMojoRequest = () => {
    dispatch(requestMojoUser({ user }));
  };

  const handleSendWelcomeMessage = () => {
    dispatch(welcomeUser({ user }));
    window.location.reload();
  };

  if (!user) return <LoadingPage />;

  return (
    <RequireAuth can="read user">
      <HeaderLayout>
        <BaseView
          headerSlot={() => Header({ user })}
        >
          <Container>
            <Row>
              <Col>
                <Pl>
                  <Pt>First Name</Pt>
                  <Pd>{user.firstName}</Pd>
                  <Pt>Last Name</Pt>
                  <Pd>{user.lastName}</Pd>
                  <Pt>Role</Pt>
                  <Pd>{humanizeString(user.role)}</Pd>
                  <Pt>Username</Pt>
                  <Pd>{user.username}</Pd>
                  <Pt>Multifactor authentication</Pt>
                  <Pd><ShowBooleanAsIcon isTruthy={user.mfa_required} /></Pd>
                </Pl>
              </Col>
              <Col>
                <Pl>
                  <Pt>Customer</Pt>
                  <Pd><NavLink to={`/customers/${user.customerId}`}>{user.customerName}</NavLink></Pd>
                  <Pt>Email Address</Pt>
                  <Pd><a href={`mailto:${user.email}`}>{user.email}</a></Pd>
                  <Pt>Alerts</Pt>
                  <Pd>
                    <ul className="list-group">
                      <ListItemWithToggle isOn={user.alerts?.usage} label="Usage" />
                      <ListItemWithToggle isOn={user.alerts?.notification} label="Notification" />
                      <ListItemWithToggle isOn={user.alerts?.marketing} label="Marketing" />
                    </ul>
                  </Pd>
                  <Pt>{!user.welcomedAt && (<p>Send Welcome Email</p>)}</Pt>
                  <Pd>{!user.welcomedAt && (<Button onButtonClick={handleSendWelcomeMessage} label="Send" />)}</Pd>
                </Pl>
              </Col>

            </Row>
          </Container>
          <ButtonGroup className="float-start">
            <Can requestMojoAccount instance={user}>
              <Button isLoading={isLoading} variant="primary" onButtonClick={handleMojoRequest} label="Request Mojo account" />
            </Can>
            {user.isLocked && (
              <Can unlock instance={user}>
                <Button isLoading={isLoading} variant="primary" onButtonClick={handleUnlock} label="Unlock user" />
              </Can>
            )}
          </ButtonGroup>
          <ButtonGroup className="float-end">
            <Can update instance={user}>
              <Button as={NavLink} to={`/users/${user.id}/edit`} variant="primary" label="Edit" />
            </Can>
            <Can delete instance={user}>
              <Button isLoading={isLoading} variant="primary" onButtonClick={handleDelete} label="Delete" />
            </Can>
          </ButtonGroup>
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default UserShow;
