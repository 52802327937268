import React from "react";
import CustomerList from "./CustomerList";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";

const Header = () => <>Customers</>;

const CustomerIndex = () => (
  <RequireAuth can="read customer">
    <HeaderLayout>
      <BaseView
        headerSlot={Header}
      >
        <CustomerList />
      </BaseView>
    </HeaderLayout>
  </RequireAuth>
);

export default CustomerIndex;
