import React from "react";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";
import { useError } from "../baseForm";

const Checkbox = ({ label, name, horizontal, ...props }) => {
  const { register, formState: { errors } } = useFormContext();
  const error = useError(errors, name)();
  const errorMessage = (error) ? error.message : false;
  const inputId = `${name}-checkbox`;
  const args = { ...props, id: inputId };

  return (
    <Form.Group>
      <Form.Check {...register(name)} label={label} {...args} isInvalid={error} />
      {errorMessage && <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback> }
    </Form.Group>
  );
};

export default Checkbox;
