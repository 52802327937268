import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as ReactDOMServer from "react-dom/server";
import { getColourByBonderStatus } from "./lib";
import BonderMapMarkerContent from "./BonderMapMarkerContent";

const BonderMaker = (options) => {
  const [marker, setMarker] = useState(false);
  const navigate = useNavigate();
  const { bondId, bonder, isOnlyMarkerForMap } = options;
  const { customerId } = useParams();
  const { position } = options;

  useEffect(() => {
    if (!marker) {
      const { bonderStatus } = bonder;
      const statusColour = getColourByBonderStatus(bonderStatus);
      setMarker(new google.maps.Marker({
        icon: `https://chart.apis.google.com/chart?chst=d_map_xpin_letter&chld=pin||${statusColour}`,
      }));
    }

    if (marker) {
      marker.addListener("click", () => {
        navigate(`/customers/${customerId}/bonders/${bondId}`, { replace: true });
      });

      if (!isOnlyMarkerForMap) {
        const infoWindow = new google.maps.InfoWindow({
          content: ReactDOMServer.renderToString(<BonderMapMarkerContent bonder={bonder} />),
        });

        marker.addListener("mouseover", () => infoWindow.open({
          anchor: marker,
          shouldFocus: false,
        }));

        marker.addListener("mouseout", () => infoWindow.close());
      }

      marker.setOptions(options);
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, position]);

  return null;
};

export default BonderMaker;
