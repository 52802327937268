import { createSlice } from "@reduxjs/toolkit";
import { camelize } from "humps";
import { isEmpty, omit } from "lodash";
import humanizeString from "string-humanize";

const initialState = {
  formErrors: {},
  messages: {},
};

export const systemMessageSlice = createSlice({
  name: "systemMessage",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const { payload } = action;
      const { commsPortalApiErrors } = { commsPortalApiErrors: [], ...payload };
      delete payload.commsPortalApiErrors;
      const { message } = { message: "", ...payload };

      if (isEmpty(message)) {
        return state;
      }

      const errors = Object.entries(commsPortalApiErrors)
        .map(([property, messages], index) => ({
          key: `error${index}`,
          error: `${humanizeString(property)}: ${messages.join(", ")}`
        }));

      const existingMessages = state.messages || [];
      const item = { ...payload, key: `message${Object.entries(existingMessages).length}`, errors };

      state.messages = { ...state.messages, [item.key]: item };
      state.formErrors = Object.fromEntries(
        Object.entries(commsPortalApiErrors).map(([property]) => [[camelize(property)], true])
      );

      return state;
    },
    clearMessages: (state) => {
      state.messages = {};
    },
    removeMessage: (state, action) => {
      state.messages = omit(state.messages, action.payload.key);
    },
  },
});

export const { removeMessage, addMessage, clearMessages } = systemMessageSlice.actions;

export const selectMessages = (state) => state.systemMessage.messages;
export const selectFormErrors = (state) => state.systemMessage.formErrors;

export default systemMessageSlice.reducer;
