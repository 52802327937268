import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import dateTimeDefaultFormat from "./dateTimeDefaultFormat";

const handlerDefaults = {
  enableDuration: false,
  enableLocalizedFormat: false,
  enableRelativeTime: false,
};

export const dateTimeHandler = (options) => {
  if (typeof options === "string") {
    return undefined;
  }

  if (typeof options === "number") {
    return undefined;
  }

  const {
    enableDuration,
    enableLocalizedFormat,
    enableRelativeTime,
  } = { ...handlerDefaults, ...options };

  if (enableDuration) {
    dayjs.extend(duration);
  }

  if (enableLocalizedFormat) {
    dayjs.extend(localizedFormat);
  }

  if (enableRelativeTime) {
    dayjs.extend(relativeTime);
  }

  dayjs.extend(timezone);
  dayjs.tz.setDefault("Europe/London");

  dayjs.extend(dateTimeDefaultFormat);

  return dayjs;
};

const DAYJS_SSS = "SSS";

export const MILLISECOND_FORMAT = DAYJS_SSS;

export default null;
