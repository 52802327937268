import terms from "../terms";

const mapTerm = (searchTerm) => {
  if (searchTerm === undefined) {
    return "";
  }

  const searchResult = terms.find(({ term }) => term === searchTerm);

  if (!searchResult) {
    return searchTerm;
  }

  const { value } = searchResult;
  return value;
};

export default mapTerm;
