import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addMessage } from "../systemMessage/systemMessageSlice";

const initialState = {
  users: {
    items: [],
  },
  errors: {},
  user: null
};

export const fetchUsers = createAsyncThunk(
  "user/fetch/users",
  async ({ pageNumber, itemsPerPage, filters }, { extra: { api }, rejectWithValue }) => {
    try {
      const params = { page: pageNumber, limit: itemsPerPage, ...filters };
      const { data } = await api.get(`/users`, { params });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetch/user",
  async ({ userId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/users/${userId}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update/user",
  async ({ user }, { extra: { api }, dispatch, rejectWithValue }) => {
    const { id } = user;
    delete user.id;
    try {
      const { data } = await api.patch(`/users/${id}`, { user });
      dispatch(addMessage({ message: `User ${user.email} updated`, type: "success" }));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/create",
  async ({ user }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.post(`/users`, { user });
      dispatch(addMessage({ message: `User ${user.email} created`, type: "success" }));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const welcomeUser = createAsyncThunk(
  "user/send_welcome_message",

  async ({ user: { id, firstName } }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.post(`/users/${id}/send_welcome_message`);
      dispatch(addMessage({ message: `Welcome message sent to ${firstName}`, type: "success" }));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestMojoUser = createAsyncThunk(
  "user/request/mojo-account",
  async ({ user: { id, firstName } }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/users/${id}/request_mojo_account`);
      dispatch(addMessage({ message: `Mojo user account requested for ${firstName}`, type: "success" }));
      return data;
    } catch (error) {
      dispatch(addMessage({ message: `Unable to request Mojo account for ${firstName}`, type: "danger" }));
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async ({ user }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/users/${user.id}`);
      dispatch(addMessage({ message: `User ${user.email} deleted`, type: "success" }));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const unlockUser = createAsyncThunk(
  "user/unlock",
  async ({ id, username }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.patch(`/users/${id}/unlock`);
      dispatch(addMessage({ message: `User ${username} unlocked`, type: "success" }));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const acceptInvitation = createAsyncThunk(
  "user/accept/invitation",
  async (
    { userId, token, password, passwordConfirmation },
    { extra: { api }, dispatch, rejectWithValue }
  ) => {
    try {
      await api.patch(`/users/${userId}/accept_invitation`, { invitation: token, password, passwordConfirmation });
      dispatch(addMessage({ message: "You have accepted this invitation and updated your password.", type: "success" }));
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.status = "idle";
        state.isLoading = false;
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "idle";
        state.isLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.errors = action.payload;
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "updated";
        state.isLoading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.errors = action.payload;
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "created";
        state.isLoading = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.errors = action.payload;
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "deleted";
        state.isLoading = false;
      })
      .addCase(acceptInvitation.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(acceptInvitation.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(acceptInvitation.fulfilled, (state) => {
        state.status = "accept";
        state.isLoading = false;
      });
  },
});

export const { clearUser } = userSlice.actions;

export const selectIsLoading = (state) => state.user.isLoading;
export const selectPagination = (state) => state.user.users.pagination;
export const selectStatus = (state) => state.user.status;
export const selectUser = (state) => state.user.user;
export const selectUsers = (state) => state.user.users.items;

export default userSlice.reducer;
