import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  reissueOneTimePassword,
  selectIsLoading,
  submitOneTimePassword,
} from "../../features/auth/authSlice";
import SystemMessages from "../../features/systemMessage/SystemMessages";
import { Button } from "../../components";
import BaseForm from "../baseForm";
import Input from "../inputs/Input";

const OneTimePasswordForm = ({ defaults }) => {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  const handleFormSubmit = ({ totp }) => {
    dispatch(submitOneTimePassword({ totp }));
  };

  // eslint-disable-next-line
  const requestAnotherTOTP = () => {
    dispatch(reissueOneTimePassword());
  };

  return (
    <>
      <SystemMessages />
      <BaseForm onSubmit={handleFormSubmit} defaults={defaults}>
        <Input label="TOTP" hideLabel name="totp" type="text" />
        <Button type="submit" isLoading={isLoading} label="Login" className="float-end mt-3" />
        {/* TODO - Reinstate when API endpoint is available
          <Button
            type="button"
            onButtonClick={requestAnotherTOTP}
            variant="link"
            label="Resend Code"
            className="float-end mt-3"
          />
        */}
      </BaseForm>
    </>
  );
};

export default OneTimePasswordForm;
