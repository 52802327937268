import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

const Paginater = ({ pagination, onPageChange, ...args }) => {
  const handlePageClick = ({ selected }) => {
    selected += 1;
    if (onPageChange) {
      onPageChange({ selected });
    }
  };

  let props;
  if (pagination.currentPage) {
    props = { ...args, initialPage: pagination.currentPage };
  }

  if (pagination.totalPages) {
    props = { ...args, pageCount: pagination.totalPages };
  }

  return (
    <div>
      <ReactPaginate
        breakClassName="page-item"
        breakLabel={<span className="page-link">...</span>}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        pageCount={pagination.totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        {...props}
      />
    </div>
  );
};

Paginater.defaultProps = {
  onPageChange: () => {},
  pagination: {
    totalPages: 1,
  },
};

Paginater.propTypes = {
  onPageChange: PropTypes.func,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }),
};

export default Paginater;
