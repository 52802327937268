import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { clearMessages } from "../../features/systemMessage/systemMessageSlice";

const Link = (props) => {
  const dispatch = useDispatch();

  const { onLinkClick } = props;
  const onClick = (event) => {
    dispatch(clearMessages());
    if (onLinkClick) {
      onLinkClick(event);
    }
  };

  return <RouterLink onClick={onClick} {...props} />;
};

export default Link;
