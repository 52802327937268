import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import HeaderlessLayout from "../layouts/headerless";
import OneTimePasswordForm from "../forms/OneTimePasswordForm";
import { selectStatus, selectLastLocationPathname } from "../features/auth/authSlice";
import { addMessage } from "../features/systemMessage/systemMessageSlice";

const OneTimePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector(selectStatus);
  const from = useSelector(selectLastLocationPathname);

  useEffect(() => {
    dispatch(addMessage({ message: "Please enter the passcode for further verification", type: "danger" }));
  }, []);

  useEffect(() => {
    if (status === "totp-accepted") {
      const destination = from || "/";
      navigate(destination);
    }
  }, [status]);

  return (
    <HeaderlessLayout>
      <Container id="one-time-password-requested" fluid>
        <Row className="d-flex justify-content-center">
          <Col md="auto">
            <Card>
              <Card.Body>
                <Card.Title>User verification</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">A PIN code has been sent to your email. Please provide it to continue.</Card.Subtitle>
                <Card.Body>
                  <OneTimePasswordForm />
                </Card.Body>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </HeaderlessLayout>
  );
};

export default OneTimePassword;
