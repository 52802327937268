import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "../../components/Button/Button";
import Can from "../../components/Can";
import NavLink from "../../components/NavLink/NavLink";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import PackageList from "../package/PackageList";
import {
  clearCustomer,
  deleteCustomer,
  fetchCustomer,
  selectCustomer,
  selectStatus,
} from "./customerSlice";
import BaseView from "../../components/BaseView/BaseView";
import LoadingPage from "../../pages/LoadingPage";
import { Pl, Pt, Pd } from "../../components/PropertyList/PropertyList";

const Header = ({ customer }) => `Customer: ${customer.name}`;
const PackageHeader = () => "Packages";

const ParentLink = ({ customerId, customerName }) => {
  if (customerId === "") return "";

  return (
    <>
      <Pt>Parent</Pt>
      <Pd>
        <NavLink to={`/customers/${customerId}`}>
          {customerName}
        </NavLink>
      </Pd>
    </>
  );
};

const CustomerShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const customer = useSelector(selectCustomer);
  const status = useSelector(selectStatus);

  const getCustomer = () => {
    dispatch(fetchCustomer({ customerId }));
  };

  useEffect(() => {
    getCustomer();

    return () => dispatch(clearCustomer());
  }, []);

  useEffect(() => {
    if (status === "deleted") {
      navigate("/customers");
    }
  }, [status]);

  const handleDelete = () => {
    dispatch(deleteCustomer({ customer }));
  };

  const bondSpaceLink = {
    title: "Spaces",
    to: `/customers/${customerId}/spaces`,
    permission: { verb: "read", noun: "customer" },
    exact: true,
  };

  const subPageLinks = [];

  if (customer == null) return <LoadingPage />;

  if (customer.bondCount > 0) {
    subPageLinks.push(bondSpaceLink);
  }

  return (
    <RequireAuth can="read customer">
      <HeaderLayout>
        <BaseView
          headerSlot={() => Header({ customer })}
          subPageLinks={subPageLinks}
        >
          <Container>
            <Row>
              <Col>
                <Pl>
                  <Pt>Name</Pt>
                  <Pd>{customer.name}</Pd>
                  <ParentLink
                    customerId={customer.customerId}
                    customerName={customer.customerName}
                  />
                  <Pt>Category</Pt>
                  <Pd>{customer.category}</Pd>
                  <Pt>Master SIM Agreement</Pt>
                  <Pd className="masterSimAgreement">{customer.masterSimAgreement ? "Yes" : "No"}</Pd>
                  <Pt>Master Service Agreement</Pt>
                  <Pd className="masterServiceAgreement">{customer.masterServiceAgreement ? "Yes" : "No"}</Pd>
                  <Pt>Internal</Pt>
                  <Pd>{customer.internal ? "Yes" : "No"}</Pd>
                  <Can admin="customer">
                    <Pt>Has own Radius</Pt>
                    <Pd>{customer.hasOwnRadius ? "Yes" : "No"}</Pd>
                  </Can>
                  <Pt>Number of bonds</Pt>
                  <Pd>{customer.bondCount}</Pd>
                </Pl>
              </Col>
              <Col>
                <Pl>
                  <Pt>Email Address</Pt>
                  <Pd>{customer.email}</Pd>
                  <Pt>Phone</Pt>
                  <Pd>{customer.phone}</Pd>
                  <Pt>Address</Pt>
                  <Pd>
                    {customer.address1} <br />
                    {customer.address2} <br />
                    {customer.city} <br />
                    {customer.zip} <br />
                  </Pd>
                </Pl>
              </Col>
            </Row>
          </Container>
          <ButtonGroup className="float-end">
            <Can update="customer">
              <Button as={NavLink} to={`/customers/${customer.id}/edit`} variant="success" label="Edit" />
            </Can>
            <Can delete="customer">
              <Button variant="danger" onButtonClick={handleDelete} label="Delete" />
            </Can>
          </ButtonGroup>
        </BaseView><br />
        <BaseView headerSlot={() => PackageHeader()}>
          <PackageList />
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default CustomerShow;
