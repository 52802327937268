import React from "react";
import Card from "react-bootstrap/Card";
import NavBar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Outlet } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import RenderSlot from "../RenderSlot/RenderSlot";
import NavLink from "../NavLink";
import UseCan from "../../lib/UseCan";
import "./BaseView.scss";
import { selectCurrentUser } from "../../features/auth/authSlice";

const SubPageLinks = ({ subPageLinks }) => {
  const user = useSelector(selectCurrentUser);
  const canDo = UseCan(user);

  return (
    <>
      <NavBar bg="light" className="sub-page-navigation">
        <Container>
          <Nav fill variant="pills">
            {subPageLinks.map((page) => {
              const { permission: { verb, noun }, exact } = page;
              if (!canDo(verb, noun)) {
                return null;
              }

              return (
                <Nav.Link
                  key={page.to}
                  {...exact}
                  as={NavLink}
                  to={page.to}
                  className={(navData) => (navData.isActive ? "active" : "")}
                >
                  {page.title}
                </Nav.Link>
              );
            })}
          </Nav>
        </Container>
      </NavBar>
      <Card.Body>
        <Outlet />
      </Card.Body>
    </>
  );
};

const BaseView = ({
  afterBodySlot,
  afterPageSlot,
  afterSubPagesSlot,
  beforeBodySlot,
  beforePageSlot,
  breadcrumbSlot,
  children,
  headerSlot,
  subPageLinks,
}) => (
  <>
    <RenderSlot component={beforePageSlot} />
    <Card>
      <Card.Header as="h3" className="bg-primary text-white">
        <RenderSlot component={headerSlot} />
      </Card.Header>
      <RenderSlot component={beforeBodySlot} />
      <Card.Body>
        {breadcrumbSlot && <RenderSlot component={breadcrumbSlot} />}
        {children}
      </Card.Body>
      <RenderSlot component={afterBodySlot} />
      {!isEmpty(subPageLinks) && <SubPageLinks subPageLinks={subPageLinks} />}
      <RenderSlot component={afterSubPagesSlot} />
    </Card>
    <RenderSlot component={afterPageSlot} />
  </>
);

export default BaseView;
