import React from "react";
import IpRangeList from "./IpRangeList";
import RequireAuth from "../../lib/RequireAuth";

const IpRangeIndex = () => (
  <RequireAuth can="read ipaddress">
    <IpRangeList />
  </RequireAuth>
);

export default IpRangeIndex;
