import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Card, Col, Row, Tabs, Tab, TabPane, Breadcrumb } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import { BaseView } from "../../components";
import HeaderLayout from "../../layouts/header";
import RequireAuth from "../../lib/RequireAuth";
import {
  clearBonder,
  fetchBonder,
  selectBonder,
  selectIsBonderLoading,
} from "./bonderSlice";
import { fetchCustomer, selectCustomer } from "../customer/customerSlice";
import BonderDetails from "./BonderDetails";
import BonderGmap from "./BonderGmap";
import IperfCreate from "../iperf/IperfCreate";
import IperfResults from "../iperf/IperfResults";
import IperfLaunchPad from "../iperf/IperfLaunchPad";
import SpeedTests from "../speedtest/SpeedTests";

import "./bonder.scss";

const Header = (customerName) => `Spaces: ${customerName}`;

const BonderShow = () => {
  const bonder = useSelector(selectBonder);
  const isLoading = useSelector(selectIsBonderLoading);
  const { bonderId, customerId, tabId } = useParams();
  const customer = useSelector(selectCustomer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const DEFAULT_TAB = "details";
  const initialTab = (tabId) || DEFAULT_TAB;
  const [activeTab, setActiveTab] = useState(initialTab);

  const getBonder = () => {
    dispatch(fetchCustomer({ customerId }));
    dispatch(fetchBonder({ customerId, bonderId }));
  };

  useEffect(() => {
    getBonder();

    return () => dispatch(clearBonder());
  }, [bonderId]);

  useEffect(() => {
    navigate(`/customers/${customerId}/bonders/${bonderId}/${activeTab}`);
  }, [activeTab]);

  const headerSlot = (customer) ? () => Header(customer.name) : null;

  let bonderDetails;
  if (bonder) {
    bonderDetails = (
      <Col>
        {bonder.geoLocation === null && <p>Map location is unknown</p>}
        {bonder.geoLocation && (
          <Col md={12} key="col-map" className="my-3">
            <BonderGmap
              mapDimensions={{ height: "200px", width: "100%" }}
              bonders={[{
                position: bonder.geoLocation,
                ...bonder,
              }]}
              customerId={bonder.customerId}
            />
          </Col>
        )}
        <Col md={12} key="col-tabs">
          <Card>
            <div className="card-body">
              <Tabs
                id="bonder-info-tab"
                activeKey={initialTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-3"
              >
                <Tab eventKey={DEFAULT_TAB} title="Details">
                  <Row>
                    <BonderDetails bonder={bonder} />
                  </Row>
                </Tab>
                <TabPane eventKey="speedtests" title="Speed tests">
                  <Row>
                    <SpeedTests customerId={customerId} bonderId={bonderId} />
                  </Row>
                </TabPane>
                <TabPane eventKey="IperfResults" title="Iperf tests">
                  <Row>
                    <Col>
                      <h4>Iperf tests</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-4 py-4">
                      {bonder.isIperfable && (<IperfCreate />)}
                      {!bonder.isIperfable && (
                        <Alert variant="danger" data-reason="not-iperfable">
                          Iperf tests can&apos;t be run on this bond.
                        </Alert>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <IperfLaunchPad />
                    </Col>
                  </Row>
                  <Row>
                    <IperfResults />
                  </Row>
                </TabPane>
              </Tabs>
            </div>
          </Card>
        </Col>
      </Col>
    );
  }

  return (
    <RequireAuth can="read customer">
      <HeaderLayout>
        <BaseView headerSlot={headerSlot}>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item key="bread-space-home">SD-One</Breadcrumb.Item>
              {customer && (
                <Breadcrumb.Item key="bread-customer" href={`/customers/${customer.id}/spaces`}>
                  {customer.name}
                </Breadcrumb.Item>
              )}
              {bonder && (
                <Breadcrumb.Item key="bread-bonder" active href={`/spaces/bonder/${bonder.id}`}>
                  {bonder.name}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
            {isLoading && (
              <Row>
                <Col>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            )}
            {bonderDetails}
          </Row>
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default BonderShow;
