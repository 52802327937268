import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
  selectMasterCpuLoad,
} from "../../bonder/bonderSlice";
import { NoData, LineChart } from "../../../components/LineChart";

const MasterCpuLoad = ({ testRef }) => {
  const masterCpuLoadData = useSelector(selectMasterCpuLoad);
  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    if (masterCpuLoadData[testRef]) {
      const data = Object
        .values(masterCpuLoadData[testRef])
        .map((loads, cpuId) => loads.map(
          ([x, y]) => ({ testRef, type: "master", cpuId, xAxisValue: x, yAxisValue: y })
        ));

      if (data.length > 0) {
        setChartData(data);
      }
    }
    return undefined;
  }, [masterCpuLoadData]);

  return (
    <Col className="col-md-10 offset-md-1">
      {chartData && (
        <LineChart
          data={chartData}
          targetHtml={`master-cpu-load-${testRef}`}
          yAxisMaxValue={100}
          yAxisNumberFormat="#,###a%"
          yAxisMinGridDistance={20}
          showLineBullets
          showLegend
        />
      )}
      {!chartData && (<NoData />)}
    </Col>
  );
};

export default MasterCpuLoad;
