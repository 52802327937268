import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import ConnectionForm from "../../forms/ConnectionForm/ConnectionForm";
import {
  clearConnection,
  fetchConnection,
  selectConnection,
  selectIsLoading,
  selectStatus,
  updateConnection,
} from "./connectionSlice";
import LoadingPage from "../../pages/LoadingPage";

const Header = () => <>Update connection</>;

const ConnectionEdit = () => {
  const connection = useSelector(selectConnection);
  const { connectionId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector(selectStatus);
  const isLoading = useSelector(selectIsLoading);

  const getCustomer = () => {
    dispatch(fetchConnection({ connectionId }));
  };

  useEffect(() => {
    getCustomer();

    return () => dispatch(clearConnection());
  }, []);

  useEffect(() => {
    if (status === "updated") {
      navigate(`/connections/${connectionId}`);
    }
  }, [status]);

  const handleSubmit = (newConnection) => {
    dispatch(updateConnection({ connection: newConnection }));
  };

  if (isLoading) return <LoadingPage />;

  return (
    <RequireAuth can="update connection">
      <HeaderLayout>
        <BaseView
          headerSlot={Header}
        >
          <ConnectionForm onSubmit={handleSubmit} defaults={connection} />
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default ConnectionEdit;
