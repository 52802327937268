import opiousLogo from "../opious/opious.png";
import anvilLogo from "../anvil/anvil.png";
import prodecLogo from "../prodec/prodec.png";

const BrandLogos = () => {
  const assets = {
    opiousLoginLogo: opiousLogo,
    anvilLoginLogo: anvilLogo,
    prodecLoginLogo: prodecLogo
  };

  return assets;
};

export default BrandLogos;
