/* Ignoring eslint rules to preserve HTML structure and convenience */
/* eslint-disable max-len */
import React from "react";
import { Col, Card, Row, Table } from "react-bootstrap";
import HeaderLayout from "../layouts/header";
import { Link } from "../components";

const PrivacyPolicy = () => (
  <HeaderLayout>
    <Row>
      <Col className="col-10">
        <h1>Comms365 Privacy Policy (Data Protection Policy)</h1>
        <p>Comms365 takes seriously the privacy and personal data of our clients, suppliers, visitors and employment applicants. As the data controller, Comms365 (referred to in this Policy as “we”, “our” or “us”) endeavours to ensure that we are open and honest about how we use individual’s personal information. It is our responsibility to safeguard this information in accordance with the GDPR and Data Protection Act 2018. This Policy is intended to provide detailed information on how we store and use your personal data, including your rights as a data subject when you visit our website or use any of our services.</p>

        <h2>What Personal Data Do We Collect and Why?</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Data Subject</th>
              <th>Type of Data</th>
              <th>Why we need it</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Direct Customers and Partners (including web-shop customers and, potential customers and partners).</td>
              <td>Name, job title and contact information.</td>
              <td>To manage our sales activities including order processing and onsite visits.
                To market our products, services and events. To collect feedback and insight through a survey invite. To provide you with a CommsPortal account so that you can monitor your data usage.
                To provide you with a Helpdesk account so that you can raise a support request.
                To respond to and act on any queries about our services, our products or training.
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>Billing information.</td>
              <td>To process payment for our products.</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>CommsPortal username and password, IoT hardware data (if you are a client, depending on the type of service you have with us, this may include data relating to your customers). IP address (collected via Google Analytics).</td>
              <td>For the provision, maintenance and operation of CommsPortal. To process and provide you with additional IoT analysis and statistics. To monitor the use of our platform.</td>
            </tr>
            <tr>
              <td>Suppliers</td>
              <td>Name, job title and contact information.</td>
              <td>For the provision and maintenance of relevant products and services to our customers.</td>
            </tr>
            <tr>
              <td>Job Applicants</td>
              <td>Full name, employment history, contact information.</td>
              <td>To recruit and hire potential employees.</td>
            </tr>
            <tr>
              <td>Visitors to our offices</td>
              <td>Face, appearance, name, signature, date and time of visit.</td>
              <td>To maintain the security of our premises.</td>
            </tr>
            <tr>
              <td>Visitors to Comms365 website</td>
              <td>IP address (collected via Google Analytics). Please see our <Link to="/cookie-policy">Cookies Policy</Link> for information on the type of cookies we collect and how to turn this function off.</td>
              <td>To maintain, monitor and improve the performance and use of our website.</td>
            </tr>
            <tr>
              <td>Website &apos;Contact&apos; form users</td>
              <td>Name, contact number and any other personal information you choose to disclose.</td>
              <td>To provide a response to your query.</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>Billing information.</td>
              <td>To process payment for our products.</td>
            </tr>
            <tr>
              <td>Webinar Registrations</td>
              <td>Name, Job Title, Company, Email Address.</td>
              <td>To register you for any webinars you sign up to.</td>
            </tr>
            <tr>
              <td>Newsletter Subscriptions</td>
              <td>Name, Job Title, Company, Email Address.</td>
              <td>To register you to receive our e-newsletter.</td>
            </tr>
          </tbody>
        </Table>

        <p>We also collect information to determine potential customers, partners and suppliers from third party sources for lead generation. These include professional networking platforms, credit reference agencies and business directories.</p>
        <Card bg="info" text="white">
          <Card.Header>Please note</Card.Header>
          <Card.Body>
            <Card.Text>
              We will <u>not</u> use Personal Data for any other purpose other than those listed in this Privacy Policy.
            </Card.Text>
          </Card.Body>
        </Card>
        <p>&nbsp;</p>
        <h2>What is Our Lawful Basis for Processing Personal Data?</h2>

        <p>We rely on legitimate interests to process personal data for the reasons set out above and below:</p>
        <ul>
          <li>For the promotion of our services, products and events to develop and grow our business.</li>
          <li>For the management of our relationship and communication with you as a customer/supplier/partner/job applicant.</li>
          <li>To carry out due diligence checks on prospective clients and suppliers.</li>
          <li>For the safety and security of our employees and visitors.</li>
        </ul>
        <p>When you sign up to a survey or webinar, we rely on your consent to process your personal data.</p>
        <p>We will use your details to market and promote our services to you. In any written marketing communication, we will always give you the option to unsubscribe. You can also do this by contacting us using the details below.</p>

        <h2>Who is Your Personal Data Shared With?</h2>

        <p>We will sometimes share personal information with the following third parties;</p>
        <ul>
          <li>Email application and marketing software providers.</li>
          <li>File hosting service.</li>
          <li>Hosted service management portal.</li>
        </ul>
        <p>If you agree to us contacting you for marketing purposes, your personal details may also be shared with other UK based companies that provide marketing consultancy services to us. Where this is the case, any marketing communication you receive will come directly from us.</p>
        <p>We will only share information with companies who comply with the Data Protection Act 2018 and GDPR.</p>

        <h2>Personal Data Transfers Outside the European Economic Area (EEA)</h2>

        <p>We are responsible for protecting the security of all personal information in our control including personal information which is transferred outside the EEA. Whilst most of our data-sharing parties process personal information within the EEA, some of these providers process information that is stored in back-up servers located in the United States. This information is safeguarded under their memberships of the EU-US Privacy Shield.</p>

        <h2>How Long Will We Keep Your Personal Data for?</h2>
        <h3>Customers/Partners/Suppliers</h3>

        <p>Your data will be kept for six years after the end of our relationship with you / end of your order term.</p>
        <p>If you have a CommsPortal account with us, any personal data stored on CommsPortal will be deleted as soon as your service has been cancelled.</p>
        <p>If you have raised a CommsPortal Helpdesk ticket, these will be deleted six years after the tickets have been closed.</p>
        <p>The information we use to bill you for our services will be deleted after a period of six years from the date of your last invoice.</p>
        <p>Where we have identified you as a potential customer/partner/supplier, we will keep your data for a period of six months in the case of inactivity.</p>

        <h3>Job Applicants</h3>
        <p>If unsuccessful, your personal data will be deleted immediately after review unless you agree to allow us to keep your personal data on file for consideration for future employment. Any recruitment records arising from the interview process will be deleted after a period of six months.</p>

        <h3>Visitors to our offices</h3>
        <p>The information collected from our CCTV at the time of your visit to our office is recorded for a 30-day period. Other information relating to the date and time of your visit is kept for a period of 12 months.</p>

        <h3>Website &apos;Contact Us&apos; form users</h3>
        <p>Your data will be kept for six years after your query has been resolved.</p>

        <h2>Your Rights as a Data Subject</h2>
        <p>Your rights include:</p>
        <ul>
          <li><strong>The right to be informed</strong> about how we process and use your personal information. In the event of any proposed changes to </li>our processing activities, this Privacy Policy will be amended accordingly, communicated with you and, where applicable, further consent will be sought.
          <li><strong>The right to access</strong> to a copy of the personal data we hold about you at any time (Subject Access Request, (SAR). A SAR can </li>be made verbally or in writing. We will respond to you within one month of the date of your request. If you would like to make a SAR, please contact us via email, telephone or in writing using the details below.
          <li><strong>The right to have</strong> any of the personal data we hold about you rectified or completed if incomplete. Where relevant, any </li>changes will be communicated to the parties with whom your data has been shared.
          <li><strong>The right to be forgotten</strong>, where the processing is no longer necessary for the purpose it was originally collected for; </li>where there is no over-riding legitimate interest to continue processing the data; or where the processing is unlawful.
          <li><strong>The right to restrict</strong> processing; where the information is inaccurate; or where the processing is unlawful.</li>
          <li><strong>The right to request</strong> a transfer of your personal information to another party.</li>
          <li><strong>The right to object</strong> to the processing of your personal data where the processing is based on legitimate interests.</li>
          <li><strong>Rights in relation to automated decision making and profiling.</strong></li>
        </ul>
        <h2>How to Contact Us</h2>

        <p>If you would like to exercise any of your rights mentioned in this Privacy Policy, you can do so by calling, emailing or writing to us at:</p>
        <p>
          Comms365<br />
          South House 3<br />
          Bond Avenue<br />
          Milton Keynes<br />
          MK1 1SW
        </p>
        <p>01234 865880</p>
        <p><a href="mailto:compliance@comms365.com">compliance@comms365.com</a></p>

        <h2>Where to Complain</h2>
        <p>We sincerely hope you never have cause to complain about this Privacy Policy but if you do, please get in touch with us in the first instance using the details provided above. You can also raise a complaint with the Information Commissioner’s Office who are the local Data Protection Authority.</p>

        <h2>Changes to this Policy</h2>
        <p>We may change this policy from time to time. Please refer to our website for the most up to date version. If there are any significant changes, we will notify you by email or in writing.</p>
      </Col>
    </Row>
  </HeaderLayout>
);

export default PrivacyPolicy;
