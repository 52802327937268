import React from "react";
import { connect } from "react-redux";
import humanizeString from "string-humanize";
import Button from "../Button";

const ConnectionAction = ({ actionHandler, action, connectionId }) => {
  const onClick = () => {
    if (actionHandler) actionHandler({ connectionId, action });
  };

  return (
    <Button
      type="submit"
      onButtonClick={onClick}
      value={action}
      label={humanizeString(action)}
      variant="link"
      className="mt-3"
    />
  );
};

export default connect()(ConnectionAction);
