import React from "react";
import UserList from "./UserList";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";

const Header = () => <>Users</>;

const UserIndex = () => (
  <RequireAuth can="read user">
    <HeaderLayout>
      <BaseView
        headerSlot={Header}
      >
        <UserList />
      </BaseView>
    </HeaderLayout>
  </RequireAuth>
);

export default UserIndex;
