import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "../../components";
import { fetchConnection } from "./connectionSlice";

const LinkedConnection = ({ id, operator, service, status: connectionStatus }) => {
  const dispatch = useDispatch();

  const getConnection = (connectionId) => {
    dispatch(fetchConnection(connectionId));
  };

  return (
    <>
      <NavLink to={`/connections/${id}`} onClick={() => getConnection({ connectionId: id })}>ID: {id}, {service}, {operator}</NavLink>
      {" "}
      ({connectionStatus})
    </>
  );
};

const LinkedConnections = ({ linkedConnections }) => {
  const connections = linkedConnections.map((connection) => (
    <li key={`link-to-${connection.id}`}>
      <LinkedConnection {...connection} />
    </li>
  ));

  return (
    <ul>
      {connections}
    </ul>
  );
};

export default LinkedConnections;
