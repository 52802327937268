const entityGroupToOption = (value, label) => {
  if (value && label) {
    return ({ value, label });
  }

  return false;
};

const mapEntityGroupDataToSelectOptions = (entityGroupData) => (
  (entityGroupData)
    ? entityGroupData.map(({ id, name }) => entityGroupToOption(id, name))
      .filter((entityGroup) => entityGroup)
    : []
);

export default mapEntityGroupDataToSelectOptions;
