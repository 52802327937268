import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themesAnimated);

const defaultXAxisType = "DURATION_AXIS";
export const DATE_AXIS = "DATE_AXIS";

export const generateMultiLineChart = ({ targetHtml, data, ...options }) => {
  const chart = am4core.create(targetHtml, am4charts.XYChart);

  const defaultOptions = {
    colourList: undefined,
    showCursor: false,
    showLegend: false,
    showLineBullets: false,
    xAxisType: defaultXAxisType,
    xAxisMinGridDistance: 0.0001,
    yAxisMaxValue: undefined,
    yAxisMinGridDistance: undefined,
    yAxisNumberFormat: undefined,
    yAxisTitle: "",
  };
  const {
    colourList,
    showCursor,
    showLegend,
    showLineBullets,
    xAxisType,
    xAxisMinGridDistance,
    yAxisMaxValue,
    yAxisMinGridDistance,
    yAxisNumberFormat,
    yAxisTitle,
  } = { ...defaultOptions, ...options };

  if (colourList) {
    chart.colors.list = colourList.map((colourHex) => am4core.color(colourHex));
  }

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = yAxisTitle;

  if (yAxisMinGridDistance) {
    valueAxis.renderer.minGridDistance = yAxisMinGridDistance;
  }

  if (yAxisNumberFormat) {
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = yAxisNumberFormat;
  }

  if (yAxisMaxValue) {
    valueAxis.max = yAxisMaxValue;
  }

  const xAxis = (xAxisType === DATE_AXIS) ? new am4charts.DateAxis() : new am4charts.DurationAxis();
  chart.xAxes.push(xAxis);
  xAxis.renderer.minGridDistance = xAxisMinGridDistance;

  data.map((series) => {
    const dataSeries = chart.series.push(new am4charts.LineSeries());

    if (xAxisType === defaultXAxisType) {
      dataSeries.dataFields.valueX = "xAxisValue";
    } else {
      dataSeries.dataFields.dateX = "xAxisValue";
    }

    dataSeries.dataFields.valueY = "yAxisValue";
    dataSeries.strokeWidth = 3;
    dataSeries.fillOpacity = 0.2;
    if (showLineBullets) {
      const circleBullet = dataSeries.bullets.push(new am4charts.CircleBullet());
      circleBullet.tooltipText = "Value: [bold]{yAxisValue}[/]";
    }
    dataSeries.tooltip.background.fill = am4core.color("#fff");
    dataSeries.tooltip.label.fill = am4core.color("#00");

    const { cpuId, name } = { cpuId: undefined, name: undefined, ...series.pop() };
    if (cpuId !== undefined) {
      dataSeries.name = `CPU ${cpuId + 1}`;
    }

    if (name) {
      dataSeries.name = name;
    }

    dataSeries.data = series;

    return series;
  });

  if (showCursor) {
    chart.cursor = new am4charts.XYCursor();
  }

  if (showLegend) {
    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
  }

  chart.numberFormatter.numberFormat = "#.##";

  return chart;
};

export default null;
