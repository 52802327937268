import React from "react";
import { Breadcrumb } from "react-bootstrap";

const ConnectionBreadCrumb = ({ connection: { id, customerId, customerName } }) => (
  <Breadcrumb>
    <Breadcrumb.Item href={`/customers/${customerId}`} data-entity-id={id} className="entity-link">
      {customerName}
    </Breadcrumb.Item>
    <Breadcrumb.Item href="/connections">
      Connections
    </Breadcrumb.Item>
    <Breadcrumb.Item active>ID: {id}</Breadcrumb.Item>
  </Breadcrumb>
);

export default ConnectionBreadCrumb;
