import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingPage from "../../pages/LoadingPage";
import Paginater from "../../components/Paginater";
import Link from "../../components/Link";
import {
  fetchPackages,
  selectPagination,
  selectPackages
} from "./packageSlice";

const PackageList = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const pagination = useSelector(selectPagination);
  const packages = useSelector(selectPackages);
  const [selectedPage, setSelectedPage] = useState(1);

  const onPaginaterClick = ({ selected }) => {
    setSelectedPage(selected);
  };

  const getPackages = () => {
    dispatch(fetchPackages({ customerId, itemsPerPage: 10, pageNumber: selectedPage }));
  };

  useEffect(() => {
    getPackages();
  }, [selectedPage]);

  const getCategoryString = (category) => {
    switch (category) {
      case 0:
        return "Aggregate";
      case 1:
        return "Continuum";
      case 2:
        return "Prism";
      default:
        return "";
    }
  };

  if (packages == null) return <LoadingPage />;

  return (
    <div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Category</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((item) => (
            <tr key={item.id}>
              <th scope="row">{item.id}</th>
              <td><Link to={`/packages/${item.id}`}>{item.name}</Link></td>
              <td>{getCategoryString(item.category)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginater pagination={pagination} onPageChange={onPaginaterClick} />
    </div>
  );
};

export default PackageList;
