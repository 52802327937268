import React, { useEffect, useState } from "react";
import humanizeString from "string-humanize";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { isEmpty } from "lodash";
import Button from "../../components/Button";
import Can from "../../components/Can";
import config from "../../config";
import Filter from "../../components/Filter";
import Input from "../../forms/inputs/Input";
import Link from "../../components/Link";
import { fetchUsers, selectIsLoading, selectUsers, selectPagination } from "./userSlice";
import NavLink from "../../components/NavLink";
import Paginater from "../../components/Paginater";
import ResultsTable from "../../components/ResultsTable/ResultsTable";
import Select from "../../forms/inputs/Select";

const USER_ROWS_PER_PAGE = 10;

const NameCell = ({ value, ...props }) => {
  const { firstName, lastName } = props.row.original;
  return (<Link to={`/users/${value}`}>{firstName} {lastName}</Link>);
};

const UserList = () => {
  const isLoading = useSelector(selectIsLoading);
  const users = useSelector(selectUsers);
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState(1);

  const onPaginaterClick = ({ selected }) => {
    setSelectedPage(selected);
  };

  const getUsers = () => {
    dispatch(fetchUsers({ itemsPerPage: USER_ROWS_PER_PAGE, pageNumber: selectedPage }));
  };

  const handleFilterChange = (filters) => {
    if (!isEmpty(filters)) {
      dispatch(fetchUsers({
        itemsPerPage: USER_ROWS_PER_PAGE,
        pageNumber: selectedPage,
        filters,
      }));
    }
  };

  useEffect(() => {
    getUsers();
  }, [selectedPage]);

  const columns = [
    {
      header: "Name",
      accessor: "id",
      Cell: NameCell,
    },
    {
      header: "Email",
      accessor: "email",
    },
    {
      header: "Role",
      accessor: "role",
      Cell: ({ value }) => humanizeString(value || "")
    },
  ];

  const roleOptions = config.userRoles.map((role) => ({
    value: role,
    label: humanizeString(role)
  }));
  roleOptions.unshift({ value: "", label: "Any" });

  return (
    <>
      <Row>
        <Col>
          <Can create="user">
            <Button as={NavLink} to="/users/create" className="float-end mb-3" label="New user" />
          </Can>
        </Col>
      </Row>
      <Row>
        <Col>
          <Filter onFilterChange={handleFilterChange}>
            <Row>
              <Col>
                <Input name="first_name_like" label="First name" />
              </Col>
              <Col>
                <Input name="last_name_like" label="Last name" />
              </Col>
              <Col>
                <Select name="role_eq" label="Role" options={roleOptions} />
              </Col>
            </Row>
          </Filter>
        </Col>
      </Row>
      <ResultsTable data={users} columns={columns} isLoading={isLoading} />
      <Paginater pagination={pagination} onPageChange={onPaginaterClick} />
    </>
  );
};

export default UserList;
