import {
  createSlice,
} from "@reduxjs/toolkit";
import config, { ADVERT_HEADER_BANNER, ADVERT_LEFT_SIDEBAR_BANNER } from "../../config";
import { getOffsetFromAdvertsByPointer } from "./lib";

const initialState = {
  adverts: config.adverts,
  currentHeaderAdvert: {},
  currentSidebarAdverts: {},
  sidebarPointer: null,
  headerPointer: null,
  offset: 0,
};

export const advertSlice = createSlice({
  name: "advert",
  initialState,
  reducers: {
    pickHeaderAdvert: (state, action) => {
      const advertData = Object.values(state.adverts)
        .filter(({ type }) => (type === ADVERT_HEADER_BANNER));

      const { limit } = action.payload;
      const { headerPointer } = state;

      state.offset = getOffsetFromAdvertsByPointer({ advertData, key: headerPointer });
      state.currentHeaderAdvert = advertData.slice(state.offset, state.offset + limit);
    },
    pickSidebarAdverts: (state, action) => {
      const advertData = Object.values(state.adverts)
        .filter(({ type }) => (type === ADVERT_LEFT_SIDEBAR_BANNER));

      const { limit } = action.payload;
      const { sidebarPointer } = state;

      state.offset = getOffsetFromAdvertsByPointer({ advertData, key: sidebarPointer });
      state.currentSidebarAdverts = advertData.slice(
        state.offset,
        state.offset + limit
      );
    },
    updateHeaderPointer: (state, action) => {
      const { pointer } = { ...action.payload };
      state.headerPointer = pointer;
    },
    updateSidebarPointer: (state, action) => {
      const { pointer } = { ...action.payload };
      state.sidebarPointer = pointer;
    },
  },
  extraReducers: () => {},
});

export const {
  pickHeaderAdvert,
  pickSidebarAdverts,
  updateHeaderPointer,
  updateSidebarPointer,
} = advertSlice.actions;

export const selectSidebarPointer = (state) => state.advert.sidebarPointer;
export const selectHeaderPointer = (state) => state.advert.headerPointer;
export const selectHeaderAdvert = (state) => state.advert.currentHeaderAdvert;
export const selectSidebarAdverts = (state) => state.advert.currentSidebarAdverts;

export default advertSlice.reducer;
