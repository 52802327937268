import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CookieConsent from "react-cookie-consent";
import { Stack } from "react-bootstrap";
import { Link } from "../components";
import {
  clearViolation,
  selectCurrentUser,
  selectIsSignedIn,
  selectShouldSync,
  selectStatus,
  syncUserPermissions,
  setLastLocationPathname,
} from "../features/auth/authSlice";
import { AUTH_URIS } from "../index";
import "./AppLayout.scss";

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const status = useSelector(selectStatus);
  const currentUser = useSelector(selectCurrentUser);
  const isSignedIn = useSelector(selectIsSignedIn);
  const shouldSync = useSelector(selectShouldSync);
  const dispatch = useDispatch();

  const cleanUpLingeringOtpChallengeData = () => localStorage.removeItem("creds");

  const syncPermissions = (user) => {
    cleanUpLingeringOtpChallengeData();
    dispatch(syncUserPermissions(user));
  };

  useEffect(() => {
    if (!AUTH_URIS.includes(window.location.pathname)) {
      dispatch(setLastLocationPathname({ locationPathname: window.location.pathname }));
    }
  }, [window.location]);

  useEffect(() => {
    if (status === "authentication-violation") {
      navigate("/signin");
    }

    if (status === "permission-violation") {
      navigate("/");
    }

    if (status === "resource-not-found") {
      navigate("/not-found");
    }

    if (status === "server-api-error") {
      navigate("/server-api-error");
    }

    return () => dispatch(clearViolation());
  }, [status]);

  useEffect(() => {
    if (shouldSync && isSignedIn) {
      syncPermissions(currentUser);
    }
  }, [shouldSync]);

  return (
    <>
      {children}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieconsent_status"
        style={{ background: "#2B373B" }}
        buttonStyle={{ background: "#eee", fontSize: "13px", borderRadius: "0.25rem" }}
      >
        <Stack gap={3} direction="horizontal">
          This website uses cookies to ensure you get the best experience on our website.
          <Link to="/our-cookies" style={{ color: "#fff" }}>Learn more</Link>
        </Stack>
      </CookieConsent>
    </>
  );
};

export default AppLayout;
