import React from "react";
import { Link } from "../../components";

const BondSpaceLink = ({ id: customerId, bondCount }) => {
  if (!bondCount) {
    return (<p>There&apos;s presently no devices in your portal.</p>);
  }

  return (
    <p>There&apos;s presently {bondCount} devices in <Link to={`/customers/${customerId}/spaces`}>your portal</Link>.</p>
  );
};

export default BondSpaceLink;
