import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mapKeys } from "lodash";
import { addMessage } from "../systemMessage/systemMessageSlice";

const initialState = {
  isLoading: false,
  isPolling: false,
  pollingUpdatedAt: null,
  results: {},
  status: "idle",
  targets: {},
  testInProgress: {},
};

export const fetchTargetBonds = createAsyncThunk(
  "iperf/fetch/target-bonds",
  async ({ customerId, pageNumber, itemsPerPage, filters }, {
    extra: { api },
    rejectWithValue
  }) => {
    try {
      const params = { page: pageNumber, limit: itemsPerPage, ...filters };
      const { data } = await api.get(`/customers/${customerId}/bonds`, { params });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const runIperfTest = createAsyncThunk(
  "iperf/run",
  async ({
    customerId,
    bonderId,
    targetBondId
  }, {
    extra: { api },
    dispatch,
    rejectWithValue
  }) => {
    try {
      const { data } = await api.post(
        `/customers/${customerId}/bonds/${bonderId}/run_iperf?target_bond_id=${targetBondId}`,
      );
      dispatch(addMessage({ message: "iPerf test has been requested", type: "success" }));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchIperfResult = createAsyncThunk(
  "iperf/result/fetch",
  async ({ customerId, bonderId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/bonds/${bonderId}/fetch_iperf_results`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkIperfResult = createAsyncThunk(
  "iperf/result/check",
  async ({ customerId, bonderId, iperfId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/bonds/${bonderId}/check_iperf_result?iperf_id=${iperfId}`, {
        iperfId
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const iperfSlice = createSlice({
  name: "iperf",
  initialState,
  reducers: {
    clearTestInProgress: (state) => {
      state.testInProgress = initialState.testInProgress;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(runIperfTest.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.isPolling = true;
      })
      .addCase(runIperfTest.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
        state.isPolling = false;
      })
      .addCase(runIperfTest.fulfilled, (state, action) => {
        const iperfTest = { ...action.payload };

        state.status = initialState.status;
        state.testInProgress = iperfTest;

        state.isPolling = true;
        state.isLoading = false;
      })
      .addCase(fetchIperfResult.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchIperfResult.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchIperfResult.fulfilled, (state, action) => {
        state.status = initialState.status;
        state.results = action.payload;
        state.isLoading = false;
        state.isPolling = false;

        if (action.payload.inProgress) {
          state.testInProgress = action.payload.inProgress;
        } else {
          state.testInProgress = initialState.testInProgress;
        }
      })
      .addCase(checkIperfResult.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(checkIperfResult.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
        state.testInProgress = {
          ...state.testInProgress,
          status: "error"
        };
      })
      .addCase(checkIperfResult.fulfilled, (state, action) => {
        const { status, timestamp: time } = action.payload;
        state.isLoading = false;

        if (status === "complete") {
          state.status = "ready";
          state.testInProgress = initialState.testInProgress;
          state.isPolling = false;
        }

        if (status === "in_progress") {
          state.pollingUpdatedAt = new Date().toISOString();
          state.isPolling = true;
          state.testInProgress = { ...state.testInProgress, time, status };
        }
      })
      .addCase(fetchTargetBonds.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchTargetBonds.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchTargetBonds.fulfilled, (state, action) => {
        const bonds = mapKeys(action.payload.items, ({ id }) => id);
        const { bonderId } = action.meta.arg;

        state.targets = Object
          .values(bonds)
          .filter(({ id, iperfable }) => (id !== bonderId && iperfable));
        state.status = "idle";
        state.isLoading = false;
      });
  },
});

export const { clearTestInProgress } = iperfSlice.actions;

export const selectLastPollingUpdate = (state) => state.iperf.pollingUpdatedAt;
export const selectIperfResults = (state) => state.iperf.results;
export const selectIsPolling = (state) => state.iperf.isPolling;
export const selectTargetBonds = (state) => state.iperf.targets;
export const selectTestInProgress = (state) => state.iperf.testInProgress;
export const selectStatus = (state) => state.iperf.status;

export default iperfSlice.reducer;
