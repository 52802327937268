import React from "react";
import { Col } from "react-bootstrap";
import Input from "../inputs/Input";

const SimFields = ({ ...props }) => (
  <>
    <Col md={3}>
      <Input {...props} label="MSISDN" name="msisdn" />
    </Col>
    <Col md={3}>
      <Input {...props} label="ICCID" name="iccid" />
    </Col>
    <Col md={3}>
      <Input {...props} label="IMSI" name="imsi" />
    </Col>
    <Col md={3}>
      <Input {...props} label="Device" name="device" />
    </Col>
  </>
);

export default SimFields;
