const reduceByStatus = (prev, current, status) => {
  if (!current) {
    return prev;
  }

  const { status: testStatus } = current;
  if (testStatus !== status) {
    return prev;
  }

  return prev.concat(current);
};

export const toMostRecentlyQueued = (prev, current) => reduceByStatus(prev, current, "queued");

export const toCompleted = (prev, current) => reduceByStatus(prev, current, "completed");

export const toLastCompleted = (prev, current) => {
  if (!current) {
    return prev;
  }

  const { status, testRef } = current;

  if (testRef < prev.testRef) {
    return prev;
  }

  if (status !== "completed") {
    return prev;
  }

  return prev.concat(current);
};

export default null;
