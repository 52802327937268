import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import UserForm from "./UserForm";
import { selectCustomerId } from "../customer/customerSlice";
import {
  createUser,
  selectStatus,
  selectUser,
} from "./userSlice";

const Header = () => <>Create User</>;

const UserCreate = () => {
  const status = useSelector(selectStatus);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCustomerId = useSelector(selectCustomerId);

  useEffect(() => {
    if (status === "created") {
      navigate(`/users/${user.id}`);
    }
  }, [status]);

  const handleSubmit = (newUser) => {
    dispatch(createUser({ user: newUser }));
  };

  return (
    <RequireAuth can="create user">
      <HeaderLayout>
        <BaseView
          headerSlot={Header}
        >
          <UserForm
            onSubmit={handleSubmit}
            showPassword
            defaults={{ customerId: selectedCustomerId }}
          />
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default UserCreate;
