/* eslint-disable react/prop-types */
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PropertyList.scss";

export const Pl = ({ children }) => (
  <Row as="dl">{children}</Row>
);

export const Pt = ({ children }) => (
  <Col as="dt" sm={4} className="text-end">{children}</Col>
);

export const Pd = ({ children, className }) => (
  <Col as="dd" className={className} sm={8}>{children}</Col>
);
