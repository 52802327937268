/* Ignoring eslint rules to preserve HTML structure and convenience */
/* eslint-disable max-len */
import React from "react";
import { Col, Row } from "react-bootstrap";
import HeaderLayout from "../layouts/header";
import { Link } from "../components";

const CookiePolicy = () => (
  <HeaderLayout>
    <Row>
      <Col className="col-10">
        <h1>Cookie Policy</h1>

        <h2>About our Cookies - Supporting your Informed Choice</h2>
        <p>Comms365 respects privacy and will protect your privacy to its fullest extent. There is nothing within any of our websites or portals designed to harvest personal data. The use of our website is for information only and offers you the chance to contact us via the use of forms or email. By using these forms and email addresses to communicate with us, you are requesting that we contact you back to discuss our products and services.</p>
        <p>Cookies are small text files stored on your device when you access most websites on the internet.</p>
        <p>Currently across all Comms365&apos;s websites and customer portals we make use of cookies to help manage our websites, make our websites easier to use and support the provision of relevant information and functionality to you to improve your visitor experience.  In addition it provides us with information about how our websites are used so that we can make sure it is as up-to-date, relevant and as error free as we can.</p>
        <p>By using our website, you agree to the use of cookies as described in this Cookie Policy.</p>
        <p>Cookies across our website, set by our 3rd party partners or us, can be in the form of session cookies (lasting only as long as your visit to our website) or persistent cookies, and may use different technologies, such as JavaScript or Flash.</p>
        <p>You can choose to manage the cookies we use on our website through your internet browser settings at any time. For more information about how to do this, and about cookies in general, you can visit <Link to="http://www.allaboutcookies.org" target="_blank">http://www.allaboutcookies.org.</Link> Certain cookies may be set as soon as you visit our website, but you can remove them using your internet browser settings.</p>
        <p>Please note that preventing cookies is likely to impact the functionality or performance of our website, or prevent you from using certain services provided through them. If you choose to do so, we cannot guarantee access to the services provided through our website or be sure how our website will perform during your visit. Doing so will also affect our ability to update our website to cater for user preferences and improve its performance.</p>
        <p>If you have any further questions, would like any further clarification around our use of cookies or wish to discuss how we are embracing online privacy, please email us at <a href="mailto:compliance@comms365.com">compliance@comms365.com</a>.</p>

        <h2>Further Information</h2>
        <p>Further general information about cookies is available at <Link to="http://www.allaboutcookies.org">http://www.allaboutcookies.org</Link>.</p>
        <p>For further information about Google Analytics please see <Link to="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</Link></p>
        <p>To prevent tracking by Google Analytics across all websites visit <Link to="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</Link>.</p>

        <h2>What Are Cookies?</h2>
        <p>Cookies are tiny files that we place into a folder on your computer when you visit our various websites and customer portals.  They allow us to customise and performance enhance your browsing experience.</p>
        <p>We set out below information to assist your understanding of the cookies that we use here at Comms365.</p>
        <h3>Cookie Information</h3>
        <p>There are two types of cookies used by Comms365 which vary by the length of time they remain active:</p>
        <h3><i>Session Cookies</i></h3>
        <p>Set by a site, which are deleted when the visitor closes their browser.</p>
        <h3><i>Persistent Cookies</i></h3>
        <p>Set by a site, which are stored on the visitor&apos;s device and expire on a given date.</p>

        <h2>Your Visit</h2>
        <p>These cookies are used to ensure that your journey through our website is smooth. They will typically remember what settings you have applied or any preferences that you have set so that they are not forgotten when you click through to a different screen.</p>

        <h2>Remarketing</h2>
        <p>Comms365 uses limited 3rd party remarketing services including Google AdWords and Google Analytics. We respect your privacy and will not collect any identifiable information through the use of Google or any other 3rd party remarketing service. To opt out of Google&apos;s use of cookies visit <Link to="https://support.google.com/ads/answer/2662922?hl=en-GB">https://support.google.com/ads/answer/2662922?hl=en-GB</Link>.</p>

        <h2>User Preferences</h2>
        <p>These cookies are used to remember your individual preferences. On our websites, including the webshop this may include a variety of things such as the enabling of automatic form completion to displaying prices Inc or ex VAT, what you have selected before or to enable us to make product recommendations.</p>

        <h2>Analytics</h2>
        <p>The software which uses Analytics cookies is intended to provide Comms365 with an overview of traffic trends throughout our sites, including the webshop, using such analytics software - Google / HotJar. These trends may include information such as your chosen browser, whether you are accessing using a smartphone or laptop, participation in marketing/email campaigns, page views, session length, etc. No personal or identifiable information is collected or used.</p>

        <h2>How can I check if cookies are enabled?</h2>
        <p>Please be aware that if you disable cookies it will not be possible to use most if not all of our websites. To check the current status of cookies please click on your browser from the list below for step-by-step instructions:</p>

        <h3><i>Internet Explorer</i></h3>
        <p>Under the &quot;Settings&quot; tab of your browser, select &quot;Privacy &amp; Security&quot; and under the Cookies heading you will be to enable or disable cookies.</p>

        <h3><i>Google Chrome</i></h3>
        <p>Click on the menu icon near the top-right of your browser window and select &apos;Settings&apos;.</p>
        <p>On the &apos;Settings&apos; screen, click &apos;Advanced Settings...&apos;.</p>
        <p>Now click the &apos;Content settings...&apos; button. This will display your cookies settings. The first option &apos;Allow sites to save and read cookie data (recommended)&apos; should be selected.</p>

        <h3><i>Safari</i></h3>
        <p>Click on &apos;Safari&apos; at the top of your browser window and select the &apos;Preferences&apos; option</p>
        <p>Click on &apos;Security&apos;, then &apos;Accept cookies&apos; and select &apos;Always&apos;</p>

        <h3><i>Firefox</i></h3>
        <p>Click on the menu icon near the top-right of your browser window and select &apos;Options&apos;.</p>
        <p>Select &apos;Privacy&apos;, and in the &apos;History&apos; section the drop-down menu labelled &apos;Firefox will:&apos; select &apos;Remember History&apos; should be selected.</p>

        <h3><i>Other</i></h3>
        <p>There are a number of other browsers available that will all have their own unique way of setting cookies, for assistance with these individual browsers please refer to their help section.</p>

        <h2>Can I enable cookies on Comms365 websites but not on other sites?</h2>
        <p>It&apos;s possible to only accept Cookies when you are on Comms365 websites and portals by adding our various web addresses to a &apos;safe list&apos; Here&apos;s how to do this in different browsers:</p>

        <h3><i>Internet Explorer</i></h3>

        <p>Click on &apos;Tools&apos; at the top of your browser window and select &apos;Internet options&apos;, then click on the &apos;Privacy&apos; tab.</p>
        <p>Under the &apos;Settings&apos; menu slide the bar to the top, so that &apos;Block All Cookies&apos; is selected.</p>
        <p>Click the &apos;Sites&apos; button and enter www.comms365.com (for example) in the &apos;Address of website&apos; bar, then select &apos;Allow&apos;.</p>

        <h3><i>Google Chrome</i></h3>

        <p>Click on the menu icon near the top-right of your browser window and select &apos;Settings&apos;.</p>
        <p>On the &apos;Settings&apos; screen, click &apos;Show advanced settings…&apos;.</p>
        <p>Now select &apos;Block sites from setting any data&apos; then select the &apos;Manage exceptions&apos; button.</p>
        <p>In the &apos;Hostname Pattern&apos; field type www.comms365.com (for example) and ensure that the &apos;Behaviour&apos; is set to &apos;Allow&apos;.</p>

        <h3><i>Safari</i></h3>

        <p>Click on &apos;Safari&apos; at the top of your browser window and select the &apos;Preferences&apos; option.</p>
        <p>Click on &apos;Security&apos;, then &apos;Accept cookies&apos; and select &apos;Only from site you navigate to&apos;.</p>

        <h3><i>Firefox</i></h3>
        <p>Click on the menu icon near the top-right of your browser window and select &apos;Options&apos;.</p>
        <p>In the first drop down menu labelled &apos;Firefox will:&apos; select &apos;Use custom settings for history&apos;.</p>
        <p>Click the &apos;Exceptions…&apos; button</p>
        <p>In the &apos;Address of web site&apos; field type www.comms365.com (for example) and click &apos;Allow&apos;.</p>

        <h3><i>Other</i></h3>
        <p>There are a number of other browsers available that will all have their own unique way of setting cookie exceptions, for assistance with these individual browsers please refer to their help section.</p>

        <h2>Further Questions</h2>
        <p>If you have any questions or concerns about any of our cookies or policies, please feel free to email us at <a href="mailto:compliance@comms365.com">compliance@comms365.com</a>.</p>
      </Col>
    </Row>
  </HeaderLayout>
);

export default CookiePolicy;
