import {
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { addMessage } from "../systemMessage/systemMessageSlice";
import isRefreshIntervalExpired from "../../lib/isRefreshIntervalExpired";
import config from "../../config";
import mapEntityGroupDataToSelectOptions from "../../lib/maps";

const { userSyncDelayInSeconds } = config;

const localUser = JSON.parse(localStorage.getItem("currentUser"));

const unauthenticatedSchema = { direct: false, userId: null, customerId: null };

const initialState = {
  currentUser: { ...unauthenticatedSchema, ...localUser },
  isLoading: false,
  status: "initial",
  lastLocationPathname: null,
  synchronisedAt: 0,
  entityGroups: {
    items: []
  },
};

export const signIn = createAsyncThunk(
  "auth/signin",
  async ({ username, password, rememberMe }, { extra: { api }, rejectWithValue }) => {
    const userPayload = { username, password, rememberMe };

    try {
      const { data } = await api.post("/users/sign_in", {
        user: userPayload,
      });
      return { ...data, username, password, label: data.firstName };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  "auth/password/forgotten",
  async ({ username }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      await api.post("/users/password", {
        user: { username }
      });

      const message = {
        type: "info",
        message: "If we can find your account, you'll shortly receive an email containing password reset instructions",
      };

      dispatch(addMessage(message));
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/password/reset",
  async (params, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      await api.put("/users/password", { user: { ...params } });

      dispatch(addMessage({ message: "Your password has been updated", type: "success" }));
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const unlockAccount = createAsyncThunk(
  "auth/account/unlock",
  async ({ unlockToken }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      await api.get(`/users/unlock?unlock_token=${unlockToken}`);

      dispatch(addMessage({ message: "Your account has been unlocked", type: "success" }));
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const submitOneTimePassword = createAsyncThunk(
  "auth/one-time-password/submit",
  async ({ totp }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const creds = JSON.parse(localStorage.getItem("creds"));
      if (!creds) {
        throw new Error("Unable to resume mulifactor authentication from expired session");
      }

      const { data } = await api.post("/users/sign_in", {
        user: {
          otp_attempt: totp,
          username: creds.username,
          password: creds.password,
          rememberMe: creds.rememberMe,
        }
      });

      if (data.error) {
        throw new Error(data.error);
      } else {
        dispatch(addMessage({ message: "Login successful", type: "success" }));
      }

      return { ...data, label: data.firstName };
    } catch (e) {
      if (e.response) {
        const { error } = e.response.data;
        dispatch(addMessage({ message: error, type: "danger" }));
      }

      if (!e.response) {
        dispatch(addMessage({ message: e.message, type: "danger" }));
      }

      return rejectWithValue(e);
    }
  }
);

export const reissueOneTimePassword = createAsyncThunk(
  "auth/one-time-password/reissue",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // TODO await API request to request a fresh TOTP once API endpoint is available

      dispatch(addMessage({ message: "A new code has been sent to your email", type: "info" }));
      return true;
    } catch (error) {
      dispatch(addMessage({ message: "Unable to restart MFA process", type: "danger" }));

      return rejectWithValue(error);
    }
  }
);

export const syncUserPermissions = createAsyncThunk(
  "auth/reconcile-user-permissions",
  async ({ id }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/users/${id}`, {
        headers: { "X-Request-type": "permissions-sync" },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchEntityGroups = createAsyncThunk(
  "auth/entity-groups/fetch",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get("/entity_groups");
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signOut: (state) => {
      localStorage.removeItem("currentUser");
      state.currentUser = unauthenticatedSchema;
      state.status = "signed-out";
    },
    permissionViolation: (state) => {
      state.status = "permission-violation";
      state.isLoading = false;
    },
    authenticationViolation: (state) => {
      state.status = "authentication-violation";
      localStorage.removeItem("currentUser");
      state.currentUser = unauthenticatedSchema;
    },
    setLastLocationPathname: (_, action) => {
      localStorage.setItem("lastLocationPathname", action.payload.locationPathname);
    },
    clearLastLocationPathname: () => {
      localStorage.setItem("lastLocationPathname", null);
    },
    resourceNotFound: (state) => {
      state.status = "resource-not-found";
    },
    serverApiError: (state) => {
      state.status = "server-api-error";
    },
    clearViolation: (state) => {
      state.status = initialState.status;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state, action) => {
        state.status = "loading";
        state.isLoading = true;

        const { username, password, rememberMe } = action.meta.arg;
        localStorage.setItem("creds", JSON.stringify({ username, password, rememberMe }));
      })
      .addCase(signIn.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.currentUser = { mfaRequired: false, ...action.payload };
        state.isLoading = false;
        state.synchronisedAt = new Date().valueOf();
        if (state.currentUser.mfaRequired) {
          state.status = "mfa-required";
        } else {
          state.status = "signed-in";
          localStorage.setItem("currentUser", JSON.stringify(action.payload));
        }
      })
      .addCase(requestPasswordReset.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(requestPasswordReset.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(requestPasswordReset.fulfilled, (state) => {
        state.status = "reset";
        state.isLoading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = "reset";
        state.isLoading = false;
      })
      .addCase(unlockAccount.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(unlockAccount.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(unlockAccount.fulfilled, (state) => {
        state.status = "unlocked";
        state.isLoading = false;
      })
      .addCase(submitOneTimePassword.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(submitOneTimePassword.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(submitOneTimePassword.fulfilled, (state, action) => {
        state.status = "totp-accepted";
        state.isLoading = false;
        state.synchronisedAt = new Date().valueOf();
        state.currentUser = { ...action.payload, mfaRequired: false };
        localStorage.removeItem("creds");
        localStorage.setItem("currentUser", JSON.stringify(action.payload));
      })
      .addCase(reissueOneTimePassword.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(reissueOneTimePassword.rejected, (state, action) => {
        state.status = "error";
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(reissueOneTimePassword.fulfilled, (state) => {
        state.status = "totp-reissued";
        state.isLoading = false;
      })
      .addCase(syncUserPermissions.rejected, (state, action) => {
        state.error = action.payload;
        state.currentUser = unauthenticatedSchema;
        localStorage.removeItem("currentUser");
      })
      .addCase(syncUserPermissions.fulfilled, (state, action) => {
        state.synchronisedAt = new Date().valueOf();
        const { payload } = action;
        const { currentUser: { apiToken, isStaff, label } } = state;
        state.currentUser = { apiToken, isStaff, label, ...payload };

        localStorage.setItem("currentUser", JSON.stringify({
          apiToken,
          isStaff,
          label,
          ...payload
        }));
      })
      .addCase(fetchEntityGroups.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(fetchEntityGroups.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchEntityGroups.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.entityGroups = action.payload;
        state.isLoading = false;
      });
  },
});

export const {
  authenticationViolation,
  clearLastLocationPathname,
  clearViolation,
  permissionViolation,
  resourceNotFound,
  serverApiError,
  setLastLocationPathname,
  signOut,
} = authSlice.actions;

export const selectCurrentUser = (state) => state.auth.currentUser;
export const selectIsDirect = (state) => state.auth.currentUser.direct;
export const selectIsLoading = (state) => state.auth.isLoading;
export const selectIsSignedIn = (state) => !isEqual(state.auth.currentUser, unauthenticatedSchema);
export const selectIsStaff = (state) => state.auth.currentUser.isStaff;
export const selectStatus = (state) => state.auth.status;
export const selectLastLocationPathname = () => localStorage.getItem("lastLocationPathname");
export const selectShouldSync = (state) => isRefreshIntervalExpired(
  userSyncDelayInSeconds,
  state.auth.synchronisedAt
);
export const selectEntityGroups = (state) => mapEntityGroupDataToSelectOptions(
  state.auth.entityGroups.items
);

export default authSlice.reducer;
