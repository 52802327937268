import React from "react";
import { useSelector } from "react-redux";
import { Container, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { selectIsStaff } from "../auth/authSlice";
import { Button } from "../../components";
import BaseForm from "../../forms/baseForm";
import Input from "../../forms/inputs/Input";
import Select from "../../forms/inputs/Select";
import Checkbox from "../../forms/inputs/Checkbox";
import SelectCustomer from "../../forms/inputs/SelectCustomer";
import { selectIsLoading } from "./customerSlice";
import { selectFormErrors } from "../systemMessage/systemMessageSlice";
import Can from "../../components/Can";

const CustomerForm = ({ onSubmit, defaults }) => {
  const errors = useSelector(selectFormErrors);
  const isStaff = useSelector(selectIsStaff);
  const isLoading = useSelector(selectIsLoading);

  const navigate = useNavigate();
  const handleFormSubmit = (e) => {
    if (onSubmit) onSubmit(e);
  };

  const categoryOptions = [
    { value: "direct", label: "Direct" },
    { value: "reseller", label: "Reseller" },
  ];

  const bondServerOptions = [
    { value: "multiman", label: "c365-1" },
    { value: "daisy", label: "ibm-1" },
  ];

  return (
    <BaseForm onSubmit={handleFormSubmit} defaults={defaults} errors={errors}>
      <Container>
        {isLoading && (
          <Row>
            <Col>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            </Col>
          </Row>
        )}
        <Row className="pb-3">
          <Col sm>
            <Form.Group className="mb-3">
              <SelectCustomer label="Parent" name="customerId" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="Name" name="name" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="Contact Email" name="email" type="email" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Select label="Category" name="category" options={categoryOptions} />
            </Form.Group>
            {isStaff && (
              <>
                <Form.Group className="mb-3">
                  <Input label="Bond space reference" name="bondSpaceRef" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Select label="Bond Management Server" name="bondServer" options={bondServerOptions} />
                </Form.Group>
              </>
            )}
          </Col>
          <Col sm>
            <Form.Group className="mb-3">
              <Input label="Address Line 1" name="address1" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="Address Line 2" name="address2" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="City" name="city" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="Post Code" name="zip" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="Phone Number" name="phone" type="text" />
            </Form.Group>
            {isStaff && (
              <Row className="mt-3">
                <Col>
                  <Stack direction="horizontal" gap={3}>
                    <Checkbox className="float-start" name="masterSimAgreement" label="Master SIM Agreement" />
                    <Checkbox className="float-left" name="masterServiceAgreement" label="Master Service Agreement" />
                    <Can admin="customer">
                      <Checkbox className="float-left" name="hasOwnRadius" label="has own radius?" />
                      <Checkbox name="internal" label="Is internal?" />
                    </Can>
                  </Stack>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Stack direction="horizontal" gap={2} className="pt-3">
              <Button type="submit" variant="primary" label="Submit" isLoading={isLoading} />
              <Button type="button" onClick={() => navigate(-1)} variant="secondary" label="Cancel" />
            </Stack>
          </Col>
        </Row>
      </Container>
    </BaseForm>
  );
};

export default CustomerForm;
