import { authenticationViolation, permissionViolation, resourceNotFound, serverApiError, signIn, signOut, submitOneTimePassword } from "../features/auth/authSlice";
import { addMessage } from "../features/systemMessage/systemMessageSlice";

const apiErrorHandler = (store) => (next) => (action) => {
  const { type } = action;

  if (type.split("/").pop() === "rejected") {
    const errorMessageTemplate = { type: "danger" };
    const { error, errors, message, responseHttpCode } = {
      error: undefined,
      errors: [],
      ...action.payload,
      ...action.payload.data
    };
    const apiErrorMessage = {
      ...errorMessageTemplate,
      message: (message) || error,
      commsPortalApiErrors: errors
    };

    if (responseHttpCode === 401) {
      if ([signIn.rejected.type, submitOneTimePassword.rejected.type].includes(type)) {
        store.dispatch(addMessage(apiErrorMessage));
      } else {
        store.dispatch(
          addMessage({ ...errorMessageTemplate, message: "Login expired" })
        );
      }

      store.dispatch(authenticationViolation());
      store.dispatch(signOut());
      return next(action);
    }

    if (responseHttpCode === 403) {
      apiErrorMessage.message = "You're not authorised to do that action";
      store.dispatch(addMessage(apiErrorMessage));
      store.dispatch(permissionViolation());
      return next(action);
    }

    if (responseHttpCode === 404) {
      store.dispatch(resourceNotFound());
      return next(action);
    }

    if (responseHttpCode === 500) {
      store.dispatch(serverApiError());
      return next(action);
    }

    store.dispatch(addMessage(apiErrorMessage));
  }

  return next(action);
};

export default apiErrorHandler;
