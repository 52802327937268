import React from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import store from "./store";
import { selectIsDirect } from "./features/auth/authSlice";
import BonderShow from "./features/bonder/BonderShow";
import {
  CookiePolicy,
  ForgottenPassword,
  OneTimePassword,
  PageNotFound,
  PrivacyPolicy,
  PrivateHome,
  PublicCookiePolicy,
  ResetPassword,
  AcceptInvitation,
  ServerApiError,
  Signin,
  Spaces,
  UnlockAccount,
} from "./pages";
import {
  UserCreate,
  UserEdit,
  UserIndex,
  UserShow,
} from "./features/user";
import {
  CustomerCreate,
  CustomerEdit,
  CustomerIndex,
  CustomerShow,
} from "./features/customer";
import {
  ConnectionIndex,
  ConnectionShow,
  ConnectionCreate,
  ConnectionEdit,
  ConnectionMove,
} from "./features/connection";
import {
  IpRangeCreate,
  IpRangeIndex,
} from "./features/ipRange";
import PackageShow from "./features/package/PackageShow";
import "bootstrap/dist/js/bootstrap.bundle";

export const SIGNIN_URI = "/signin";
export const ONE_TIME_PASSWORD_URI = "/one-time-password";
export const RESET_PASSWORD = "/reset-password";
export const ACCEPT_INVITATION = "/users/:userId/accept_invitation";
export const FORGOTTEN_PASSWORD = "/forgotten-password";
export const AUTH_URIS = [SIGNIN_URI, ONE_TIME_PASSWORD_URI, RESET_PASSWORD, FORGOTTEN_PASSWORD];

const Main = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<PrivateHome />} />
      <Route path={SIGNIN_URI} element={<Signin />} />
      {useSelector(selectIsDirect) && (
        <>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
        </>
      )}
      <Route path="/our-cookies" element={<PublicCookiePolicy />} />
      <Route path="/server-api-error" element={<ServerApiError />} />
      { /* user password manage routes */ }
      <Route path={FORGOTTEN_PASSWORD} element={<ForgottenPassword />} />
      <Route path={RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ACCEPT_INVITATION} element={<AcceptInvitation />} />
      <Route path={ONE_TIME_PASSWORD_URI} element={<OneTimePassword />} />
      { /* user routes */ }
      <Route path="/users" element={<UserIndex />} />
      <Route path="/users/create" element={<UserCreate />} />
      <Route path="/users/:userId" element={<UserShow />} />
      <Route path="/users/:userId/edit" element={<UserEdit />} />
      <Route path="/users/unlock" element={<UnlockAccount />} />
      { /* customer routes */ }
      <Route path="/customers" element={<CustomerIndex />} />
      <Route path="/customers/create" element={<CustomerCreate />} />
      <Route path="/customers/:customerId" element={<CustomerShow />}>
        <Route path="ip-ranges" element={<IpRangeIndex />} />
        <Route path="ip-ranges/create" element={<IpRangeCreate />} />
      </Route>
      <Route path="/customers/:customerId/edit" element={<CustomerEdit />} />
      <Route path="/customers/:customerId/spaces" element={<Spaces />} />
      <Route path="/customers/:customerId/bonders/:bonderId" element={<BonderShow />} />
      <Route path="/customers/:customerId/bonders/:bonderId/:tabId" element={<BonderShow />} />
      { /* connection routes */ }
      <Route path="/connections" element={<ConnectionIndex />} />
      <Route path="/connections/create" element={<ConnectionCreate />} />
      <Route path="/connections/:connectionId" element={<ConnectionShow />} />
      <Route path="/connections/:connectionId/edit" element={<ConnectionEdit />} />
      <Route path="/connections/:connectionId/move" element={<ConnectionMove />} />
      <Route path="*" element={<PageNotFound />} />
      { /* package routes */ }
      <Route path="/packages/:packageId" element={<PackageShow />} />
    </Routes>
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
export default Main;
