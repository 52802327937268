import React, { Fragment } from "react";
import _ from "lodash";
import humanize from "string-humanize";
import { Pt, Pd } from "../PropertyList/PropertyList";
import PropertyValue from "../PropertyValue/PropertyValue";
import PropertyEditor from "../PropertyEditor/PropertyEditor";
import ShowBooleanAsIcon from "../ShowBooleanAsIcon/ShowBooleanAsIcon";

const MetaNode = ({ metaKey, value, entityId, onSubmit }) => (
  <Fragment key={`meta-${metaKey}`}>
    <Pt>{humanize(metaKey)}</Pt>
    <Pd>
      {_.isString(value) && (
        <PropertyEditor
          propName={metaKey}
          entityId={entityId}
          propValue={value}
          onSubmit={onSubmit}
        />
      )}
      {_.isNumber(value) && <PropertyValue value={value} />}
      {_.isBoolean(value) && <ShowBooleanAsIcon isTruthy={value} />}
    </Pd>
  </Fragment>
);

const Meta = ({ entries, entityId, onMetaEdit }) => {
  const metaData = Object.entries(entries);
  return metaData.map(([metaKey, meta]) => {
    const { output, key } = meta;

    return (
      <MetaNode
        key={`meta-key-${metaKey}`}
        metaKey={key}
        value={output}
        entityId={entityId}
        onSubmit={onMetaEdit}
      />
    );
  });
};

export default Meta;
