import React from "react";
import humanize from "string-humanize";
import config from "../../config";

const BondStatesGlossary = () => {
  const { bondStates } = config;
  const statusIndicators = bondStates
    .sort((a, b) => ((a.displayOrder > b.displayOrder) ? 1 : -1))
    .map(
      ({ title, hex }) => (
        <span key={title}>
          <i className="bi-circle-fill" style={{ color: `#${hex}` }} />
          {humanize(title)}{" "}
        </span>
      )
    );

  return <div>{statusIndicators}</div>;
};

export default BondStatesGlossary;
