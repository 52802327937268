import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import Select from "./Select";
import {
  queryCustomers,
  selectCustomerId,
  selectCustomerName,
  selectQueries,
  updateSelectedCustomer,
} from "../../features/customer/customerSlice";
import config from "../../config";

const SelectCustomer = ({ name, ...props }) => {
  const customerQueries = useSelector(selectQueries);
  const { delayDispatchBySeconds } = config;

  const usersCustomerId = useSelector(selectCustomerId);
  const usersCustomerName = useSelector(selectCustomerName);

  const dispatch = useDispatch();
  const key = () => `${name}-select-query`;
  const customers = () => (customerQueries[key()] || {}).result || [];

  const fetchCustomers = (customerName) => {
    const query = { name_like: customerName };
    if (customerName) {
      dispatch(queryCustomers({ key: key(), query, page: 1 }));
    }
  };

  const delayedCustomerFetch = useRef(
    debounce((value) => fetchCustomers(value), delayDispatchBySeconds * 1000)
  ).current;

  const handleCustomerChange = (inputValue) => delayedCustomerFetch(inputValue);
  const handleOptionSelect = (selectedOption) => dispatch(updateSelectedCustomer(selectedOption));

  const customerOptions = () => [{
    value: usersCustomerId,
    label: usersCustomerName
  }].concat(customers().map(
    (cust) => ({ value: cust.id, label: cust.name }),
  ));

  return (
    <Select
      options={customerOptions()}
      onInputChange={handleCustomerChange}
      onOptionSelect={handleOptionSelect}
      name={name}
      value={{ value: usersCustomerId, label: usersCustomerName }}
      id="select-customer"
      {...props}
    />
  );
};

export default SelectCustomer;
