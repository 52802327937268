import React from "react";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { useTable } from "react-table";

const ResultsTable = ({ data, columns, ...props }) => {
  const { prepareRow, rows, columns: tableColumns } = useTable({ columns, data });

  const { isLoading } = props;
  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <div className="pb-3">
      <Table striped hover bordered>
        <thead>
          <tr>
            { tableColumns.map((column) => (
              <th key={column.id} className="d-flex-inline user-select-none">
                { column.header }
              </th>
            )) }
          </tr>
        </thead>
        <tbody>
          { rows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.id}>
                {row.cells.map((cell) => <td key={cell.column.id}>{cell.render("Cell")}</td>)}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ResultsTable;
