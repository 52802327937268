import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import RequireAuth from "../../lib/RequireAuth";
import IpRangeForm from "./IpRangeForm";
import {
  createIpRange,
  selectIpRanges,
  selectMessage,
  selectStatus,
  validateIpRange,
} from "./ipRangeSlice";
import { Button } from "../../components";

const IpRangeCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { validatedIpaddress } = useSelector(selectIpRanges);
  const message = useSelector(selectMessage);
  const status = useSelector(selectStatus);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { customerId } = useParams();

  useEffect(() => {
    if (status === "created") {
      navigate(`/customers/${customerId}/ip-ranges/`);
    }

    if (status === "validated") {
      setShowConfirmation(true);
    }
  }, [status]);

  const handleSubmit = (ipaddress) => {
    setShowConfirmation(false);
    dispatch(validateIpRange({ ipaddress }));
  };

  const confirmSubmit = () => {
    dispatch(createIpRange({ ipaddress: validatedIpaddress }));
  };

  return (
    <RequireAuth can="create ipaddress">
      <h2>Create IP Range</h2>

      <Alert show={showConfirmation} variant="info">
        <Alert.Heading>Confirmation</Alert.Heading>
        <p>{message}</p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button className="mx-2" onButtonClick={() => setShowConfirmation(false)} label="Cancel" variant="secondary" />
          <Button onButtonClick={confirmSubmit} label="Proceed" variant="info" />
        </div>
      </Alert>

      <IpRangeForm onSubmit={handleSubmit} />
    </RequireAuth>
  );
};

export default IpRangeCreate;
