import * as am4core from "@amcharts/amcharts4/core";
import React, { useEffect } from "react";
import { Carousel, Figure } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import LatencyResult from "./charts/LatencyResult";
import ThroughputResult from "./charts/ThroughputResult";
import MasterCpuUsage from "./charts/MasterCpuUsage";
import SlaveCpuUsage from "./charts/SlaveCpuUsage";
import {
  fetchSpeedTestResults,
} from "../bonder/bonderSlice";

const SpeedTestResults = ({ speedTest }) => {
  const { bonderId, customerId } = useParams();
  const dispatch = useDispatch();
  const { testRef } = speedTest;

  useEffect(() => {
    dispatch(fetchSpeedTestResults({ bonderId, customerId, testIds: [testRef] }));
  }, []);

  return (
    <Carousel key={`results-${testRef}`} variant="dark" interval={null}>
      <Carousel.Item className="py-3">
        <Figure.Caption className="col-md-10 offset-md-1">
          <h3>Throughput</h3>
          <p><i className="bi-circle-fill" style={{ color: new am4core.ColorSet().next() }} /> Throughput in Mbps</p>
        </Figure.Caption>

        <ThroughputResult testRef={testRef} />
      </Carousel.Item>

      <Carousel.Item className="py-3">
        <Figure.Caption className="col-md-10 offset-md-1">
          <h3>Latency</h3>
          <p><i className="bi-circle-fill" style={{ color: new am4core.ColorSet().next() }} /> Latency in ms</p>
        </Figure.Caption>
        <LatencyResult testRef={testRef} />
      </Carousel.Item>

      <Carousel.Item className="py-3">
        <Figure.Caption className="col-md-10 offset-md-1">
          <h3>Slave CPU Usage</h3>
          <p>How much of the CPU each core is using for the duration of the test.</p>
        </Figure.Caption>
        <SlaveCpuUsage testRef={testRef} />
      </Carousel.Item>

      <Carousel.Item className="py-3">
        <Figure.Caption className="col-md-10 offset-md-1">
          <h3>Master CPU Usage</h3>
          <p>How much of the CPU each core is using for the duration of the test.</p>
        </Figure.Caption>
        <MasterCpuUsage testRef={testRef} />
      </Carousel.Item>

    </Carousel>
  );
};

export default SpeedTestResults;
