import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { clearViolation } from "../features/auth/authSlice";
import HeaderlessLayout from "../layouts/headerless";
import { Link } from "../components";

const ServerApiError = () => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(clearViolation()), []);

  return (
    <HeaderlessLayout>
      <Container id="server-api-error" fluid>
        <Row className="d-flex justify-content-center">
          <Col md="auto">
            <Card>
              <Card.Body>
                <Card.Title>500</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Server API Error</Card.Subtitle>
                <Card.Text>
                  Unable to process this request. You may wish to try the <Link to="/">homepage</Link> instead.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </HeaderlessLayout>
  );
};

export default ServerApiError;
