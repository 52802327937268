import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Form from "react-bootstrap/Form";

import { useError } from "../baseForm";

const SelectWithForm = ({ name, label, options, isCreatable, ...props }) => {
  const { control, formState: { errors } } = useFormContext();
  const [localOptions, setLocalOptions] = useState(options);
  const error = useError(errors, name);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  const maintainOptions = (newVals) => {
    let newOpts = localOptions;
    newVals.filter((val) => ("__isNew__" in val)).forEach((newVal) => {
      if (localOptions.filter((opt) => opt.value === newVal.value).length === 0) {
        newOpts = [...newOpts, newVal];
      }
    });
    if (newOpts.length !== localOptions.length) setLocalOptions(newOpts);
  };

  const handleValueChange = (val) => {
    if (Array.isArray(val)) {
      if (val.filter((v) => ("__isNew__" in v)).length > 0) maintainOptions(val);
      return val.map((item) => item.value);
    }

    const { onOptionSelect } = { onOptionSelect: () => {}, ...props };
    onOptionSelect(val);

    return val.value;
  };

  const customStyles = {
    control: (styles) => {
      const borderColor = error() ? "#dc3545" : styles.borderColor;
      return { ...styles, borderColor };
    },
  };

  const selectValue = (value) => {
    if (value === undefined) return null;
    if (props.isMulti) {
      return value.map((val) => localOptions.find((opt) => opt.value === val));
    }

    return options.find((opt) => opt.value === value);
  };

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <SelectComponent
            isCreatable={isCreatable}
            inputRef={ref}
            onChange={(e) => { onChange(handleValueChange(e)); }}
            placeholder="search..."
            value={selectValue(value)}
            options={localOptions}
            styles={customStyles}
            {...props}
          />
        )}
      />
      { error() && <ErrorMessage error={error()} /> }
    </Form.Group>
  );
};

const ErrorMessage = ({ error }) => <Form.Text className="text-danger">{error.message}</Form.Text>;

const SelectComponent = ({ isCreatable, ...selectProps }) => (
  (isCreatable) ? <CreatableSelect {...selectProps} /> : <Select {...selectProps} />
);

export default SelectWithForm;
