import React, { useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import { debounce } from "lodash";
import BaseForm from "../../forms/baseForm";
import config from "../../config";

const Filter = ({ children, onFilterChange }) => {
  const [filterParams, setFilterParams] = useState({});
  const { delayDispatchBySeconds } = config;

  const handleFormChange = (fields) => {
    Object.keys(fields).forEach((key) => fields[key] === "" && delete fields[key]);
    setFilterParams(fields);
  };

  const delayedFilterChange = useRef(
    debounce((params) => onFilterChange(params), delayDispatchBySeconds * 1000)
  ).current;

  useEffect(() => {
    if (onFilterChange) delayedFilterChange(filterParams);
  }, [filterParams]);

  return (
    <Card className="mb-3" bg="light" text="dark">
      <Card.Header>Filters</Card.Header>
      <Card.Body>
        <BaseForm onChange={handleFormChange}>
          { children }
        </BaseForm>
      </Card.Body>
    </Card>
  );
};

export default Filter;
