import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addMessage } from "../systemMessage/systemMessageSlice";

const initialState = {
  customers: {
    items: [],
  },
  errors: {},
  customer: null,
  availablePlans: {},
  queries: {},
  suggestedCredentials: {},
  packages: {},
  verifiers: {},
  selectedCustomerId: undefined,
  selectedCustomerName: undefined,
};

export const fetchCustomers = createAsyncThunk(
  "customer/fetch/customers",
  async ({ pageNumber, itemsPerPage, filters }, { extra: { api }, rejectWithValue }) => {
    try {
      const params = { page: pageNumber, limit: itemsPerPage, ...filters };
      const { data } = await api.get(`/customers`, { params });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCustomer = createAsyncThunk(
  "customer/fetch/customer",
  async ({ customerId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const queryCustomers = createAsyncThunk(
  "customer/query/customers",
  async ({ key, query, page }, { extra: { api }, rejectWithValue }) => {
    page = page || 1;

    try {
      const result = await api.get("/customers", { params: { page, ...query } });

      return {
        key,
        details: { query, result: result.data.items, pagination: result.data.pagination },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/create",
  async ({ customer }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.post(`/customers`, { customer });
      dispatch(addMessage({ message: `Customer ${customer.name} created`, type: "success" }));

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/delete",
  async ({ customer }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/customers/${customer.id}`);
      dispatch(addMessage({ message: `Customer ${customer.name} deleted`, type: "success" }));

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/update/customer",
  async ({ customer }, { extra: { api }, dispatch, rejectWithValue }) => {
    const { id } = customer;
    delete customer.id;
    try {
      const { data } = await api.patch(`/customers/${id}`, { customer });
      dispatch(addMessage({ message: `Customer ${customer.name} updated`, type: "success" }));

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectCustomerPackage = createAsyncThunk(
  "fetch/customer/package",
  async ({ customerId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/select_package`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectCustomerVerifiers = createAsyncThunk(
  "fetch/customer/verifiers",
  async ({ customerId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/verifiable_users`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAvailablePlans = createAsyncThunk(
  "plan/fetch/available",
  async ({ customerId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/plans`);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSuggestedCredentials = createAsyncThunk(
  "suggested-credentials/fetch",
  async ({ customerId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/customers/${customerId}/credentials`);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    updateSelectedCustomer: (state, action) => {
      const { value, label } = action.payload;

      state.selectedCustomerId = value;
      state.selectedCustomerName = label;
    },
    clearCustomer: (state) => {
      state.customer = null;
    },
    clearCustomerQueries: (state) => {
      state.queries = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customers = action.payload;
        state.status = "idle";
        state.isLoading = false;
      })
      .addCase(fetchCustomer.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchCustomer.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
        state.status = "idle";
        state.isLoading = false;
      })
      .addCase(queryCustomers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(queryCustomers.rejected, (state) => {
        state.status = "error";
      })
      .addCase(queryCustomers.fulfilled, (state, action) => {
        state.status = "idle";
        state.queries = { ...state.queries, [action.payload.key]: action.payload.details };
      })
      .addCase(createCustomer.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(createCustomer.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
        state.status = "created";
        state.isLoading = false;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
        state.status = "updated";
        state.isLoading = false;
      })
      .addCase(selectCustomerVerifiers.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(selectCustomerVerifiers.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(selectCustomerVerifiers.fulfilled, (state, action) => {
        state.verifiers = action.payload;
        state.status = "verifiers fetched";
        state.isLoading = false;
      })
      .addCase(selectCustomerPackage.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(selectCustomerPackage.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(selectCustomerPackage.fulfilled, (state, action) => {
        state.packages = action.payload;
        state.status = "packages fetched";
        state.isLoading = false;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(deleteCustomer.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
        state.status = "deleted";
        state.isLoading = false;
      })
      .addCase(fetchAvailablePlans.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchAvailablePlans.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchAvailablePlans.fulfilled, (state, action) => {
        state.availablePlans = action.payload;
        state.status = "idle";
        state.isLoading = false;
      })
      .addCase(fetchSuggestedCredentials.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchSuggestedCredentials.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchSuggestedCredentials.fulfilled, (state, action) => {
        state.suggestedCredentials = action.payload;
        state.status = "suggested";
        state.isLoading = false;
      });
  },
});

export const { clearCustomer, updateSelectedCustomer } = customerSlice.actions;

export const selectCustomer = (state) => state.customer.customer;
export const selectPackage = (state) => state.customer.packages.items;
export const selectVerifiers = (state) => state.customer.verifiers.items;
export const selectCustomers = (state) => state.customer.customers.items;
export const selectIsLoading = (state) => state.customer.isLoading;
export const selectPagination = (state) => state.customer.customers.pagination;
export const selectQueries = (state) => state.customer.queries;
export const selectStatus = (state) => state.customer.status;
export const selectSuggestedCredentials = (state) => state.customer.suggestedCredentials;
export const selectAvailablePlans = (state) => state.customer.availablePlans;
export const selectCustomerId = (state) => (state.customer.selectedCustomerId)
  || state.auth.currentUser?.customerId;
export const selectCustomerName = (state) => (state.customer.selectedCustomerName)
  || state.auth.currentUser?.customerName;

export default customerSlice.reducer;
