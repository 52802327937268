import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { showBondStatus } from "./lib";
import { Button, Can } from "../../components";
import { Pl, Pd, Pt } from "../../components/PropertyList/PropertyList";
import BaseForm from "../../forms/baseForm";
import Checkbox from "../../forms/inputs/Checkbox";
import PropertyEditor from "../../components/PropertyEditor/PropertyEditor";
import PropertyValue from "../../components/PropertyValue/PropertyValue";
import BonderTelemetry from "./BonderTelemetry";
import {
  fetchBonderTelemetry,
  selectTelemetry,
  updateBonderLocation,
  updateBonderStatus,
} from "./bonderSlice";

const BonderDetails = ({ bonder }) => {
  const dispatch = useDispatch();
  const telemetryData = useSelector(selectTelemetry);

  const { sshUrl } = bonder;
  const { customerId, bonderId } = useParams();

  const handleEnabledToggle = (form) => {
    if (isEmpty(form) || form.enabled === bonder.enabled) return;

    dispatch(updateBonderStatus({ bond: form, customerId }));
  };

  const onLocationSubmit = (data) => {
    dispatch(updateBonderLocation({ bond: data, customerId }));
  };

  const openSshWindow = () => {
    window.open(sshUrl, "_blank");
  };

  useEffect(() => {
    dispatch(fetchBonderTelemetry({ customerId, bonderId }));
  }, []);

  return (
    <Row>
      <Col>
        <Pl>
          <Pt>Name:</Pt>
          <Pd><PropertyValue value={bonder.name} /></Pd>
          <Pt>Type:</Pt>
          <Pd><PropertyValue value={bonder.cpu} /></Pd>
          <Pt>Location:</Pt>
          <Pd>
            <small>
              <PropertyEditor
                propName="location"
                entityId={bonder.id}
                propValue={bonder.location}
                onSubmit={onLocationSubmit}
              />
            </small>
          </Pd>
          <Pt>
            <i className="bi bi-info-circle-fill text-info" data-bs-toggle="tooltip" data-bs-placement="top" title="Calculated status of the bond&apos;s legs" />
            &nbsp;
            Status:
          </Pt>
          <Pd>{showBondStatus(bonder.status)}</Pd>
          <Pt>Connected IP:</Pt>
          <Pd><PropertyValue value={bonder.ipAddress} /></Pd>
          <Pt>Vendor IP:</Pt>
          <Pd><PropertyValue value={bonder.vendorIp} /></Pd>
          <Pt>Customer IP:</Pt>
          <Pd><PropertyValue value={bonder.customerIp} /></Pd>
        </Pl>
      </Col>
      <Col>
        <Pl>
          <Pt>Alertable:</Pt>
          <Pd><PropertyValue value={bonder.isAlertable} /></Pd>
          <Pt>Internet Access:</Pt>
          <Pd><PropertyValue value={bonder.internetAccess} /></Pd>
          <Pt>SD-WAN:</Pt>
          <Pd><PropertyValue value={bonder.includePrivateWan} /></Pd>
          <Pt>Throughput:</Pt>
          <Pd>
            <ul className="horizonal-list">
              <li>{" "}<h4><i className="bi-caret-up-fill" /></h4>{" "}</li>
              <li>{" "}<PropertyValue value={bonder.uploadRateHuman} />{" "}</li>
              <li>{" "}<h4><i className="bi-caret-down-fill" /></h4>{" "}</li>
              <li>{" "}<PropertyValue value={bonder.downloadRateHuman} />{" "}</li>
            </ul>
          </Pd>
          <Pt>Latency:</Pt>
          <Pd><PropertyValue value={bonder.tunnelLatency} />{" "}Mbps</Pd>
          <Pt>Uptime:</Pt>
          <Pd><PropertyValue value={bonder.uptime} /></Pd>
          <Pt>Site Enabled:</Pt>
          <Pd>
            <BaseForm defaults={bonder} onChange={handleEnabledToggle}>
              <Checkbox name="enabled" horizontal />
            </BaseForm>
          </Pd>
          <Can ssh="bond">
            <Pt>SSH to bond:</Pt>
            <Pd><Button variant="secondary" size="sm" onButtonClick={openSshWindow} label="Open in new window" /></Pd>
          </Can>
          <Pt>Version:</Pt>
          <Pd><PropertyValue value={bonder.version} /></Pd>
        </Pl>
      </Col>
      {telemetryData && <BonderTelemetry {...telemetryData} />}
    </Row>
  );
};

export default BonderDetails;
