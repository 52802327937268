import React from "react";
import PropTypes from "prop-types";
import AppLayout from "../AppLayout";
import "./HeaderlessLayout.scss";

const HeaderlessLayout = ({ children }) => {
  const logo = "https://d8g1bqnys44rc.cloudfront.net/comms365-portal-bg-sample.jpeg";

  return (
    <AppLayout>
      <div className="headerless" style={{ backgroundImage: `url("${logo}")` }}>
        {children}
      </div>
    </AppLayout>
  );
};

HeaderlessLayout.defaultProps = {
  children: [],
};

HeaderlessLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default HeaderlessLayout;
