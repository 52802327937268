import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestPasswordReset, selectIsLoading } from "../../features/auth/authSlice";
import SystemMessages from "../../features/systemMessage/SystemMessages";
import { Button, Link } from "../../components";
import BaseForm from "../baseForm";
import Input from "../inputs/Input";

const ForgottenPasswordForm = ({ defaults }) => {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  const handleFormSubmit = (e) => {
    dispatch(requestPasswordReset(e));
  };

  return (
    <>
      <SystemMessages />
      <BaseForm onSubmit={handleFormSubmit} defaults={defaults}>
        <Input label="Username" hideLabel placeholder="Username" name="username" type="text" />
        <Button type="submit" isLoading={isLoading} label="Submit" className="float-left mt-3" />
        <Link to="/signin">
          <Button type="submit" isLoading={isLoading} label="Return to Signin" variant="link" className="float-left mt-3" />
        </Link>
      </BaseForm>
    </>
  );
};

export default ForgottenPasswordForm;
