import React from "react";
import config from "../../config";
import PropertyValue from "../../components/PropertyValue/PropertyValue";

export const getColourByBonderStatus = (bondState) => {
  const { bondStates } = config;
  const { hex } = bondStates.reduce(
    (prev, current) => ((prev.key === bondState) ? prev : current),
    bondStates[0]
  );

  return hex;
};

export const showBondStatus = (bondState) => {
  const colour = getColourByBonderStatus(bondState);

  return (<h4><i className="bi-broadcast" style={{ color: `#${colour}` }} /></h4>);
};

export const showLegStatuses = (legs) => {
  const { legStates } = config;

  if (!legs) {
    return null;
  }

  const statuses = [].concat(legs).map(({ state, id }) => {
    const { hex } = legStates.reduce(
      (prev, current) => ((prev.key === state) ? prev : current),
      legStates[0]
    );

    return (
      <i
        key={`${id}-${hex}-${new Date().valueOf()}`}
        className="bi-circle-fill me-1"
        style={{ color: `#${hex}` }}
      />
    );
  });

  return statuses;
};

export const isSelectedBonder = (key, selectedBonderId) => (
  (key === selectedBonderId) ? "active selected-bonder-in-nav" : ""
);

export const renderPropertyValue = (value) => <PropertyValue value={value} warnOnUndefined />;
