import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { isObject } from "lodash";
import {
  fetchBondersForList,
  selectListBonders,
  selectIsListLoading,
  selectPagination,
} from "./bonderSlice";
import { showLegStatuses, renderPropertyValue } from "./lib";
import Paginater from "../../components/Paginater";
import ResultsTable from "../../components/ResultsTable/ResultsTable";
import PropertyValue from "../../components/PropertyValue/PropertyValue";
import Filter from "../../components/Filter/Filter";
import Input from "../../forms/inputs/Input";

const NameCell = (data) => {
  const { id, customerId, name } = data;
  return (<Link to={`/customers/${customerId}/bonders/${id}`}>{name}</Link>);
};

const LegCell = ({ value }) => showLegStatuses(value);

const renderIp = (value) => {
  if (isObject(value)) {
    const { ip } = value;
    return <PropertyValue value={ip} />;
  }

  return <PropertyValue value={value} />;
};

const BonderList = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const pagination = useSelector(selectPagination);
  const bonders = [].concat(useSelector(selectListBonders));
  const isLoading = useSelector(selectIsListLoading);
  const [selectedPage, setSelectedPage] = useState(1);

  const onPaginaterClick = ({ selected }) => {
    setSelectedPage(selected);
  };

  const getBonders = () => {
    dispatch(fetchBondersForList({ customerId, itemsPerPage: 10, pageNumber: selectedPage }));
  };

  useEffect(() => {
    getBonders();
  }, [selectedPage]);

  const columns = [
    {
      header: "Name",
      accessor: "name",
      Cell: ({ row: { original: { id, name } } }) => NameCell({ id, customerId, name }),
    },
    {
      header: "IP Address",
      accessor: "ipAddress",
      Cell: ({ value }) => renderIp(value),
    },
    {
      header: "Bonder Status",
      accessor: "bonderStatus",
      Cell: ({ value }) => renderPropertyValue(value),
    },
    {
      header: "Leg Status",
      accessor: "legs",
      Cell: LegCell,
    },
    {
      header: "Uptime",
      accessor: "uptime",
      Cell: ({ value }) => renderPropertyValue(value),
    },
    {
      header: "Location",
      accessor: "location",
      Cell: ({ value }) => renderPropertyValue(value),
    },
  ];

  const handleFilterChange = (filters) => {
    dispatch(fetchBondersForList({
      itemsPerPage: 10,
      pageNumber: selectedPage,
      filters,
      customerId,
    }));
  };

  return (
    <>
      <Filter onFilterChange={handleFilterChange}>
        <Row>
          <Col>
            <Input name="name_like" label="Name" />
          </Col>
        </Row>
      </Filter>
      <ResultsTable data={bonders} columns={columns} isLoading={isLoading} />
      <Paginater pagination={pagination} onPageChange={onPaginaterClick} />
    </>
  );
};

export default BonderList;
