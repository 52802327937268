import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  pickHeaderAdvert,
  selectHeaderAdvert,
  selectHeaderPointer,
  updateHeaderPointer,
} from "../../features/advert/advertSlice";

const HeaderBannerAd = () => {
  const dispatch = useDispatch();
  const selectedPointer = useSelector(selectHeaderPointer);
  const headerAdvert = useSelector(selectHeaderAdvert);
  const [advert, setAdvert] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [pointer, setPointer] = useState(selectedPointer);

  useEffect(() => {
    dispatch(pickHeaderAdvert({ pointer, limit: 1 }));
  }, []);

  useEffect(() => {
    dispatch(updateHeaderPointer({ pointer }));
  }, [pointer]);

  useEffect(() => {
    if (!isEmpty(headerAdvert) && !hasLoaded) {
      const [{ key, imageFileName }] = headerAdvert;
      if (!imageFileName) {
        return true;
      }

      import(`../../assets/adverts/${imageFileName}`)
        .then((image) => {
          setAdvert({ ...headerAdvert, imgSrc: image.default });
          setPointer(key);
          setHasLoaded(true);
        });
    }

    return () => setAdvert(null);
  }, [headerAdvert]);

  if (!advert) {
    return null;
  }

  return (
    <div className="d-sm-none d-md-none d-lg-block">
      <a href={advert.landingUrl} target="_blank" rel="noreferrer">
        <img className="img-fluid" src={advert.imgSrc} alt={advert.altText} />
      </a>
    </div>
  );
};

export default HeaderBannerAd;
