import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import xbytes from "xbytes";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import { Pl, Pt, Pd } from "../../components/PropertyList/PropertyList";
import LoadingPage from "../../pages/LoadingPage";
import Link from "../../components/Link";
import PropertyValue from "../../components/PropertyValue/PropertyValue";
import {
  fetchPackage,
  selectPackage
} from "./packageSlice";

const PackageShow = () => {
  const dispatch = useDispatch();
  const { packageId } = useParams();
  const Package = useSelector(selectPackage);

  const getPackage = () => {
    dispatch(fetchPackage({ packageId }));
  };

  useEffect(() => {
    getPackage();
  }, []);

  const getCategoryString = (category) => {
    switch (category) {
      case 0:
        return "Aggregate";
      case 1:
        return "Continuum";
      case 2:
        return "Prism";
      default:
        return "";
    }
  };

  if (Package == null) return <LoadingPage />;

  const Header = () => `Package: ${Package.name}`;

  return (
    <RequireAuth can="read package">
      <HeaderLayout>
        <BaseView
          headerSlot={() => Header({ Package })}
        >
          <Container>
            <Row>
              <Col>
                <Pl>
                  <Pt>Customer</Pt>
                  <Pd><Link to={`/customers/${Package.customerId}`}>{Package.customerName}</Link></Pd>
                  <Pt>Name</Pt>
                  <Pd>{Package.name}</Pd>
                  <Pt>Category</Pt>
                  <Pd><PropertyValue value={getCategoryString(Package.category)} /></Pd>
                  <Pt>Monthly Cost</Pt>
                  <Pd><PropertyValue value={`£${Package.monthlyCost}`} /></Pd>
                  <Pt>Allowance</Pt>
                  <Pd>{xbytes(!Package.allowance ? 0 : Package.allowance)}</Pd>
                  <Pt>Setup Cost</Pt>
                  <Pd><PropertyValue value={`£${Package.setupCost}`} /></Pd>
                  <Pt>Portal Cost</Pt>
                  <Pd><PropertyValue value={`£${Package.portalCost}`} /></Pd>
                  <Pt>Sim Alerts?</Pt>
                  <Pd><PropertyValue value={Package.simAlert} /></Pd>
                  <Pt>Start Date</Pt>
                  <Pd><PropertyValue value={Package.startDate} /></Pd>
                  <Pt>End Date</Pt>
                  <Pd><PropertyValue value={Package.endDate} /></Pd>
                </Pl>
              </Col>
              <Col>
                <Pl>
                  <Pt>Alert Step</Pt>
                  <Pd><PropertyValue value={Package.alertStep} /></Pd>
                  <Pt>Alert Trigger</Pt>
                  <Pd><PropertyValue value={Package.alertTrigger} /></Pd>
                  <Pt>PO Number</Pt>
                  <Pd><PropertyValue value={Package.poNumber} /></Pd>
                  <Pt>Location</Pt>
                  <Pd><PropertyValue value={Package.location} /></Pd>
                  <Pt>Created By</Pt>
                  <Pd><PropertyValue value={Package.user} /></Pd>
                  <Pt>Fixed Bundle</Pt>
                  <Pd><PropertyValue value={Package.fixedBundle} /></Pd>
                  <Pt>Notice</Pt>
                  <Pd><PropertyValue value={Package.notice} /></Pd>
                  <Pt>Overusage</Pt>
                  <Pd><PropertyValue value={`£${Package.oobCost}`} /></Pd>
                </Pl>
              </Col>
            </Row>
          </Container>
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default PackageShow;
