import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import HeaderlessLayout from "../layouts/headerless";
import { Link } from "../components";

const PageNotFound = () => (
  <HeaderlessLayout>
    <Container id="page-not-found" fluid>
      <Row className="d-flex justify-content-center">
        <Col md="auto">
          <Card>
            <Card.Body>
              <Card.Title>404</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Page not found</Card.Subtitle>
              <Card.Text>
                Please check the parameters of your request or return to the <Link to="/">homepage</Link>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </HeaderlessLayout>
);

export default PageNotFound;
