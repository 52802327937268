import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  whitelabel: null,
};

export const fetchWhitelabel = createAsyncThunk(
  "customer/fetch/customer",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/styles/fetch`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const whitelabelSlice = createSlice({
  name: "whitelabel",
  initialState,
  reducers: (builder) => {
    builder
      .addCase(fetchWhitelabel.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchWhitelabel.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchWhitelabel.fulfilled, (state, action) => {
        state.whitelabel = action.payload;
        state.status = "idle";
        state.isLoading = false;
      });
  },
});

export const selectWhitelabel = (state) => state.customer.customer;

export default whitelabelSlice.reducer;
