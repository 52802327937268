import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { acceptInvitation } from "../features/user/userSlice";
import HeaderlessLayout from "../layouts/headerless";
import AcceptInvitationForm from "../forms/AcceptInvitationForm/AcceptInvitationForm";

const AcceptInvitation = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const onSubmitHandler = (token, password, passwordConfirmation) => dispatch(
    acceptInvitation({ userId, token, password, passwordConfirmation })
  );

  return (
    <HeaderlessLayout>
      <Container id="reset-password" fluid>
        <Row className="d-flex justify-content-center">
          <Col md="auto">
            <Card>
              <Card.Body>
                <Card.Title>Accept Invitation</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Enter and confirm a new password.</Card.Subtitle>
                <Card.Body>
                  <AcceptInvitationForm onSubmitHandler={onSubmitHandler} />
                </Card.Body>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </HeaderlessLayout>
  );
};

export default AcceptInvitation;
