import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import ConnectionForm from "../../forms/ConnectionForm/ConnectionForm";
import { createConnection, selectConnection, selectStatus } from "./connectionSlice";
import {
  fetchSuggestedCredentials,
  selectSuggestedCredentials,
  selectStatus as selectCustomerStatus
} from "../customer/customerSlice";

const Header = () => <>Create connection</>;

const ConnectionCreate = () => {
  const status = useSelector(selectStatus);
  const connection = useSelector(selectConnection);
  const suggestedCredentials = useSelector(selectSuggestedCredentials);
  const customerStatus = useSelector(selectCustomerStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({});

  const setSuggestedCredentials = () => {
    setFormState({
      ...formState,
      ...suggestedCredentials
    });
  };

  useEffect(() => {
    if (customerStatus === "suggested") {
      setSuggestedCredentials();
    }
  }, [customerStatus]);

  useEffect(() => {
    if (status === "created") {
      navigate(`/connections/${connection.id}`);
    }
  }, [status]);

  useEffect(() => {
    if (!formState.customerId) return;

    dispatch(fetchSuggestedCredentials({ customerId: formState.customerId }));
  }, [formState.customerId]);

  const handleSubmit = (newConnection) => {
    dispatch(createConnection({ connection: newConnection }));
  };

  const handleFormChange = (newFormState) => {
    setFormState(newFormState);
  };

  return (
    <RequireAuth can="create user">
      <HeaderLayout>
        <BaseView
          headerSlot={Header}
        >
          <ConnectionForm
            onSubmit={handleSubmit}
            onChange={handleFormChange}
            defaults={formState}
            showQuantity
          />
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default ConnectionCreate;
