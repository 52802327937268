import React from "react";
import { useSelector } from "react-redux";
import { Header, HeaderBannerAd, Footer, SidebarMenu } from "../../components";
import { selectIsDirect } from "../../features/auth/authSlice";
import SystemMessages from "../../features/systemMessage/SystemMessages";
import AppLayout from "../AppLayout";

const HeaderLayout = ({ children }) => {
  const isDirect = useSelector(selectIsDirect);

  return (
    <AppLayout>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <SidebarMenu />
          <main id="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-3 py-3">
            {isDirect && (<HeaderBannerAd />)}
            <section className="pt-3">
              <SystemMessages />
              {children}
            </section>
          </main>
        </div>
      </div>
      <Footer />
    </AppLayout>
  );
};

export default HeaderLayout;
