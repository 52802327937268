export const BOND_DISABLED = "disabled";
export const BOND_DOWN = "down";
export const BOND_FLAPPING = "flapping";
export const BOND_PROBLEM = "problem";
export const BOND_TESTING = "testing";
export const BOND_UP = "up";
export const BOND_IDLE = "idle";
export const LEG_DOWN = "down";
export const LEG_FAILOVER = "failover";
export const LEG_IDLE = "idle";
export const LEG_UP = "up";
export const ADVERT_LEFT_SIDEBAR_BANNER = "advert_left_sidebar_banner";
export const ADVERT_HEADER_BANNER = "advert_header_banner";

const baseUrl = () => {
  const host = window.location.hostname.split(".");
  const subdomain = host.length > 1 && host[0];
  const url = subdomain ? `${process.env.REACT_APP_BASE_API_URL.replace("://", `://${subdomain}.`)}` : process.env.REACT_APP_BASE_API_URL;
  return url;
};

export default Object.freeze({
  commsPortalApiBaseUrl: baseUrl() || "https://app.config.fail",
  userSyncDelayInSeconds: process.env.REACT_APP_PERMS_REFRESH_SECS || 60,
  delayDispatchBySeconds: process.env.REACT_APP_DELAY_DISPATCH_BY_SECONDS || 0.8,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || undefined,
  bondStates: [
    { key: BOND_DISABLED, title: "disabled", hex: "808b96", displayOrder: 2 },
    { key: BOND_DOWN, title: "all down", hex: "9f1c27", displayOrder: 6 },
    { key: BOND_FLAPPING, title: "flapping", hex: "db6e42", displayOrder: 3 },
    { key: BOND_PROBLEM, title: "problem", hex: "db6e42", displayOrder: 4 },
    { key: BOND_TESTING, title: "testing", hex: "db6e42", displayOrder: 5 },
    { key: BOND_UP, title: "all legs up / failover", hex: "a3ce3e", displayOrder: 0 },
    { key: BOND_IDLE, title: "all legs idle", hex: "808b96", displayOrder: 1 },
  ],
  legStates: [
    { key: LEG_UP, title: "green", hex: "a3ce3e" },
    { key: LEG_FAILOVER, title: "blue", hex: "1c3b9f" },
    { key: LEG_DOWN, title: "red", hex: "9f1c27" },
    { key: LEG_IDLE, title: "grey", hex: "808b96" },
  ],
  userRoles: [
    "reseller", "end_user", "read_only", "reseller_support"
  ],
  adverts: {
    services4G5G: {
      altText: "4/5g Services",
      imageFileName: "4g-5g-services.jpeg",
      key: "services4G5G",
      landingUrl: "https://www.comms365.com/4g-5g-services/",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
    routers4G: {
      altText: "4G Routers",
      imageFileName: "4g-routers.jpeg",
      key: "routers4G",
      landingUrl: "https://www.comms365.com/product-category/4g-routers/",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
    routers5G: {
      altText: "5g Routers",
      imageFileName: "5g-routers.jpeg",
      key: "routers5G",
      landingUrl: "https://www.comms365.com/product-category/5g-routers/",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
    construction: {
      altText: "Construction sector",
      imageFileName: "banner-1.jpeg",
      key: "construction",
      landingUrl: "https://www.comms365.com/sectors/construction/",
      type: ADVERT_HEADER_BANNER,
    },
    mediaAndEventsBanner: {
      altText: "Media and events",
      imageFileName: "banner-2.jpeg",
      key: "mediaAndEventsBanner",
      landingUrl: "https://www.comms365.com/sectors/media-and-events/",
      type: ADVERT_HEADER_BANNER,
    },
    engergy: {
      altText: "In the engergy sector",
      imageFileName: "banner-3.jpeg",
      key: "engergy",
      landingUrl: "https://www.comms365.com/sectors/energy/",
      type: ADVERT_HEADER_BANNER,
    },
    iotCaas: {
      altText: "IOT CAAS",
      imageFileName: "iot-caas.jpeg",
      key: "iotCaas",
      landingUrl: "https://www.comms365.com/iot-caas/",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
    newsletter: {
      altText: "Sign up to our newsletter!",
      imageFileName: "newsletter-signup.jpeg",
      key: "newsletter",
      landingUrl: "https://www.comms365.com/newsroom/newsletter-and-marketing-email-signup/",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
    products: {
      altText: "Products",
      imageFileName: "products.jpeg",
      key: "products",
      landingUrl: "https://staging.commsportal.com/products",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
    sdWan: {
      altText: "SD-WAN",
      imageFileName: "sdwan.jpeg",
      key: "sdWan",
      landingUrl: "https://www.comms365.com/sd-wan/",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
    sectors: {
      altText: "Sectors",
      imageFileName: "sectors.jpeg",
      key: "sectors",
      landingUrl: "https://www.comms365.com/sectors/",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
    socialMedia: {
      altText: "Follow us on social media!",
      imageFileName: "social-media.jpeg",
      key: "socialMedia",
      landingUrl: "https://www.linkedin.com/company/comms365-limited/",
      type: ADVERT_LEFT_SIDEBAR_BANNER,
    },
  },
});
