import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import CustomerForm from "./CustomerForm";
import {
  clearCustomer,
  fetchCustomer,
  selectCustomer,
  selectIsLoading,
  selectStatus,
  updateCustomer,
} from "./customerSlice";
import LoadingPage from "../../pages/LoadingPage";

const Header = () => <>Edit Customer</>;

const CustomerEdit = () => {
  const status = useSelector(selectStatus);
  const isLoading = useSelector(selectIsLoading);
  const customer = useSelector(selectCustomer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const getCustomer = () => {
    dispatch(fetchCustomer({ customerId }));
  };

  useEffect(() => {
    getCustomer();

    return () => dispatch(clearCustomer());
  }, []);

  useEffect(() => {
    if (status === "updated") {
      navigate(`/customers/${customer.id}`);
    }
  }, [status]);

  const handleSubmit = (newCustomer) => {
    dispatch(updateCustomer({ customer: newCustomer }));
  };

  if (isLoading) return <LoadingPage />;

  return (
    <RequireAuth can="update" instance={customer}>
      <HeaderLayout>
        <BaseView
          headerSlot={Header}
        >
          <CustomerForm onSubmit={handleSubmit} defaults={customer} />
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default CustomerEdit;
