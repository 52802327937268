import React from "react";
import { Col, Row } from "react-bootstrap";
import { Pl, Pd, Pt } from "../../components/PropertyList/PropertyList";
import PrettyDate from "../../components/PrettyDate/PrettyDate";

const SpeedTestDetails = ({ speedTest }) => (
  <Row>
    <Col sm>
      <Pl>
        <Pt>Run at</Pt>
        <Pd><PrettyDate dateString={speedTest.createdAt} /></Pd>
        <Pt>Status</Pt>
        <Pd>{speedTest.status}</Pd>
        <Pt>Avg Throughput</Pt>
        <Pd>{speedTest.averageThroughput} MB/s</Pd>
      </Pl>
    </Col>
    <Col sm>
      <Pl>
        <Pt>Avg Latency</Pt>
        <Pd>{speedTest.averageLatency} ms</Pd>
        <Pt>Avg Packet Loss</Pt>
        <Pd>{speedTest.averagePacketLoss}</Pd>
      </Pl>
    </Col>
  </Row>
);

export default SpeedTestDetails;
