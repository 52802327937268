import { useSelector } from "react-redux";
import UseCan, { resolvePermission } from "../../lib/UseCan";
import { selectCurrentUser } from "../../features/auth/authSlice";

const Can = ({ children, instance, ...props }) => {
  const user = useSelector(selectCurrentUser);
  const can = instance ? UseCan(user, instance.instancePermissions) : UseCan(user);

  const infinitive = Object.keys(props).pop();
  const noun = Object.values(props).pop();

  const hasPermissions = () => {
    const defaultPermission = false;

    if (instance) {
      return Object.keys(props)
        .reduce((prevInspection, verb) => (prevInspection || can(verb)), defaultPermission);
    }

    const { permissions } = user;
    const outcome = resolvePermission({ permissions, verb: infinitive, noun });

    return outcome;
  };

  return (hasPermissions() ? children : null);
};

export default Can;
