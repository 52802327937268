import React from "react";
import Alert from "react-bootstrap/Alert";
import { useSelector, useDispatch } from "react-redux";
import { removeMessage, selectMessages } from "./systemMessageSlice";
import mapTerm from "../../lib/MapTerm";

const SystemMessages = ({ ...props }) => {
  const dispatch = useDispatch();
  const messages = useSelector(selectMessages);

  const handleClose = (index) => {
    dispatch(removeMessage(index));
  };

  if (!messages) {
    return null;
  }

  return (
    <>
      {Object.values(messages).map((message, index) => (
        <Alert
          key={message.key}
          variant={message.type}
          {...props}
          onClose={() => { handleClose(index); }}
        >
          {message.message}
          {message.errors?.length > 0 && (
            <ul>
              {message.errors.map(({ key, error }) => <li key={key}>{mapTerm(error)}</li>)}
            </ul>
          )}
        </Alert>
      ))}
    </>
  );
};

export default SystemMessages;
