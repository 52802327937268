import React, { useState } from "react";
import { Input } from "../../forms/inputs";
import BaseForm from "../../forms/baseForm";
import Button from "../Button";
import "./PropertyEditor.scss";

const PropertyEditor = ({ entityId, propName, propValue, onSubmit }) => {
  const [isEditing, setIsEditing] = useState(false);

  if (!isEditing) {
    return (
      <span
        onClick={() => setIsEditing(true)}
        role="button"
        onKeyPress={() => {}}
        tabIndex={0}
      >
        <i className="bi-pencil-square" />{" "}
        <span className="prop-value">{propValue}</span>
      </span>
    );
  }

  const handleOnSubmit = (data) => {
    if (onSubmit) {
      onSubmit(data);
      setIsEditing(false);
    }
  };

  return (
    <BaseForm onSubmit={handleOnSubmit} defaults={{ [propName]: propValue }}>
      <ul className="float-start property-editor">
        <li>
          <Input type="hidden" name="id" value={entityId} hideLabel />
          <Input className="float-start" size="sm" name={propName} type="text" />
        </li>
        <li>
          <Button className="mx-1" type="submit" label="ok" variant="primary" size="sm" />
        </li>
        <li>
          <Button onClick={() => setIsEditing(false)} label="cancel" variant="secondary" size="sm" />
        </li>
      </ul>
    </BaseForm>
  );
};

export default PropertyEditor;
