import React from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { resetPassword } from "../features/auth/authSlice";
import HeaderlessLayout from "../layouts/headerless";
import ResetPasswordForm from "../forms/ResetPasswordForm";

const ResetPassword = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("reset_password_token");

  const onSubmitHandler = (params) => dispatch(
    resetPassword({ resetPasswordToken, ...params })
  );

  return (
    <HeaderlessLayout>
      <Container id="reset-password" fluid>
        <Row className="d-flex justify-content-center">
          <Col md="auto">
            <Card>
              <Card.Body>
                <Card.Title>Set a new password</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Enter and confirm a new password.</Card.Subtitle>
                <Card.Body>
                  <ResetPasswordForm onSubmitHandler={onSubmitHandler} />
                </Card.Body>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </HeaderlessLayout>
  );
};

export default ResetPassword;
