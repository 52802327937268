export const isPointerOutOfRange = ({ adverts, key }) => {
  const currentPointerIndex = adverts.indexOf(key);

  if (currentPointerIndex === -1) {
    return true;
  }

  if (currentPointerIndex === adverts.length - 1) {
    return true;
  }

  return false;
};

export const getOffsetFromAdvertsByPointer = ({ advertData, key }) => {
  const adverts = advertData.map(({ key: keyRef }) => keyRef);

  const isNextPointerOutOfRange = isPointerOutOfRange({ adverts, key });
  const cursor = adverts.indexOf(key);

  return (isNextPointerOutOfRange) ? 0 : cursor + 1;
};

export default null;
