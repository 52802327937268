import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addMessage } from "../systemMessage/systemMessageSlice";
import monetisedInputs from "../../lib/monetisedInputs";
import connectionPayload from "./connectionPayload";

const initialState = {
  activationNote: "",
  connection: null,
  connections: {
    items: [],
  },
  errors: {},
};

export const fetchConnections = createAsyncThunk(
  "connection/fetch/connections",
  async ({ pageNumber, itemsPerPage }, { extra: { api }, rejectWithValue }) => {
    try {
      const params = { page: pageNumber, limit: itemsPerPage };
      const { data } = await api.get(`/connections`, { params });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const moveConnection = createAsyncThunk(
  "move/connection",
  async ({
    connectionId,
    customerId,
    packageId,
    userId,
    theDate,
    preserveLocation
  }, { extra: { api }, rejectWithValue }) => {
    try {
      const params = {
        customer_id: customerId,
        package_id: packageId,
        user_id: userId,
        the_date: theDate,
        opt_in_location: preserveLocation
      };
      const { data } = await api.patch(`/connections/${connectionId}/do_move`, null, { params });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchConnection = createAsyncThunk(
  "connection/fetch/connection",
  async ({ connectionId }, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.get(`/connections/${connectionId}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateConnection = createAsyncThunk(
  "connection/update",
  async ({ connection }, { extra: { api }, dispatch, rejectWithValue }) => {
    const { id } = connection;
    delete connection.id;

    const monetisedValues = monetisedInputs(connection);
    const connectionData = { ...connection, ...monetisedValues };

    try {
      const { data } = await api.patch(`/connections/${id}`, { connection: connectionPayload(connectionData, id) });
      dispatch(addMessage({ message: "connection updated", type: "success" }));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateConnectionMeta = createAsyncThunk(
  "connection/meta/update",
  async ({ connectionId, metaKey, metaValue }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.patch(`/connections/${connectionId}/meta`, { connection: { [metaKey]: metaValue } });
      dispatch(addMessage({ message: "Connection meta updated", type: "success" }));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createConnection = createAsyncThunk(
  "connection/create",
  async ({ connection }, { extra: { api }, dispatch, rejectWithValue }) => {
    const monetisedValues = monetisedInputs(connection);
    const connectionData = connectionPayload({ ...connection, ...monetisedValues });
    try {
      let response;
      if (connection.quantity > 1) {
        response = await api.post(`/connections/bulk_create`, { quantity: connection.quantity, connection: connectionData });
      } else {
        response = await api.post(`/connections`, { connection: connectionData });
      }

      dispatch(addMessage({ message: "connection created", type: "success" }));
      const { data } = response;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteConnection = createAsyncThunk(
  "connection/delete",
  async ({ connection }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.delete(`/connections/${connection.id}`);
      dispatch(addMessage({ message: `connection ${connection.id} deleted`, type: "success" }));
      return data;
    } catch (error) {
      dispatch(addMessage({ message: "connection couldn't be deleted", type: "danger" }));
      return rejectWithValue(error);
    }
  }
);

export const performConnectionAction = createAsyncThunk(
  "connection/action",
  async ({ connectionId, action }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.patch(`/connections/${connectionId}/action/${action}`);
      dispatch(addMessage({ message: `connection status was changed to ${action}`, type: "success" }));
      return data;
    } catch (error) {
      dispatch(addMessage({ message: "connection status couldn't be changed", type: "danger" }));
      return rejectWithValue(error);
    }
  }
);

export const fetchActivationNote = createAsyncThunk(
  "connection/activation-note/fetch",
  async ({ connectionId }, { extra: { api }, rejectWithValue }) => {
    try {
      api.defaults.headers.common.Accept = "application/pdf";
      const { request: { response } } = await api.get(`/connections/${connectionId}/activation_note`, { responseType: "blob" });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    clearConnection: (state) => {
      state.connection = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConnections.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchConnections.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchConnections.fulfilled, (state, action) => {
        state.status = "idle";
        state.isLoading = false;
        state.connections = action.payload;
      })
      .addCase(fetchConnection.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchConnection.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchConnection.fulfilled, (state, action) => {
        state.status = "idle";
        state.isLoading = false;
        state.connection = action.payload;
      })
      .addCase(createConnection.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(createConnection.rejected, (state, action) => {
        state.status = "error";
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(createConnection.fulfilled, (state, action) => {
        state.status = "created";
        state.isLoading = false;
        state.connection = action.payload;
      })
      .addCase(updateConnection.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(updateConnection.rejected, (state, action) => {
        state.status = "error";
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(updateConnection.fulfilled, (state, action) => {
        state.status = "updated";
        state.isLoading = false;
        state.connection = action.payload;
      })
      .addCase(fetchActivationNote.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchActivationNote.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchActivationNote.fulfilled, (state, action) => {
        state.status = "activation-note-success";
        state.isLoading = false;
        state.activationNote = action.payload;
      })
      .addCase(deleteConnection.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(deleteConnection.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(deleteConnection.fulfilled, (state) => {
        state.status = "deleted";
        state.isLoading = false;
      })
      .addCase(moveConnection.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(moveConnection.rejected, (state, action) => {
        state.status = "error";
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(moveConnection.fulfilled, (state, action) => {
        state.status = "connection moved";
        state.isLoading = false;
        state.connection = action.payload;
      })
      .addCase(performConnectionAction.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(performConnectionAction.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(performConnectionAction.fulfilled, (state, action) => {
        state.status = "deleted";
        state.isLoading = false;
        state.connection = action.payload;
      })
      .addCase(updateConnectionMeta.fulfilled, (state, action) => {
        state.connection = action.payload;
      });
  },
});

export const { clearConnection } = connectionSlice.actions;

export const selectActivationNote = (state) => state.connection.activationNote;
export const selectConnection = (state) => state.connection.connection;
export const selectConnections = (state) => state.connection.connections.items;
export const selectIsLoading = (state) => state.connection.isLoading;
export const selectPagination = (state) => state.connection.connections.pagination;
export const selectStatus = (state) => state.connection.status;

export default connectionSlice.reducer;
