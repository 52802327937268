import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  checkIperfResult,
  clearTestInProgress,
  fetchIperfResult,
  selectTestInProgress,
  selectStatus,
} from "./iperfSlice";
import IperfLaunchItem from "./IperfLaunchItem";

const IperfLaunchPad = () => {
  const testInProgress = useSelector(selectTestInProgress);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const interval = useRef(null);

  const { bonderId, customerId } = useParams();

  useEffect(() => {
    if (isEmpty(testInProgress)) {
      clearInterval(interval.current);
    } else {
      const iperfId = (testInProgress.iperfId) || testInProgress.id;
      interval.current = setInterval(() => {
        dispatch(checkIperfResult({ customerId, bonderId, iperfId }));
      }, 2000);
    }
    return () => clearInterval(interval.current);
  }, [testInProgress]);

  useEffect(() => {
    if (status === "ready") {
      dispatch(fetchIperfResult({ customerId, bonderId }));
      dispatch(clearTestInProgress());
    }

    if (status === "error") {
      clearInterval(interval.current);
    }
  }, [status]);

  if (isEmpty(testInProgress)) {
    return null;
  }

  return (
    <IperfLaunchItem key={testInProgress.id} iperf={testInProgress} />
  );
};

export default IperfLaunchPad;
