import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { isEmpty } from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Can from "../../components/Can";
import Filter from "../../components/Filter";
import {
  fetchCustomers,
  selectCustomers,
  selectIsLoading,
  selectPagination
} from "./customerSlice";
import Paginater from "../../components/Paginater";
import ResultsTable from "../../components/ResultsTable/ResultsTable";
import Button from "../../components/Button";
import Input from "../../forms/inputs/Input";
import Select from "../../forms/inputs/Select";
import PrettyDate from "../../components/PrettyDate/PrettyDate";

const CUSTOMER_ROWS_PER_PAGE = 10;

const DateCreatedCell = ({ value }) => <PrettyDate dateString={value} />;

const NameCell = ({ value, ...props }) => {
  const { name } = props.row.original;
  return (<Link to={`/customers/${value}`}>{name}</Link>);
};

const CustomerList = () => {
  const dispatch = useDispatch();
  const pagination = useSelector(selectPagination);
  const customers = useSelector(selectCustomers);
  const isLoading = useSelector(selectIsLoading);
  const [selectedPage, setSelectedPage] = useState(1);

  const onPaginaterClick = ({ selected }) => {
    setSelectedPage(selected);
  };

  const getCustomers = () => {
    dispatch(fetchCustomers({ itemsPerPage: CUSTOMER_ROWS_PER_PAGE, pageNumber: selectedPage }));
  };

  const handleFilterChange = (filters) => {
    if (!isEmpty(filters)) {
      dispatch(fetchCustomers({
        itemsPerPage: CUSTOMER_ROWS_PER_PAGE,
        pageNumber: selectedPage,
        filters,
      }));
    }
  };

  useEffect(() => {
    getCustomers();
  }, [selectedPage]);

  const columns = [
    {
      header: "Name",
      accessor: "id",
      Cell: NameCell,
    },
    {
      header: "Category",
      accessor: "category"
    },
    {
      header: "Created At",
      accessor: "createdAt",
      Cell: DateCreatedCell,
    }
  ];

  const categoryOptions = [
    { value: "", label: "Any" },
    { value: "direct", label: "Direct" },
    { value: "reseller", label: "Reseller" },
  ];

  const hideButtonForDaisyGreeneKingGoLive = true;

  return (
    <>
      {!hideButtonForDaisyGreeneKingGoLive && (
        <Can create="customer">
          <Row>
            <Col>
              <Button
                as={NavLink}
                to="/customers/create"
                size="sm"
                className="float-end mb-3"
                label="New customer"
              />
            </Col>
          </Row>
        </Can>
      )}
      <Row>
        <Col>
          <Filter onFilterChange={handleFilterChange}>
            <Row>
              <Col>
                <Input name="name_like" label="Name" />
              </Col>
              <Col>
                <Select name="category_eq" label="Category" options={categoryOptions} />
              </Col>
            </Row>
          </Filter>
        </Col>
      </Row>
      <ResultsTable data={customers} columns={columns} isLoading={isLoading} />
      <Paginater pagination={pagination} onPageChange={onPaginaterClick} />
    </>
  );
};

export default CustomerList;
