import { template } from "lodash";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addMessage } from "../systemMessage/systemMessageSlice";

const initialState = {
  errors: {},
  ipRange: null,
  ipRanges: {
    items: [],
  },
  message: null,
  queries: {},
};

export const fetchIpRanges = createAsyncThunk(
  "iprange/fetch",
  async ({ pageNumber, itemsPerPage }, { extra: { api }, rejectWithValue }) => {
    try {
      const params = { page: pageNumber, limit: itemsPerPage };
      const { data } = await api.get(`/ipaddresses`, { params });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const validateIpRange = createAsyncThunk(
  "iprange/validate",
  async ({ ipaddress }, { extra: { api }, dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(`/ipaddresses`, { ipaddress, commit: false });

      const { creating, messages } = {
        messages: [],
        ...response.data
      };
      const messageData = { creating, ip: ipaddress.ip };
      const messageDetails = Object.assign(messageData, ...messages.map((p) => (p)));
      const { invalidPrefix } = messageDetails;

      let systemMessage;
      if (invalidPrefix) {
        systemMessage = template(
          "Unable to use forbidden CIDR length \"<%= invalidPrefix %>\" in <%= ip %>"
        )(messageData);
        dispatch(addMessage({ message: systemMessage, type: "danger" }));
      } else {
        systemMessage = template(
          "Your requested range (<%= ip %>) will generate <%= creating %> IP addresses, click \"proceed\" to apply"
        )(messageData);

        // TODO Provide (validatedIpaddress) action link within this message
        dispatch(addMessage({ message: systemMessage, type: "info" }));
      }

      return systemMessage;
    } catch (error) {
      const { errors, valid, messages: { invalidPrefix } } = {
        errors: 0,
        messages: [],
        valid: 0,
        ...error.response.data
      };
      const errorMessages = { errors, valid, invalidPrefix, ip: ipaddress.ip };
      let systemMessage = template("Unable to create IP range: <%= ip %>, check your selected customer.");

      if (errors) {
        systemMessage = template(
          "Errors affecting <%= errors %> IP addresses prevent this IP address range from being created. "
          + "<%= valid %> addresses in the <%= ip %> range were OK."
        );
      }
      const { message } = { message: systemMessage(errorMessages), ...error.response.data };
      dispatch(addMessage({ message, type: "danger" }));

      return rejectWithValue(error);
    }
  }
);

export const createIpRange = createAsyncThunk(
  "iprange/create",
  async ({ ipaddress }, { extra: { api }, dispatch, rejectWithValue }) => {
    const { ip } = ipaddress;
    try {
      const { data } = await api.post(`/ipaddresses`, { ipaddress, commit: true });
      const { created } = data;
      const systemMessage = template(
        "Success! Created <%= created %> addresses in the <%= ip %> IP Address range"
      )({ created, ip });

      dispatch(addMessage({ message: systemMessage, type: "success" }));

      return systemMessage;
    } catch (error) {
      dispatch(addMessage({ message: "An unknown error occured", type: "danger" }));
      return rejectWithValue(error);
    }
  }
);

export const queryIpAddresses = createAsyncThunk(
  "iprange/query",
  async ({ key, query, page }, { extra: { api }, rejectWithValue }) => {
    page = page || 1;

    try {
      const { data } = await api.get("/ipaddresses", { params: { page, ...query } });

      return {
        key,
        details: { query, result: data.items, pagination: data.pagination },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ipRangeSlice = createSlice({
  name: "iprange",
  initialState,
  reducers: {
    clearIpRange: (state) => {
      state.ipRange = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIpRanges.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(fetchIpRanges.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(fetchIpRanges.fulfilled, (state, action) => {
        state.ipRange = action.payload;
        state.status = "idle";
        state.isLoading = false;
      })
      .addCase(validateIpRange.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(validateIpRange.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(validateIpRange.fulfilled, (state, action) => {
        state.ipRange = action.payload;
        state.status = "validated";
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(createIpRange.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(createIpRange.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(createIpRange.fulfilled, (state, action) => {
        state.ipRange = action.payload;
        state.status = "created";
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(queryIpAddresses.pending, (state, action) => {
        state.status = "loading";
        state.isLoading = true;
        state.queries = { ...state.queries, [action.payload.key]: action.payload.details };
      })
      .addCase(queryIpAddresses.rejected, (state) => {
        state.status = "error";
        state.isLoading = false;
      })
      .addCase(queryIpAddresses.fulfilled, (state, action) => {
        state.ipRanges = action.payload;
        state.status = "fulfilled";
        state.isLoading = false;
        state.queries = { ...state.queries, [action.payload.key]: action.payload.details };
      });
  },
});

export const { clearIpRange } = ipRangeSlice.actions;

export const selectIpQueries = (state) => state.ipRange.queries;
export const selectIpRanges = (state) => state.ipRange.ipRanges.items;
export const selectIsLoading = (state) => state.ipRange.isLoading;
export const selectMessage = (state) => state.ipRange.message;
export const selectPagination = (state) => state.ipRange.ipRanges.pagination;
export const selectStatus = (state) => state.ipRange.status;
