import React from "react";
import HeaderLayout from "../layouts/header";

const LoadingPage = () => (
  <HeaderLayout>
    loading...
  </HeaderLayout>
);

export default LoadingPage;
