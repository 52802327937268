import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { dateTimeHandler, MILLISECOND_FORMAT } from "../../../lib/dateTimeHandler";
import {
  selectLatency,
} from "../../bonder/bonderSlice";
import { NoData, LineChart } from "../../../components/LineChart";

const LatencyResult = ({ testRef }) => {
  const latencyData = useSelector(selectLatency);
  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    const timeHandler = dateTimeHandler({ enableDuration: true });
    if (latencyData[testRef]) {
      const data = latencyData[testRef].map(([x, y]) => (
        {
          name: "latency",
          xAxisValue: timeHandler.duration({ millisecond: x }).format(MILLISECOND_FORMAT),
          yAxisValue: y,
        }
      ));

      if (data.length > 0) {
        setChartData([data]);
      }
    }
  }, [latencyData]);

  return (
    <Col className="col-md-10 offset-md-1">
      {chartData && (
        <LineChart
          data={chartData}
          targetHtml={`latency-cpu-load-${testRef}`}
          showLineBullets
        />
      )}
      {!chartData && (<NoData />)}
    </Col>
  );
};

export default LatencyResult;
