import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RequireAuth from "../lib/RequireAuth";
import HeaderLayout from "../layouts/header";
import { selectCurrentUser } from "../features/auth/authSlice";
import { fetchCustomer, selectCustomer, clearCustomer } from "../features/customer/customerSlice";
import BondSpaceLink from "../features/bonder/BondSpaceLink";

const PrivateHome = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const customer = useSelector(selectCustomer);
  const { label } = { label: null, ...user };

  useEffect(() => {
    const { customerId } = user;
    if (customerId) {
      dispatch(fetchCustomer({ customerId }));
    }

    return () => dispatch(clearCustomer());
  }, [user]);

  return (
    <RequireAuth can="read user">
      <HeaderLayout>
        <div>
          <h1 className="user-welcome">Welcome {label}</h1>
          {customer && (<BondSpaceLink {...customer} />)}
        </div>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default PrivateHome;
