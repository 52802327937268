import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import LatestSpeedTest from "./LatestSpeedTest";
import SpeedTestDetails from "./SpeedTestDetails";
import SpeedTestResults from "./SpeedTestResults";
import PrettyDate from "../../components/PrettyDate/PrettyDate";
import Button from "../../components/Button";
import { toCompleted, toMostRecentlyQueued } from "./lib";
import SpeedTestLaunchPad from "./SpeedTestLaunchPad";
import {
  selectBonderSpeedTests,
  selectIsBonderLoading,
  createSpeedTest,
} from "../bonder/bonderSlice";

const SpeedTests = ({ bonderId, customerId }) => {
  const speedtests = useSelector(selectBonderSpeedTests);
  const [enqueuedSpeedtests, setEnqueuedSpeedtests] = useState([]);
  const [completedSpeedtests, setCompletedSpeedtests] = useState([]);
  const [isPolling, setIsPolling] = useState(false);
  const isLoading = useSelector(selectIsBonderLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    setEnqueuedSpeedtests(Object.values(speedtests).reduce(toMostRecentlyQueued, []));
    setCompletedSpeedtests(Object.values(speedtests).reduce(toCompleted, []));
  }, [speedtests]);

  const handleSpeedTest = () => {
    dispatch(createSpeedTest({ customerId, bonderId }));
  };

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  return (
    <div>
      <Row>
        <Col>
          <h4>Speed tests</h4>
        </Col>
      </Row>
      <Row>
        <Col sm>
          {enqueuedSpeedtests && (
            <SpeedTestLaunchPad
              speedTestsInProgress={enqueuedSpeedtests}
              handleIsPolling={setIsPolling}
              customerId={customerId}
              bonderId={bonderId}
            />
          )}
        </Col>
        <Col sm>
          {isPolling && (
            <Button
              type="button"
              label="Running speed test..."
              variant="primary"
              className="float-end"
              isLoading
              disabled
            />
          )}
          {!isPolling && (
            <Button
              type="button"
              onButtonClick={handleSpeedTest}
              className="float-end"
              label="Run speed test"
              variant="primary"
            />
          )}
        </Col>
      </Row>
      {completedSpeedtests.length > 0 && (<LatestSpeedTest speedTests={completedSpeedtests} />)}
      {completedSpeedtests.length > 0 && (<h4>Previous tests</h4>)}
      {completedSpeedtests.length > 0 && (
        <Accordion>
          {completedSpeedtests.map((test) => (
            <Accordion.Item eventKey={test.id} key={test.id}>
              <Accordion.Header>
                <PrettyDate dateString={test.createdAt} /> - {test.direction}
              </Accordion.Header>
              <Accordion.Body>
                <SpeedTestDetails speedTest={test} />
                <SpeedTestResults speedTest={test} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default SpeedTests;
