import React from "react";
import { Pl, Pd, Pt } from "../../components/PropertyList/PropertyList";
import { showBondStatus } from "./lib";
import PropertyValue from "../../components/PropertyValue/PropertyValue";

const BonderMapMarkerContent = ({ bonder }) => {
  const {
    name,
    location,
    ipAddress,
    version,
    cpu,
    status,
    isAlertable,
    internetAccess,
    includePrivateWan,
    uploadRateHuman,
    downloadRateHuman,
  } = bonder;

  return (
    <div id="content">
      <h1 id="firstHeading" className="firstHeading" style={{ fontSize: "1rem" }}>{name}</h1>
      <div id="bodyContent">
        <Pl>
          <Pt>Location:</Pt>
          <Pd>{location}</Pd>
          <Pt>IP Address:</Pt>
          <Pd>{ipAddress}</Pd>
          <Pt>Version:</Pt>
          <Pd>{version}</Pd>
          <Pt>Type:</Pt>
          <Pd>{cpu}</Pd>
          <Pt>Status:</Pt>
          <Pd>{showBondStatus(status)}</Pd>
          <Pt>Alterable:</Pt>
          <Pd><PropertyValue value={isAlertable} /></Pd>
          <Pt>Internet Access:</Pt>
          <Pd><PropertyValue value={internetAccess} /></Pd>
          <Pt>SD-WAN:</Pt>
          <Pd><PropertyValue value={includePrivateWan} /></Pd>
          <Pt>Throughput:</Pt>
          <Pd>
            <ul className="horizonal-list">
              <li>{" "}<h4><i className="bi-caret-up-fill" /></h4>{" "}</li>
              <li>{" "}<PropertyValue value={uploadRateHuman} />{" "}</li>
              <li>{" "}<h4><i className="bi-caret-down-fill" /></h4>{" "}</li>
              <li>{" "}<PropertyValue value={downloadRateHuman} />{" "}</li>
            </ul>
          </Pd>
        </Pl>
      </div>
    </div>
  );
};

export default BonderMapMarkerContent;
