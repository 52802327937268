import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchConnections, selectIsLoading, selectPagination, selectConnections } from "./connectionSlice";
import Paginater from "../../components/Paginater";
import ResultsTable from "../../components/ResultsTable/ResultsTable";
import Button from "../../components/Button";
import mapTerm from "../../lib/MapTerm";

const USER_ROWS_PER_PAGE = 10;

const IdCell = ({ value: id }) => (
  <NavLink
    key={`link-for-${id}`}
    className="entity-link"
    to={`/connections/${id}`}
  >
    {id}
  </NavLink>
);

const MapTermCell = ({ value }) => mapTerm(value);

const ConnectionList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const pagination = useSelector(selectPagination);
  const connections = useSelector(selectConnections);
  const [selectedPage, setSelectedPage] = useState(1);

  const onPaginaterClick = ({ selected }) => {
    setSelectedPage(selected);
  };

  const getConnections = () => {
    dispatch(fetchConnections({ itemsPerPage: USER_ROWS_PER_PAGE, pageNumber: selectedPage }));
  };

  useEffect(() => {
    getConnections();
  }, [selectedPage]);

  const columns = [
    {
      header: "ID",
      accessor: "id",
      Cell: IdCell
    },
    {
      header: "Plan",
      accessor: "planName",
    },
    {
      header: "Operator",
      accessor: "operator",
      Cell: MapTermCell,
    },
    {
      header: "Service",
      accessor: "service",
      Cell: MapTermCell,
    },
    {
      header: "Status",
      accessor: "status",
    }
  ];

  return (
    <>
      <Button as={NavLink} to="/connections/create" className="float-end mb-3" label="New connection" />
      <ResultsTable data={connections} columns={columns} isLoading={isLoading} />
      <Paginater pagination={pagination} onPageChange={onPaginaterClick} />
    </>
  );
};

export default ConnectionList;
