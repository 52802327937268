import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import xbytes from "xbytes";
import { useFormContext } from "react-hook-form";
import { useError } from "../baseForm";

const Bytes = ({ label, name, hideLabel, ...props }) => {
  const { register, formState: { errors }, watch, setValue } = useFormContext();
  const error = useError(errors, name)();
  const errorMessage = (error) ? error.message : false;
  const inputId = `${name}-input`;
  const args = { ...props, id: inputId };
  const value = watch(name);
  const [prettyValue, setPrettyValue] = useState("");

  useEffect(() => {
    setPrettyValue(xbytes(value));
  }, [value]);

  const handlePrettyChange = (e) => {
    const newPrettyValue = e.target.value;
    setPrettyValue(newPrettyValue);
    if (xbytes.isBytes(newPrettyValue)) {
      setValue(name, xbytes.parse(newPrettyValue).bytes);
    }
  };

  return (
    <Form.Group>
      { !hideLabel && <Form.Label htmlFor={inputId}>{label}</Form.Label> }
      <Form.Control aria-label={label} {...register(name)} {...args} type="hidden" />
      <Form.Control
        aria-label={label}
        name={`${name}-pretty`}
        value={prettyValue}
        {...args}
        isInvalid={error}
        onChange={handlePrettyChange}
      />
      {errorMessage && <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback> }
    </Form.Group>
  );
};

export default Bytes;
