const connectionPayload = (data, connectionId) => {
  const {
    permittedActions,
    instancePermissions,
    createdAt,
    updatedAt,
    ...payload
  } = data;

  if (!connectionId) {
    return payload;
  }

  const {
    operator,
    service,
    ...connectionUpdatePayload
  } = payload;
  return connectionUpdatePayload;
};

export default connectionPayload;
