import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { isArray } from "lodash";
import SpeedTestDetails from "./SpeedTestDetails";
import { toLastCompleted } from "./lib";
import { selectBonderSpeedTests } from "../bonder/bonderSlice";

const LatestSpeedTest = () => {
  const speedtests = useSelector(selectBonderSpeedTests);
  const [up, setUp] = useState(null);
  const [down, setDown] = useState(null);

  useEffect(() => {
    if (isArray(speedtests)) {
      const completedTests = speedtests.reduce(toLastCompleted, []);
      const upTest = completedTests.filter(({ direction }) => (direction === "up"))[0];
      setUp(upTest);

      const downTest = completedTests.filter(({ direction }) => (direction === "down"))[0];
      setDown(downTest);
    }
  }, [speedtests]);

  return (
    <Row>
      {up && (
        <Col sm>
          <h5>Up</h5>
          <SpeedTestDetails speedTest={up} />
        </Col>
      )}
      {down && (
        <Col sm>
          <h5>Down</h5>
          <SpeedTestDetails speedTest={down} />
        </Col>
      )}
    </Row>
  );
};

export default LatestSpeedTest;
