/* eslint-disable react/prop-types */
import React from "react";

const RenderSlot = ({ component, hide }) => {
  const Component = component || (() => "");
  if (hide) return "";

  return <Component />;
};

export default RenderSlot;
