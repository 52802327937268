export default (o, c) => {
  const proto = c.prototype;
  const oldFormat = proto.format;

  // Arrow syntax breaks plugin
  // eslint-disable-next-line func-names
  proto.format = function (formatStr) {
    if (formatStr === undefined || formatStr === "YYYY-MM-DDTHH:mm:ssZ") {
      return oldFormat.bind(this)("D/M/YYYY HH:mm:ss");
    }

    return oldFormat.bind(this)(formatStr);
  };
};
