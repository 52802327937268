import React from "react";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import ConnectionList from "./ConnectionList";

const Header = () => <>Connections</>;

const Connections = () => (
  <RequireAuth can="read connection">
    <HeaderLayout>
      <BaseView headerSlot={Header}>
        <ConnectionList />
      </BaseView>
    </HeaderLayout>
  </RequireAuth>
);

export default Connections;
