import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { DATE_AXIS } from "../../components/LineChart/lib";
import LineChart from "../../components/LineChart/LineChart";
import { selectIsTelemetryLoading } from "./bonderSlice";

const BonderTelemetry = ({ dataTunnelBytes, dataTunnelLatency }) => {
  const isLoading = useSelector(selectIsTelemetryLoading);
  const [tunnelBytes, setTunnelBytes] = useState(false);
  const [tunnelLatency, setTunnelLatency] = useState(false);
  const colourList = ["#73EAB1", "#73A8EA", "#D573EA"];

  useEffect(() => {
    if (dataTunnelBytes) {
      const recieved = dataTunnelBytes
        .map(({ date, meanRx }) => ({ name: "Recieved", xAxisValue: new Date(date), yAxisValue: meanRx }))
        .filter(({ yAxisValue }) => yAxisValue !== null);
      const sent = dataTunnelBytes
        .map(({ date, meanTx }) => ({ name: "Sent", xAxisValue: new Date(date), yAxisValue: meanTx }))
        .filter(({ yAxisValue }) => yAxisValue !== null);
      const total = dataTunnelBytes
        .map(({ date, totalTx }) => ({ name: "Total", xAxisValue: new Date(date), yAxisValue: totalTx }))
        .filter(({ yAxisValue }) => yAxisValue !== null);

      const data = [].concat([recieved], [sent], [total]);
      setTunnelBytes(data);
    }

    if (dataTunnelLatency) {
      const data = dataTunnelLatency
        .map(({ date, mean }) => ({ xAxisValue: new Date(date), yAxisValue: mean }))
        .filter(({ yAxisValue }) => yAxisValue !== null);

      setTunnelLatency([data]);
    }
  }, [dataTunnelLatency, dataTunnelBytes]);

  return (
    <>
      {isLoading && (
        <Row>
          <Col>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </Col>
        </Row>
      )}
      {tunnelBytes && (
        <Row>
          <Col>
            <h5>Site Throughput</h5>
            <LineChart
              data={tunnelBytes}
              targetHtml="site-throughput"
              yAxisTitle="Data (bps)"
              colourList={colourList}
              xAxisType={DATE_AXIS}
              xAxisMinGridDistance={60}
              showLegend
              showCursor
            />
          </Col>
        </Row>
      )}
      {tunnelLatency && (
        <Row>
          <Col>
            <h5>Site Latency</h5>
            <LineChart
              data={tunnelLatency}
              targetHtml="site-latency"
              yAxisTitle="Latency (Ms)"
              xAxisType={DATE_AXIS}
              xAxisMinGridDistance={60}
              showCursor
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default BonderTelemetry;
