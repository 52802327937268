import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { dateTimeHandler, MILLISECOND_FORMAT } from "../../../lib/dateTimeHandler";
import {
  selectThroughput,
} from "../../bonder/bonderSlice";
import { NoData, LineChart } from "../../../components/LineChart";

const ThroughputResult = ({ testRef }) => {
  const throughputData = useSelector(selectThroughput);
  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    const timeHandler = dateTimeHandler({ enableDuration: true });
    if (throughputData[testRef]) {
      const data = throughputData[testRef].map(([x, y]) => (
        {
          name: "throughput",
          xAxisValue: timeHandler.duration({ millisecond: x }).format(MILLISECOND_FORMAT),
          yAxisValue: y,
        }
      ));

      if (data.length > 0) {
        setChartData([data]);
      }
    }
  }, [throughputData]);

  return (
    <Col className="col-md-10 offset-md-1">
      {chartData && (
        <LineChart
          data={chartData}
          targetHtml={`throughput-cpu-load-${testRef}`}
          showLineBullets
        />
      )}
      {!chartData && (<NoData />)}
    </Col>
  );
};

export default ThroughputResult;
