import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { isEmpty } from "lodash";
import { Button } from "../../components";
import BaseForm from "../baseForm";
import SelectCustomer from "../inputs/SelectCustomer";
import Select from "../inputs/Select";
import Input from "../inputs/Input";
import Bytes from "../inputs/bytes";
import SelectIpAddress from "../inputs/SelectIpAddress";
import SimFields from "./SimFields";
import { fetchAvailablePlans, selectAvailablePlans } from "../../features/customer/customerSlice";
import { selectFormErrors } from "../../features/systemMessage/systemMessageSlice";
import mapTerm from "../../lib/MapTerm";

const ConnectionForm = ({ onSubmit, defaults, onChange, showQuantity }) => {
  const [operator, setOperator] = useState(defaults ? defaults.operator : "");
  const [service, setService] = useState(defaults ? defaults.service : "");
  const [customerId, setCustomerId] = useState(defaults ? defaults.customerId : "");
  const [planId, setPlanId] = useState(defaults ? defaults.plan : "");
  const [formState, setFormState] = useState(defaults || {});
  const { id: connectionId } = defaults;
  const dispatch = useDispatch();
  const availablePlans = useSelector(selectAvailablePlans);
  const formErrors = useSelector(selectFormErrors);

  const isSim = () => service && !["fttc", "fttp", "adsl"].includes(service);

  const handleFormSubmit = (e) => {
    if (onSubmit) onSubmit(e);
  };

  const operatorOptions = () => {
    const entries = Object.entries(availablePlans);
    return entries.map(([value]) => ({ value, label: mapTerm(value) }));
  };

  const serviceOptions = () => {
    if (!operator || isEmpty(availablePlans)) return [];

    const entries = Object.entries(availablePlans[operator]);
    return entries.map(([value]) => ({ value, label: mapTerm(value) }));
  };

  const planOptions = () => {
    if (!service || !operator || isEmpty(availablePlans)) return [];

    return availablePlans[operator][service]
      .map((plan) => ({ value: plan.id, label: plan.name }));
  };

  const handleFormChange = (newFormState) => {
    setOperator(newFormState.operator);
    setService(newFormState.service);
    setCustomerId(newFormState.customerId);
    setPlanId(newFormState.planId);
    setFormState(newFormState);
    if (onChange) onChange(newFormState);
  };

  const getPlanOptions = () => {
    if (customerId) dispatch(fetchAvailablePlans({ customerId }));
  };

  useEffect(() => {
    getPlanOptions();
  }, [customerId]);

  useEffect(() => {
    setFormState({
      ...formState,
      password: defaults.password,
      username: defaults.username,
    });
  }, [defaults.username, defaults.password]);

  useEffect(() => {
    if (!operator || !service || !planId || isEmpty(availablePlans)) return;

    const {
      setupCost,
      monthlyCost,
      allowance,
      term
    } = availablePlans[operator][service].find((plan) => plan.id === planId);

    setFormState({
      ...formState,
      setupCost,
      monthlyCost,
      allowance,
      term
    });
  }, [planId]);

  return (
    <BaseForm
      onChange={handleFormChange}
      onSubmit={handleFormSubmit}
      defaults={formState}
      errors={formErrors}
    >
      <Row className="mt-2">
        <Col md={6}>
          { showQuantity && <Input label="How many connections would you like to create?" name="quantity" type="number" min="1" max="100" /> }
        </Col>
        <Col md={6}>
          <Input label="PO Number" name="poNumber" />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <SelectCustomer isDisabled={connectionId} label="Customer" name="customerId" />
        </Col>
        <Col md={6}>
          <Select label="Plan" name="planId" options={planOptions()} />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <Select
            label="Operator"
            name="operator"
            isDisabled={connectionId}
            options={operatorOptions()}
          />
        </Col>
        <Col md={6}>
          <Select
            label="Service"
            name="service"
            isDisabled={connectionId}
            options={serviceOptions()}
          />
        </Col>
      </Row>
      {[].includes("Hiding cost attributes for now because there's no backend data; pending CurrencyInput component") && (
        <Row className="mt-2">
          <Col md={6}>
            <Input
              type="number"
              min="0.00"
              max="9999"
              step="0.01"
              label="Setup Cost"
              name="setupCost"
            />
          </Col>
          <Col md={6}>
            <Input
              type="number"
              min="0.00"
              max="9999"
              step="0.01"
              label="Monthly Cost"
              name="monthlyCost"
            />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={6}>
          <Bytes label="Allowance" name="allowance" />
        </Col>
        <Col md={6}>
          <Input label="Term" name="term" />
        </Col>
      </Row>
      {connectionId && (
        <Row className="mt-2">
          {isSim() && <SimFields /> }
        </Row>
      )}
      {connectionId && (
        <Row className="mt-2">
          <Col md={4}>
            <SelectIpAddress
              label="IP Address"
              name="ipaddress_id"
              operator={operator}
              service={service}
            />
          </Col>
          <Col md={4}>
            <Input label="Username" name="username" />
          </Col>
          <Col md={4}>
            <Input label="Password" name="password" />
          </Col>
        </Row>
      )}
      <Button type="submit" label={connectionId ? "Update" : "Create"} className="mt-3" />
    </BaseForm>
  );
};

export default ConnectionForm;
