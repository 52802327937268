import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { signOut, selectCurrentUser } from "../../features/auth/authSlice";
import "./Header.scss";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSignOut = (e) => {
    e.preventDefault();
    dispatch(signOut());
    navigate("/signin");
  };

  const scrollToTopOfWindow = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const globalSearchEnabled = false;

  return (
    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <Link to="/" label="Internal link" className="navbar-brand col-md-3 col-lg-2 me-0 px-3">
        <span>CommsPortal</span>
      </Link>
      <button
        className="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target=".sidebar"
        aria-controls="sidebarMenu"
        aria-label="Toggle navigation"
        onClick={scrollToTopOfWindow}
      >
        <span className="navbar-toggler-icon" />
      </button>

      {globalSearchEnabled && (
        <Form.Control
          className="form-control-dark w-100"
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />
      )}

      {!globalSearchEnabled && (
        <div className="w-100">&nbsp;</div>
      )}

      {currentUser && (
        <div className="dropdown">
          <button className="btn user-menu-dropdown dropdown-toggle" type="button" id="user-header-menu" data-bs-toggle="dropdown" aria-expanded="false">
            Signed in as {currentUser.label}
          </button>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="user-header-menu">
            <li><Link to={`/users/${currentUser.id}`} className="dropdown-item">Your account</Link></li>
            <li><hr /></li>
            <li><button onClick={handleSignOut} className="dropdown-item" type="button"><i className="person-circle" />Sign out</button></li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
