import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider } from "react-hook-form";
import { isEqual } from "lodash";
import { clearMessages } from "../features/systemMessage/systemMessageSlice";

const BaseForm = ({ onSubmit, defaults, children, errors, onChange, isHorizontal, ...props }) => {
  defaults = defaults || {};
  const dispatch = useDispatch();
  const formMethods = useForm({
    defaultValues: defaults,
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { isDirty }
  } = formMethods;

  const formState = watch();

  useEffect(() => {
    if (errors) {
      Object.entries(errors).map(([key, value]) => setError(key, { type: "server", message: value[0] }));
    }
  }, [errors, setError]);

  useEffect(() => {
    clearErrors();
    if (onChange && isDirty) onChange(formState);
  }, [JSON.stringify(formState)]);

  useEffect(() => {
    if (isEqual(defaults, formState)) return;

    const defaultEntries = Object.entries(defaults);
    defaultEntries.forEach(([index, value]) => {
      if (formState[index] !== value) setValue(index, value);
    });
  }, [JSON.stringify(defaults)]);

  const handleFormSubmit = (data) => {
    dispatch(clearMessages());
    if (onSubmit) onSubmit(data);
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(handleFormSubmit)} {...props}>
        {children}
      </Form>
    </FormProvider>
  );
};

export default BaseForm;

export const useError = (errors, name) => () => {
  if (!errors && !errors.name) return false;

  return errors[name];
};
