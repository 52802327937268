import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import BaseForm from "../baseForm";
import { Button } from "../../components";

import {
  selectCustomerPackage,
  selectCustomerVerifiers,
  selectVerifiers,
  selectPackage
} from "../../features/customer/customerSlice";
import { moveConnection, selectStatus, clearConnection, fetchConnection } from "../../features/connection/connectionSlice";

const MoveConnectionForm = ({ customerId }) => {
  const initialFormData = {
    date: "",
    package: "",
    username: "",
    location: false,
  };

  const packages = useSelector(selectPackage);
  const verifiers = useSelector(selectVerifiers);
  const status = useSelector(selectStatus);
  const { connectionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialFormData);
  const verifierOptions = [];
  const packageOptions = [];

  const getConnection = () => {
    dispatch(fetchConnection({ connectionId }));
  };

  useEffect(() => {
    getConnection();

    return () => dispatch(clearConnection());
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue
    }));
  };

  const handleMoveConnection = (event) => {
    event.preventDefault();
    const packageId = formData.package;
    const userId = formData.username;
    const theDate = formData.date;
    const preserveLocation = formData.location;
    dispatch(moveConnection({
      connectionId,
      customerId,
      packageId,
      userId,
      theDate,
      preserveLocation
    }));
  };

  useEffect(() => {
    if (status === "connection moved") {
      navigate(`/connections/${connectionId}`);
    }
  }, [status]);

  const getCustomerPackages = () => {
    dispatch(selectCustomerPackage({ customerId }));
  };

  useEffect(() => {
    getCustomerPackages();
  }, []);

  const getCustomerVerifiers = () => {
    dispatch(selectCustomerVerifiers({ customerId }));
  };

  useEffect(() => {
    getCustomerVerifiers();
  }, []);

  const loadVerifier = async () => {
    await verifiers.forEach((item) => {
      verifierOptions.push({ value: item.id, label: `${item.firstName} ${item.lastName}` });
    });
  };

  const loadPackage = async () => {
    await packages.forEach((item) => {
      packageOptions.push({ value: item.id, label: item.name });
    });
  };

  loadVerifier();
  loadPackage();

  return (
    /* eslint-disable */
    <BaseForm onSubmit={handleMoveConnection}>
      <Row className="mt-4">
        <Col md={6}>
          <div>
            <label htmlFor="date">Backdate Usage:</label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </div>
        </Col>
        <Col md={6}>
          <div>
            <label htmlFor="package">Package:</label>
            <select
              id="package"
              name="package"
              value={formData.package}
              onChange={handleChange}
            >
              <option value="">Select a package..</option>
              {packageOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <div>
            <label>Select Verifier:</label>
            <select
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
            >
              <option value="">Select a user..</option>
              {verifierOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col md={6}>
          <div>
            <label htmlFor="location">Preserve location after move?:</label>
            <input
              type="checkbox"
              id="location"
              name="location"
              checked={formData.location}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
      <Button type="submit" onButtonClick={handleMoveConnection} label="Move" className="mt-3" />
    </BaseForm>
    /* eslint-enable */
  );
};

export default MoveConnectionForm;
