import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Row } from "react-bootstrap";
import {
  fetchTargetBonds,
  runIperfTest,
  selectIsPolling,
  selectTargetBonds,
} from "./iperfSlice";
import Button from "../../components/Button";
import BaseForm from "../../forms/baseForm";
import Select from "../../forms/inputs/Select";

const IperfCreate = () => {
  const dispatch = useDispatch();
  const isPolling = useSelector(selectIsPolling);
  const targetBonds = useSelector(selectTargetBonds);
  const { bonderId, customerId } = useParams();
  const [targetBondId, setTargetBondId] = useState(null);
  const [targetOptions, setTargetOptions] = useState([]);
  const [targetError, setTargetError] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleRunIperfTest = () => {
    if (targetBondId === null) {
      setTargetError("Please select a target bond");
      const errors = { targetBond: true };
      return setFormErrors(errors);
    }

    return dispatch(runIperfTest({ customerId, bonderId, targetBondId }));
  };

  useEffect(() => {
    dispatch(fetchTargetBonds({ customerId, bonderId }));
  }, []);

  useEffect(() => {
    if (targetBonds) {
      const optionData = Object.values(targetBonds)
        .map(({ name, id }) => ({ value: id, label: name }));

      setTargetOptions(optionData);
    }
  }, [targetBonds]);

  const handleTargetBonderSelect = ({ targetBond }) => {
    setTargetBondId(targetBond);
  };

  return (
    <>
      <Row>
        {targetOptions && (
          <Col>
            <BaseForm onChange={handleTargetBonderSelect} errors={formErrors}>
              <Select
                name="targetBond"
                label="Target bonds"
                options={targetOptions}
              />
            </BaseForm>
          </Col>
        )}
        <Col className="align-self-end">
          {isPolling && (
            <Button
              type="button"
              label="Running test..."
              variant="primary"
              isLoading
              disabled
            />
          )}
          {!isPolling && (
            <Button
              type="button"
              onButtonClick={handleRunIperfTest}
              label="Run Iperf test"
              variant="primary"
            />
          )}
        </Col>
      </Row>
      {targetError && (
        <Row>
          <Col className="pt-3">
            <Alert variant="danger" onClose={() => setTargetError(false)} dismissible>
              {targetError}
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};

export default IperfCreate;
