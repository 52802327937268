import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { generateMultiLineChart } from "./lib";
import "./LineChart.scss";

const LineChart = ({ data, targetHtml, ...options }) => {
  let chart;

  useEffect(() => {
    chart = generateMultiLineChart({ targetHtml, data, ...options });

    return () => chart.dispose();
  }, [data]);

  return (
    <Col className="col-md-10 offset-md-1">
      <div id={targetHtml} className="amchart-4-linechart-height" />
    </Col>
  );
};

export default LineChart;
