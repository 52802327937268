// Surmised values are provided by developer so respective values are TBC
const surmised = true;

const terms = [
  { term: "ee_unlimited", value: "EE Unlimited" },
  { term: "ee", value: "EE" },
  { term: "esim", value: "eSIM" },
  { term: "vodafone_nbiot:", value: "Vodafone NBIoT" },
  { term: "3g", value: "3G" },
  { term: "4g", value: "4G" },
  { term: "5g", value: "5G" },
  { term: "nbiot", value: "NBIoT" },
  { term: "adsl", value: "ADSL" },
  { term: "fttc", value: "FTTC" },
  { term: "fttp", value: "FTTP" },
  { term: "fixedLineM", value: "Fixed Broadband (M)", surmised },
  { term: "e2", value: "E2", surmised },
  { term: "o2", value: "O2", surmised },
  { term: "o2Other", value: "O2 (unlimited)", surmised },
  { term: "three", value: "Three", surmised },
  { term: "threeMega", value: "Three (mega)", surmised },
  { term: "vodafone", value: "Vodafone", surmised },
  { term: "vodafoneUnlimited", value: "Vodafone (unlimited)", surmised },
  { term: "fluidata", value: "Fluidata", surmised },
  { term: "o2Unlimited", value: "O2 (unlimited)", surmised },
  { term: "vodafoneNbiot", value: "Vodafone (NBIoT)", surmised },
  { term: "eeUnlimited", value: "EE (unlimited)", surmised },
  { term: "Customer id: can't be blank", value: "Customer is blank" },
  { term: "Email: can't be blank, is invalid", value: "Email can't be blank or invalid" },
  { term: "Password confirmation: doesn't match Password, doesn't match Password", value: "Password confirmation: doesn't match Password" },
];

export default terms;
