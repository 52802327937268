import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import RequireAuth from "../lib/RequireAuth";
import HeaderLayout from "../layouts/header";
import BaseView from "../components/BaseView/BaseView";
import BonderGmap from "../features/bonder/BonderGmap";
import BonderList from "../features/bonder/BonderList";
import { fetchCustomer, selectCustomer } from "../features/customer/customerSlice";
import { fetchBondersForMap, selectMapBonders } from "../features/bonder/bonderSlice";

const Header = (customerName) => `Spaces: ${customerName}`;

const Spaces = () => {
  const dispatch = useDispatch();
  const bonders = useSelector(selectMapBonders);
  const customer = useSelector(selectCustomer);
  const { customerId } = useParams();

  useEffect(() => {
    dispatch(fetchBondersForMap({ customerId }));
    dispatch(fetchCustomer({ customerId }));
  }, []);

  const headerSlot = (customer) ? () => Header(customer.name) : null;

  return (
    <RequireAuth can="read user">
      <HeaderLayout>
        <BaseView headerSlot={headerSlot}>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item key="bread-space-home">SD-One</Breadcrumb.Item>
              {customer && (
                <Breadcrumb.Item key="bread-customer" href={`/customers/${customerId}/spaces`}>
                  {customer.name}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
            {bonders.length > 0 && (
              <Col md={12}>
                <BonderGmap
                  mapDimensions={{ height: `600px`, width: `100%` }}
                  bonders={bonders}
                  customerId={customerId}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12} className="gy-3">
              <Card>
                <div className="card-header">Bonders</div>
                <div className="card-body">
                  <BonderList />
                </div>
              </Card>
            </Col>
          </Row>
        </BaseView>
      </HeaderLayout>
    </RequireAuth>
  );
};

export default Spaces;
