import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Button } from "../../components";
import BaseForm from "../../forms/baseForm";
import Checkbox from "../../forms/inputs/Checkbox";
import { Input, Select, SelectCustomer } from "../../forms/inputs";
import { selectIsLoading } from "./userSlice";
import { selectFormErrors } from "../systemMessage/systemMessageSlice";
import {
  fetchEntityGroups,
  selectEntityGroups,
} from "../auth/authSlice";

const UserForm = ({ onSubmit, defaults, showPassword }) => {
  const errors = useSelector(selectFormErrors);
  const entityGroups = useSelector(selectEntityGroups);
  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    if (onSubmit) onSubmit(e);
  };

  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "manager", label: "Manager" },
    { value: "noc", label: "NOC" },
    { value: "noc_apprentice", label: "NOC Apprentice" },
    { value: "sales_manager", label: "Sales Manager" },
    { value: "account_manager", label: "Account Manager" },
    { value: "reseller", label: "Reseller" },
    { value: "end_user", label: "End User" },
    { value: "read_only", label: "Read Only" },
  ];

  useEffect(() => {
    dispatch(fetchEntityGroups());
  }, []);

  return (
    <BaseForm onSubmit={handleFormSubmit} defaults={defaults} errors={errors}>
      <Container>
        {isLoading && (
          <Row>
            <Col>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm>
            <Form.Group className="mb-3">
              <SelectCustomer label="Customer" name="customerId" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="First Name" name="firstName" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="Last Name" name="lastName" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="Username" name="username" type="text" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Input label="Email" name="email" type="email" />
            </Form.Group>
          </Col>
          <Col sm>
            {showPassword && (
              <Form.Group className="mb-3">
                <Input label="Password" name="password" type="password" />
              </Form.Group>
            )}
            {showPassword && (
              <Form.Group className="mb-3">
                <Input label="Password confirmation" name="passwordConfirmation" type="password" />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Select label="Role" name="role" options={roleOptions} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Select label="Entity group" id="entity-group-select" name="entityGroupId" options={entityGroups} />
            </Form.Group>
            <Row>
              <Col>
                <h4 className="mt-3 h5">Alerts</h4>
                <Stack direction="horizontal" gap={3}>
                  <Checkbox label="Marketing" name="alerts.marketing" />
                  <Checkbox label="Notifications" name="alerts.notification" />
                  <Checkbox label="usage" name="alerts.usage" />
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
        <Stack direction="horizontal" gap={2} className="pt-3">
          <Button type="submit" variant="primary" label="Submit" isLoading={isLoading} />
          <Button type="button" onClick={() => navigate(-1)} variant="secondary" label="Cancel" />
        </Stack>
      </Container>
    </BaseForm>
  );
};

export default UserForm;
