import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import HeaderlessLayout from "../layouts/headerless";
import ForgottenPasswordForm from "../forms/ForgottenPasswordForm";

const ForgottenPassword = () => (
  <HeaderlessLayout>
    <Container id="forgotten-password" fluid>
      <Row className="d-flex justify-content-center">
        <Col md="auto">
          <Card>
            <Card.Body>
              <Card.Title>Forgotten password</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Enter your username to begin password recovery.</Card.Subtitle>
              <Card.Body>
                <ForgottenPasswordForm />
              </Card.Body>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </HeaderlessLayout>
);

export default ForgottenPassword;
