import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";
import { useError } from "../baseForm";

const REGEX_FOR_DECIMAL_NUMBER = /^([0-9]+\.[0-9]*|\.[0-99]+)$/;

const Input = ({ label, name, hideLabel, ...props }) => {
  const { register, formState: { errors } } = useFormContext();

  const { step } = { step: undefined, ...props };
  let pattern;
  if (new RegExp(REGEX_FOR_DECIMAL_NUMBER).test(step)) {
    pattern = { value: REGEX_FOR_DECIMAL_NUMBER, message: `Entered value does not match ${step} format` };
  }

  const error = useError(errors, name)();
  const errorMessage = (error) ? error.message : false;
  const inputId = `${name}-input`;
  const args = { ...props, id: inputId };

  return (
    <Form.Group>
      { !hideLabel && <Form.Label htmlFor={inputId}>{label}</Form.Label> }
      <Form.Control
        aria-label={label}
        {...register(name, { pattern })}
        {...args}
        isInvalid={error}
      />
      {errorMessage && <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback> }
    </Form.Group>
  );
};

Input.defaultProps = {
  label: "",
  name: "",
  type: ""
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string
};

export default Input;
