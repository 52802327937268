import React, { useRef, useState, useEffect } from "react";

const GoogleMap = ({ onClick, children, style }) => {
  const ref = useRef();
  const [map, setMap] = useState(false);
  const [zoom, setZoom] = useState(8); // initial zoom
  const [center, setCenter] = useState({ lat: 51.50000, lng: 0 });

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter()?.toJSON());
  };

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        center,
        zoom,
      }));
    }
  }, []);

  useEffect(() => {
    if (map && children) {
      const bounds = new window.google.maps.LatLngBounds();

      children.map((marker) => {
        bounds.extend(marker.props.position);
        return marker.key;
      });
      map.fitBounds(bounds);
    }
  }, [map, children]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }

      map.addListener("touchstart", () => {}, { passive: true });
      map.addListener("touchmove", () => {}, { passive: true });
    }
  }, [map, onClick, onIdle]);

  return (
    <div ref={ref} style={style} id="map">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
        return null;
      })}
    </div>
  );
};

export default GoogleMap;
