import _ from "lodash";
import React from "react";
import ShowBooleanAsIcon from "../ShowBooleanAsIcon/ShowBooleanAsIcon";

const PropertyValue = ({ value, warnOnUndefined }) => {
  if (value === false || value === true) {
    return (<ShowBooleanAsIcon isTruthy={value} />);
  }

  if (value || _.isNumber(value)) {
    return value;
  }

  if (value === undefined && warnOnUndefined === true) {
    return (
      <span className="data-value-alert">
        <i className="bi-exclamation-octagon-fill"> Not mapped</i>
      </span>
    );
  }

  return (<i className="bi-question-circle"> Unknown</i>);
};

export default PropertyValue;
