export const resolvePermission = ({ permissions, verb, noun }) => {
  const defaultPermission = false;

  if (!permissions || !verb || !noun) {
    return defaultPermission;
  }

  return Object
    .entries(permissions)
    .reduce((hasPermission, [entityName, entityPermissions]) => {
      if (hasPermission) {
        return true;
      }
      const { can } = entityPermissions || {};
      return (entityName === noun && (Object.keys(can).includes(verb) || Object.keys(can).includes("manage")));
    }, defaultPermission);
};

const resolveInstancePermission = ({ permissions, verb }) => permissions.can.includes(verb) || permissions.can.includes("manage");

const UseCan = (user, instancePermissions) => {
  if (instancePermissions) {
    return (verb) => resolveInstancePermission({ permissions: instancePermissions, verb });
  }

  if (user) {
    const { permissions } = user;
    return (verb, noun) => resolvePermission({ permissions, verb, noun });
  }

  return () => false;
};

export default UseCan;
