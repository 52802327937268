import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectIsDirect } from "../../features/auth/authSlice";
import SidebarAdverts from "../SidebarAdverts/SidebarAdverts";
import NavLink from "../NavLink";
import Can from "../Can";
import "./SidebarMenu.scss";

const NavigationLink = ({ linkTitle, linkIconClass, uri }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <li className="nav-item" key={`${pathname}-list-item-navigation-link`}>
      <NavLink to={uri} className="nav-link">
        <i className={linkIconClass} />{` ${linkTitle}`}
      </NavLink>
    </li>
  );
};

const SidebarMenu = () => {
  const isDirect = useSelector(selectIsDirect);

  return (
    <Nav className="col-md-3 col-lg-2 d-md-block sidebar collapse">
      <div className="position-sticky p-3">
        <ul className="nav nav-pills flex-column mb-auto">
          <Can read="customer">
            <NavigationLink uri="/customers" linkTitle="Customers" linkIconClass="bi-building" key="customers-nav-link" />
          </Can>
          <Can read="user">
            <NavigationLink uri="/users" linkTitle="Users" linkIconClass="bi-people-fill" key="users-nav-link" />
          </Can>
          <Can read="connection">
            <NavigationLink uri="/connections" linkTitle="Connections" linkIconClass="bi-broadcast-pin" key="connection-nav-link" />
          </Can>
        </ul>
      </div>
      {isDirect && (<SidebarAdverts />)}
    </Nav>
  );
};

export default SidebarMenu;
