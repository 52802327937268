import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectPollingUpdatedAt } from "../bonder/bonderSlice";
import PrettyPastDate from "../../components/PrettyPastDate/PrettyPastDate";

const SpeedTestLaunchItem = ({ speedtest }) => {
  const [show, setShow] = useState(true);
  const updatedAt = useSelector(selectPollingUpdatedAt);

  if (!show) {
    return null;
  }

  return (
    <Alert variant="info" onClose={() => setShow(false)} dismissible>
      Test requested {speedtest && (
        <PrettyPastDate pastDate={speedtest.createdAt} updated={updatedAt} />
    )}
    </Alert>
  );
};

export default SpeedTestLaunchItem;
