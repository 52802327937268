const isRefreshIntervalExpired = (delayInSeconds, intervalStartTimestamp) => {
  const delay = delayInSeconds || 300;
  const millsecondsPerSecond = 1000;
  const syncExpiryTimestamp = new Date(intervalStartTimestamp)
    .valueOf() + (delay * millsecondsPerSecond);
  const currentTimestamp = new Date();

  return (new Date(syncExpiryTimestamp) < new Date(currentTimestamp));
};

export default isRefreshIntervalExpired;
