import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, OperatorServices } from "../../components";
import BaseForm from "../../forms/baseForm";
import Input from "../../forms/inputs/Input";
import SelectCustomer from "../../forms/inputs/SelectCustomer";
import {
  fetchOperatorServices,
  selectSims,
  selectLines,
  selectIsLoadingOpServices,
} from "../operatorService/operatorServiceSlice";
import { selectFormErrors } from "../systemMessage/systemMessageSlice";
import { selectIsLoading } from "./ipRangeSlice";

const IpRangeForm = ({ onSubmit, defaults }) => {
  const errors = useSelector(selectFormErrors);
  const isLoading = useSelector(selectIsLoading);
  const isLoadingOpServices = useSelector(selectIsLoadingOpServices);
  const sims = useSelector(selectSims);
  const lines = useSelector(selectLines);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOperatorServices());
  }, []);

  const handleFormSubmit = (e) => {
    if (onSubmit) onSubmit(e);
  };

  return (
    <BaseForm onSubmit={handleFormSubmit} defaults={defaults} errors={errors}>
      <Input label="Range" name="ip" type="text" />
      <SelectCustomer label="Customer" name="customerId" type="text" />

      <h4 className="mt-3">Operators</h4>
      {isLoadingOpServices && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
      {!isLoadingOpServices && <OperatorServices sims={sims} lines={lines} />}

      <Button isLoading={isLoading} type="submit" label="Submit" name="something" className="mt-3" />
    </BaseForm>
  );
};

export default IpRangeForm;
