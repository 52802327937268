import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import RequireAuth from "../../lib/RequireAuth";
import HeaderLayout from "../../layouts/header";
import BaseView from "../../components/BaseView/BaseView";
import MoveConnectionForm from "../../forms/MoveConnectionForm/MoveConnectionForm";
import {
  fetchCustomers,
  selectCustomers
} from "../customer/customerSlice";

const CUSTOMER_ROWS_PER_PAGE = 100;

const Header = () => <>Move Connection</>;

const ConnectionMove = () => {
  const customers = useSelector(selectCustomers);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [matchingCustomers, setMatchingCustomers] = useState([]);
  const dispatch = useDispatch();

  const getCustomers = () => {
    dispatch(fetchCustomers({ itemsPerPage: CUSTOMER_ROWS_PER_PAGE }));
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "targetCustomer") {
      const matches = customers.filter((customer) => customer.name.toLowerCase()
        .includes(value.toLowerCase()));
      setMatchingCustomers(matches);
    }
  };

  const handleCustomerSelection = (customerId) => {
    setSelectedCustomerId(customerId);
    setMatchingCustomers([]);
  };

  return (
  /* eslint-disable */
    <RequireAuth can="update connection">
      <HeaderLayout>
        <BaseView
          headerSlot={Header}
        >
          <form>
            <div>
              <Row className="mt-4">
                <Col md={6}>
                  <label htmlFor="targetCustomer">Target Customer:</label>
                  <input
                    type="text"
                    id="targetCustomer"
                    name="targetCustomer"
                    onChange={handleChange}
                  />
                  {matchingCustomers.length > 0 && (
                  <ul>
                    {matchingCustomers.map((customer) => (
                      <li key={customer.id} onClick={() => handleCustomerSelection(customer.id)}>
                        {customer.name}
                      </li>
                    ))}
                  </ul>
                  )}
                </Col>
              </Row>

            </div>
            
          </form>
          {selectedCustomerId && (
          <div>
            <MoveConnectionForm customerId={selectedCustomerId} />
          </div>
          )}

        </BaseView>
      </HeaderLayout>
    </RequireAuth>
    /* eslint-enable */
  );
};

export default ConnectionMove;
