import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import humanizeString from "string-humanize";

import {
  fetchIpRanges,
  selectIsLoading,
  selectPagination,
  selectIpRanges,
} from "./ipRangeSlice";
import Paginater from "../../components/Paginater";
import ResultsTable from "../../components/ResultsTable/ResultsTable";
import Button from "../../components/Button";
import Can from "../../components/Can";
import NavLink from "../../components/NavLink";

const IP_RANGE_ROWS_PER_PAGE = 10;
const positiveIcon = <i className="bi bi-check" />;
const negativeIcon = <i className="bi bi-x" />;

const renderPositiveOrNegativeSymbol = ({ value }) => (value ? positiveIcon : negativeIcon);
const listOperatorServices = ({ value }) => (value ? [].concat(value).join(", ") : null);
const listStatus = ({ value }) => humanizeString(value || "");

const IpRangeList = () => {
  const isLoading = useSelector(selectIsLoading);
  const pagination = useSelector(selectPagination);
  const ipRanges = useSelector(selectIpRanges);

  const dispatch = useDispatch();
  const { customerId } = useParams();
  const [selectedPage, setSelectedPage] = useState(1);

  const onPaginaterClick = ({ selected }) => {
    setSelectedPage(selected);
  };

  const getIpRanges = () => {
    dispatch(fetchIpRanges({ itemsPerPage: IP_RANGE_ROWS_PER_PAGE, pageNumber: selectedPage }));
  };

  useEffect(() => {
    getIpRanges();
  }, [selectedPage]);

  const columns = [
    {
      header: "IP Range",
      accessor: "ip",
    },
    {
      header: "Is private",
      accessor: "isPrivate",
      Cell: renderPositiveOrNegativeSymbol,
    },
    {
      header: "Services",
      accessor: "operatorServices",
      Cell: listOperatorServices,
    },
    {
      header: "Status",
      accessor: "status",
      Cell: listStatus,
    },
  ];

  return (
    <>
      <Can create="ipaddress">
        <Button as={NavLink} to={`/customers/${customerId}/ip-ranges/create`} className="float-end mb-3" label="New IP range" />
      </Can>
      <ResultsTable data={ipRanges} columns={columns} isLoading={isLoading} />
      <Paginater pagination={pagination} onPageChange={onPaginaterClick} />
    </>
  );
};

export default IpRangeList;
