import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectStatus, selectIsLoading } from "../../features/auth/authSlice";
import { Button } from "../../components";
import BaseForm from "../baseForm";
import Input from "../inputs/Input";
import SystemMessages from "../../features/systemMessage/SystemMessages";

const AcceptInvitationForm = ({ defaults, onSubmitHandler }) => {
  const status = useSelector(selectStatus);
  const isLoading = useSelector(selectIsLoading);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("invitation_token");

  useEffect(() => {
    if (status === "accept") {
      navigate("/signin");
    }
  }, [status, navigate]);

  const handleFormSubmit = (e) => {
    const {
      password,
      passwordConfirmation,
    } = e;

    if (onSubmitHandler) {
      onSubmitHandler({
        token,
        password,
        passwordConfirmation
      });
    }
  };

  return (
    <>
      <SystemMessages />
      <BaseForm onSubmit={handleFormSubmit} defaults={defaults}>
        <div className="form-floating mb-3">
          <Input label="Password" hideLabel placeholder="Password" name="password" type="password" />
        </div>
        <div className="form-floating mb-3">
          <Input label="Password Confirmation" hideLabel placeholder="Password confirmation" name="passwordConfirmation" type="password" />
        </div>
        <Button type="submit" isLoading={isLoading} label="Submit" className="mt-3" />
      </BaseForm>
    </>
  );
};

export default AcceptInvitationForm;
