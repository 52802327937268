import React from "react";
import { useSelector } from "react-redux";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Spinner } from "react-bootstrap";
import BonderMarker from "./BonderMarker";
import BondStatesGlossary from "./BondStatesGlossary";
import config from "../../config";
import { selectIsMapLoading } from "./bonderSlice";
import GoogleMap from "../../components/GoogleMap/GoogleMap";

const { googleMapsApiKey } = config;

const renderWrapper = (status) => {
  switch (status) {
    case Status.LOADING:
      return <h1>loading</h1>;
    case Status.FAILURE:
      return <h1>Error</h1>;
    default:
      return null;
  }
};

const BonderGmap = ({
  mapDimensions,
  bonders,
  onClick,
}) => {
  const isMapDataLoading = useSelector(selectIsMapLoading);

  return (
    <>
      <BondStatesGlossary />
      {isMapDataLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <Wrapper apiKey={googleMapsApiKey} render={renderWrapper}>
        <GoogleMap onClick={onClick} style={mapDimensions}>
          {bonders && bonders
            .filter(({ position }) => (position && position.length > 0))
            .map((bonder) => {
              const { id, position, location } = bonder;
              const [longitude, latitude] = position;
              return (
                <BonderMarker
                  key={id}
                  bondId={id}
                  position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                  touchstart={{ passive: true }}
                  bonder={bonder}
                  location={location}
                  isOnlyMarkerForMap={bonders.length === 1}
                />
              );
            })}
        </GoogleMap>
      </Wrapper>
    </>
  );
};

export default BonderGmap;
