import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { Button, Link } from "../../components";
import { signIn, selectIsLoading } from "../../features/auth/authSlice";
import BaseForm from "../baseForm";
import { Input, Checkbox } from "../inputs/index";
import SystemMessages from "../../features/systemMessage/SystemMessages";
import { selectWhitelabel, fetchWhitelabel } from "../../features/whitelabel/whitelabelSlice";
import commsLogo from "../../assets/commsportal-logo-large.png";
import brandLogos from "../../assets/brandAssets/brandLogos";
import "./SignInForm.scss";

const SignInForm = ({ defaults }) => {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const whitelabel = useSelector(selectWhitelabel);
  const logos = brandLogos();
  const [logo, setLogo] = useState();

  const handleFormSubmit = (e) => {
    dispatch(signIn({ ...e }));
  };

  const getWhitelabel = () => {
    dispatch(fetchWhitelabel());
  };

  useEffect(() => {
    getWhitelabel();
  }, []);

  const findBrandLogo = () => {
    try {
      const logoKeys = Object.keys(logos);
      if (logoKeys.includes(whitelabel.logoPath)) {
        setLogo(logos[whitelabel.logoPath]);
      }
    } catch {
      setLogo("");
    }
  };

  useEffect(() => {
    findBrandLogo();
  });

  if (whitelabel == null) {
    return (<p />);
  }

  const branding = {
    background: whitelabel.primaryColour,
  };

  return (
    <Container fluid className="form-signin">
      <Row className="d-flex justify-content-center">
        <Col md="auto">
          <Card>
            <Card.Body>
              <Row>
                <Col className="p-4">
                  {whitelabel.message ? (
                    <Image fluid src={commsLogo} alt="brand-logo" />
                  ) : (
                    <Image fluid src={logo} alt="brand-logo" />
                  )}
                </Col>
              </Row>
              <SystemMessages />
              <BaseForm onSubmit={handleFormSubmit} defaults={defaults}>
                <div className="form-floating mb-3">
                  <Input label="Username" name="username" placeholder="Username" type="text" hideLabel />
                </div>
                <div className="form-floating mb-3">
                  <Input label="Password" name="password" placeholder="Password" type="password" hideLabel />
                </div>
                <div className="form-floating mb-3">
                  <Checkbox label="Remember me?" name="rememberMe" horizontal />
                </div>
                <div className="form-floating mb-3">
                  <Link to="/forgotten-password">Forgotten password</Link>
                </div>
                {whitelabel.message ? (
                  <Button isLoading={isLoading} type="submit" className="w-100" variant="primary" size="lg" label="Sign in" />
                ) : (
                  <Button style={{ backgroundColor: branding.background }} isLoading={isLoading} type="submit" className="w-100" variant="primary" size="lg" label="Sign in" />
                )}
              </BaseForm>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignInForm;
