import { configureStore } from "@reduxjs/toolkit";
import advertReducer from "./features/advert/advertSlice";
import authReducer from "./features/auth/authSlice";
import bonderReducer from "./features/bonder/bonderSlice";
import connectionReducer from "./features/connection/connectionSlice";
import customerReducer from "./features/customer/customerSlice";
import packageReducer from "./features/package/packageSlice";
import iperfReducer from "./features/iperf/iperfSlice";
import operatorServiceReducer from "./features/operatorService/operatorServiceSlice";
import systemMessageReducer from "./features/systemMessage/systemMessageSlice";
import userReducer from "./features/user/userSlice";

import commsportal from "./api/commsportal";

import apiErrorHandler from "./lib/apiErrorHandler";

const api = commsportal();

const store = configureStore({
  reducer: {
    advert: advertReducer,
    auth: authReducer,
    bonder: bonderReducer,
    package: packageReducer,
    connection: connectionReducer,
    customer: customerReducer,
    iperf: iperfReducer,
    operatorService: operatorServiceReducer,
    systemMessage: systemMessageReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: { api },
    },
  }).concat(apiErrorHandler)
});

export default store;
