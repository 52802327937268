import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectIpQueries, queryIpAddresses } from "../../features/ipRange/ipRangeSlice";
import Select from "./Select";

const SelectIpAddress = ({ name, operator, service, ...props }) => {
  const key = () => `${name}-select-query`;

  const ipQueries = useSelector(selectIpQueries);
  const ips = () => (ipQueries[key()] || {}).result || [];
  const dispatch = useDispatch();

  const fetchIpAddresses = (ip) => {
    const query = { ip_like: ip };
    // TODO Make me a delayed dispatch using lodash.debounce
    dispatch(queryIpAddresses({ key: key(), query, page: 1 }));
  };

  useEffect(() => {
    fetchIpAddresses();
  }, []);

  useEffect(() => {
    fetchIpAddresses();
  }, [operator, service]);

  const handleIpChange = (e) => {
    fetchIpAddresses(e);
  };

  const ipOptions = () => ips().map(
    (ip) => ({ value: ip.id, label: ip.ip }),
  );

  return (
    <Select
      options={ipOptions()}
      onInputChange={handleIpChange}
      name={name}
      {...props}
    />
  );
};

export default SelectIpAddress;
