import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Stack } from "react-bootstrap";
import {
  fetchSpeedTestResults,
} from "../bonder/bonderSlice";
import SpeedTestLaunchItem from "./SpeedTestLaunchItem";

const SpeedTestLaunchPad = ({ speedTestsInProgress, customerId, bonderId, handleIsPolling }) => {
  const interval = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(speedTestsInProgress)) {
      clearInterval(interval.current);

      handleIsPolling(false);
    } else {
      handleIsPolling(true);

      const testIds = speedTestsInProgress.map(({ testRef }) => testRef);
      interval.current = setInterval(() => {
        dispatch(fetchSpeedTestResults({ customerId, bonderId, testIds }));
      }, 2000);
    }

    return () => clearInterval(interval.current);
  }, [speedTestsInProgress]);

  if (!speedTestsInProgress) {
    return null;
  }

  return (
    <Stack>
      {speedTestsInProgress.map((speedtest) => (
        <SpeedTestLaunchItem key={speedtest.id} speedtest={speedtest} />
      ))}
    </Stack>
  );
};

export default SpeedTestLaunchPad;
