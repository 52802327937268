import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectStatus, selectLastLocationPathname } from "../features/auth/authSlice";
import HeaderlessLayout from "../layouts/headerless";
import SignInForm from "../forms/SignInForm";

const SignIn = () => {
  const navigate = useNavigate();
  const status = useSelector(selectStatus);
  const from = useSelector(selectLastLocationPathname);

  useEffect(() => {
    if (status === "signed-in") {
      const destination = from || "/";
      navigate(destination);
    }

    if (status === "mfa-required") {
      navigate("/one-time-password");
    }
  }, [status, navigate]);

  return (
    <HeaderlessLayout>
      <SignInForm />
    </HeaderlessLayout>
  );
};

export default SignIn;
