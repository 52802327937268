import React from "react";
import { useDispatch } from "react-redux";
import { NavLink as NavigationLink } from "react-router-dom";
import { clearMessages } from "../../features/systemMessage/systemMessageSlice";

const NavLink = (props) => {
  const dispatch = useDispatch();

  const { onNavLinkClick } = props;
  const onClick = (event) => {
    dispatch(clearMessages());
    if (onNavLinkClick) {
      onNavLinkClick(event);
    }
  };

  return <NavigationLink onClick={onClick} {...props} />;
};

export default NavLink;
