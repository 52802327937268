import React from "react";

const FooterIconLink = (linkData) => {
  const { iconName, linkUrl } = linkData;

  return (
    <a
      className="btn btn-floating m-1 text-white"
      role="button"
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
    ><i className={`bi bi-${iconName}`} />
    </a>
  );
};

export default FooterIconLink;
