import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  pickSidebarAdverts,
  selectSidebarAdverts,
  selectSidebarPointer,
  updateSidebarPointer,
} from "../../features/advert/advertSlice";

const SidebarAdverts = () => {
  const dispatch = useDispatch();
  const sidebarAdverts = useSelector(selectSidebarAdverts);
  const selectedPointer = useSelector(selectSidebarPointer);
  const [adverts, setAdverts] = useState([]);
  const [pointer, setPointer] = useState(selectedPointer);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    dispatch(pickSidebarAdverts({ pointer, limit: 2 }));
  }, []);

  useEffect(() => {
    dispatch(updateSidebarPointer({ pointer }));
  }, [pointer]);

  useEffect(() => {
    if (!isEmpty(sidebarAdverts) && !hasLoaded) {
      setAdverts([]);

      sidebarAdverts.map((advert) => {
        const { key, imageFileName } = advert;

        if (!imageFileName) {
          return true;
        }

        import(`../../assets/adverts/${imageFileName}`)
          .then((image) => {
            const advertWithImgSrc = { ...advert, imgSrc: image.default };
            setAdverts((arr) => [...arr, advertWithImgSrc]);
            setPointer(key);
          });
        return true;
      });

      return setHasLoaded(true);
    }

    return () => setAdverts([]);
  }, [sidebarAdverts]);

  if (isEmpty(adverts)) {
    return null;
  }

  return (
    <div className="position-sticky p-3">
      <Stack gap={3} className="flex-column mb-auto">
        {adverts && adverts.map(({ key, landingUrl, imgSrc, altText }) => (
          <a key={key} href={landingUrl} target="_blank" rel="noreferrer">
            <img className="img-fluid" src={imgSrc} alt={altText} />
          </a>
        ))}
      </Stack>
    </div>
  );
};

export default SidebarAdverts;
