import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unlockAccount, selectStatus } from "../features/auth/authSlice";
import HeaderlessLayout from "../layouts/headerless";
import SystemMessages from "../features/systemMessage/SystemMessages";

const UnlockAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector(selectStatus);
  const [searchParams] = useSearchParams();
  const unlockToken = searchParams.get("unlock_token");

  useEffect(() => {
    dispatch(unlockAccount({ unlockToken }));
  }, []);

  useEffect(() => {
    if (status === "unlocked") {
      navigate("/");
    }
  }, [status]);

  return (
    <HeaderlessLayout>
      <Container id="unlock-account" fluid>
        <Row className="d-flex justify-content-center">
          <Col md="auto">
            <Card>
              <Card.Body>
                <Card.Title>Account access recovery</Card.Title>
                <SystemMessages />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </HeaderlessLayout>
  );
};

export default UnlockAccount;
