import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
  selectSlaveCpuLoad,
} from "../../bonder/bonderSlice";
import { NoData, LineChart } from "../../../components/LineChart";

const SlaveCpuLoad = ({ testRef }) => {
  const slaveCpuLoadData = useSelector(selectSlaveCpuLoad);
  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    if (slaveCpuLoadData[testRef]) {
      const data = Object
        .values(slaveCpuLoadData[testRef])
        .map((loads, cpuId) => loads.map(
          ([x, y]) => ({ testRef, type: "slave", cpuId, xAxisValue: x, yAxisValue: parseFloat(Number(y).toFixed(1)) })
        ));

      if (data.length > 0) {
        setChartData(data);
      }
    }
  }, [slaveCpuLoadData]);

  return (
    <Col className="col-md-10 offset-md-1">
      {chartData && (
        <LineChart
          data={chartData}
          targetHtml={`slave-cpu-load-${testRef}`}
          yAxisMaxValue={100}
          yAxisNumberFormat="#,###a%"
          yAxisMinGridDistance={20}
          showLineBullets
          showLegend
        />
      )}
      {!chartData && (<NoData />)}
    </Col>
  );
};

export default SlaveCpuLoad;
